// noinspection ES6MissingAwait

import React from "react";
import { Container } from "unstated";
import notificationHelper from "../util/helpers/notificationHelper";

import errorHelper from "../util/helpers/errorHelper";
import browserHelper from "../util/helpers/browserHelper";
import { roundPrice } from "../util/helpers/mathHelper";

import { resetHeaderData, sortTableData, sortTableDataAndUpdateHeaders } from "../util/helpers/sorting";
import { Throttle } from "../util/Throttle";
import { formatDate, formatDateForPresenting } from "../util/dates";
import {
	customRegex,
	isDateWithfourDigitYear,
	isEmail,
	isFormValid,
	isNumeric,
	isPositiveWithStringConversion,
	isTrue,
	maxLength,
	minValue,
	required,
} from "../util/validation/validation";
import tableHeaders from "../util/constants/tableHeaders";

import prescriptionService from "../services/PrescriptionsService";
import prescriberService from "../services/PrescribersService";
import patientService from "../services/PatientsService";
import PaymentService from "../services/PaymentService";

import {
	AddressTypesToUseForLabelGeneration,
	GenerateLabelNoteTypes,
	OrderStatuses,
	OrderStatusesToOrderHeaderMapper,
	OrderStatusesToPhaseMapper,
	PatientAccountTypes,
	PrescriptionPhases,
	productAvailabilityStatusMapper,
	RefundTypes,
	StockCheckStatus,
	StockStatusToProductLabelMapper,
	SubscriptionTypes,
} from "../enums";
import { Flag, NoteIcon, TickedIcon, XIcon } from "../assets/icons";
import CustomIconWithTooltip from "../components/SharedComponents/CustomIconWithTooltip";
import RWIcon from "../assets/icons/RWIcon";
import UrgentIconSmall from "../assets/icons/UrgentIconSmall";
import axios from "axios";
import UniversalTooltip from "../components/Tooltips/UniversalTooltip";
import FlaggedPrescriptionTooltipContent
	from "../components/Tooltips/ContentForTooltips/FlaggedPrescriptionTooltipContent";
import OrganisationsService from "../services/OrganisationsService";
import moment from "moment";
import OverflowSelect from "../components/Form/OverflowSelect";
import PaperCopyIcon from "../assets/icons/PaperCopyIcon";
import { getProductWithStatus } from "../converters/prescriptionsConverter";
import FlaggedPatientTooltipContent from "../components/Tooltips/ContentForTooltips/FlaggedPatientTooltipContent";
import { debounce } from "../util/helpers/debounce";
import MetricsService from "../services/MetricsService";

const initialPrescriptionState = {
	id: { value: "" },
	prescriptionNumber: { value: "" },
	imageUrl: { value: "" },

	patient: { value: null },
	prescriber: { value: null },
	organisationId: { value: null },
	adminFlaggedBy: { value: null },

	submittedBy: { value: "" },
	status: { value: 0 },
	expiryDate: { value: "" },

	datePrescribed: { value: "" },
	dateReceived: { value: "" },
	dateCompleted: { value: "" },
	isPaperCopyReceived: { value: false },
	isT21: { value: false },
	isProcessed: { value: false },
	isUrgent: { value: false },
	isArchived: { value: false },
	isFlagged: { value: false },
	isCompleted: { value: false },

	orders: { value: [] },
	eventHistory: { value: [] },
	supportComms: { value: [] },

	createdAt: { value: "" },
	updatedAt: { value: "" },

	rewriteChildPrescriptionId: { value: "" },
	rewriteParentPrescriptionId: { value: "" },
	rewriteReason: { value: "" },
	archivedReason: { value: "" },
	unarchivedReason: { value: "" },
	cancelProductReason: { value: "" },
	flaggedReason: { value: "" },
	flaggedByAdminId: { value: "" },
	urgentReason: { value: "" },
	chargeShipping: { value: true },
	removeShippingReason: { value: "" },
	awaitingRewrite: { value: false },

	// list for keeping products an admin
	// adds to order before clicking save
	orderedProducts: [],

	archivedDateTime: { value: "" },
};

const initialCreatePrescriptionState = {
	prescription: { value: null },
	prescriptionUrl: { value: "" },
	envelopeTrackingCode: { value: "" },

	prescriber: { value: null },
	organisationId: { value: null },
	confirmedConsent: { value: true },
};

const initialCreateCommentState = {
	prescriptionId: { value: -1 },
	commentText: { value: "" },
};

const assignPrescriptionInitialState = {
	patient: { value: null },
	tempPatient: { value: null },
	prescriber: { value: null },
	prescription: { value: null },
	adminFlaggedBy: { value: null },
	isFlagged: { value: false },
	flaggedReason: { value: "" },
	isUrgent: { value: false },
	urgentReason: { value: "" },
	trackingNumber: { value: '' },
	patientsFullName: { value: false },
	patientsFullAddress: { value: false },
	productName: { value: false },
	prescriberSigniture: { value: false },
	paperPrescriptionId: { value: "" },
	eventHistory: { value: [] },
	rewritePrescriptionIdForLinking: { value: "" },
	datePrescribed: { value: "" },
	patientsActivePrescriptions: { value: [] },
}

const rejectPrescriptionInitialState = {
	highLevelReason: { value: "", label: 'Select' },
	reason: { value: "", label: 'Select' },
	product: [{ value: { value: "", label: 'Select....' }, error: null }],
	prescriptionNumber: { value: "" },
	patient: { value: null },
	paperPrescriptionId: { value: "" },
	comment: { value: "" },
	prescriptionId: { value: "" },
	datePrescribed: { value: "" },
}

const refundOrderInitialState = {
	refundType: { value: RefundTypes.FullRefund },
	refundAmount: { value: "" },
	refundReason: { value: "" },
	resetCustomRefundAmmountField: { value: 1 },
}

const regenerateLabelInitialState = {
	addressToUseType: { value: AddressTypesToUseForLabelGeneration.PatientProfileAddress },
	address1: { value: "" },
	address2: { value: "" },
	address3: { value: "" },
	city: { value: "" },
	zipCode: { value: "" },
	orderId: { value: "" },
	noteType: { value: GenerateLabelNoteTypes.MustNotLeaveWithNeighbor },
	note: { value: "Must not leave with neighbour" },
}

const unlinkPrescriptionInitialState = {
	prescriptionId: { value: null },
	rewritePrescriptionId: { value: null },
	reason: { value: "" },
}

const removeRewriteRequiredStatusInitialState = {
	prescriptionId: { value: null },
	reason: { value: "" },
}

const contactInitialState = {
	subject: { value: "" },
	message: { value: "" },
	accountTypes: [],
}

const resubmitOrderInitialState = {
	prescriptionIsInDate: { value: false },
	orderHasBeenProcessed: { value: false },
	productAddedAndInStock: { value: false },
	prescriptionReadable: { value: false },
	orderToResubmit: { value: "" },
}

const splitOrderInitialState = {
	orderToSplit: { value: "" },
	orderedProductsToSplit: [],
	splitOrderReason: { value: "" },
}

const initialStates = {
	rejectPrescription: { ...rejectPrescriptionInitialState },
	contact: { ...JSON.parse(JSON.stringify(contactInitialState)) },
	resubmitOrder: { ...JSON.parse(JSON.stringify(resubmitOrderInitialState)) },
	splitOrder: { ...JSON.parse(JSON.stringify(splitOrderInitialState)) },
}

const initialForceCompletedState = {
	forceCompletedReason: { value: "" }
}

class PrescriptionContainer extends Container {
	//#region State

	constructor() {
		super();

		this.state = {
			tableHeaders: {
				[PrescriptionPhases.new]: tableHeaders.prescriptionsProcessing(),
				[PrescriptionPhases.outofstock]: tableHeaders.awaitingStock(),
				[PrescriptionPhases.readyforpayment]: tableHeaders.prescriptionsReadyForPayment(),
				[PrescriptionPhases.requirepapercopy]: tableHeaders.prescriptions(),
				[PrescriptionPhases.readyforpharmacy]: tableHeaders.prescriptions(),
				[PrescriptionPhases.readyforpatient]: tableHeaders.prescriptions(),
				[PrescriptionPhases.shipped]: tableHeaders.prescriptions(),
				[PrescriptionPhases.completed]: tableHeaders.completed(),
				[PrescriptionPhases.void]: tableHeaders.prescriptions(),
				[PrescriptionPhases.archived]: tableHeaders.archived(),
				[PrescriptionPhases.cancelled]: tableHeaders.prescriptions(),
				toBeAssigned: tableHeaders.toBeAssigned(),
				[PrescriptionPhases.paymentmissed]: tableHeaders.prescriptionsReadyForPayment(),
				awaitingRewrite: tableHeaders.awaitingRewrite(),
				futureDatedScripts: tableHeaders.futureDatedScripts(),
			},
			tableData: {
				[PrescriptionPhases.new]: [],
				[PrescriptionPhases.outofstock]: [],
				[PrescriptionPhases.readyforpayment]: [],
				[PrescriptionPhases.requirepapercopy]: [],
				[PrescriptionPhases.readyforpharmacy]: [],
				[PrescriptionPhases.readyforpatient]: [],
				[PrescriptionPhases.shipped]: [],

				[PrescriptionPhases.completed]: [],
				[PrescriptionPhases.void]: [],
				[PrescriptionPhases.archived]: [],
				[PrescriptionPhases.cancelled]: [],
				[PrescriptionPhases.paymentmissed]: [],
				toBeAssigned: [],
				awaitingRewrite: [],
				futureDatedScripts: [],
			},

			tablePaginationData: {
				[PrescriptionPhases.new]: [],
				[PrescriptionPhases.outofstock]: [],
				[PrescriptionPhases.readyforpayment]: [],
				[PrescriptionPhases.requirepapercopy]: [],
				[PrescriptionPhases.readyforpharmacy]: [],
				[PrescriptionPhases.readyforpatient]: [],
				[PrescriptionPhases.shipped]: [],

				[PrescriptionPhases.completed]: [],
				[PrescriptionPhases.void]: [],
				[PrescriptionPhases.archived]: [],
				[PrescriptionPhases.cancelled]: [],
				[PrescriptionPhases.paymentmissed]: [],
				toBeAssigned: [],
				awaitingRewrite: [],
				futureDatedScripts: [],
			},

			loadingPaginatedPrescriptions: {
				[PrescriptionPhases.new]: false,
				[PrescriptionPhases.outofstock]: false,
				[PrescriptionPhases.readyforpayment]: false,
				[PrescriptionPhases.requirepapercopy]: false,
				[PrescriptionPhases.readyforpharmacy]: false,
				[PrescriptionPhases.readyforpatient]: false,
				[PrescriptionPhases.shipped]: false,

				[PrescriptionPhases.completed]: false,
				[PrescriptionPhases.void]: false,
				[PrescriptionPhases.archived]: false,
				[PrescriptionPhases.cancelled]: false,
				[PrescriptionPhases.paymentmissed]: false,
				toBeAssigned: false,
				awaitingRewrite: false,
				futureDatedScripts: false,
			},

			temporaryOrderedProducts: [{
				productId: { value: "" },
				amount: { value: null },
				fullPrice: { value: 0 },
				retailPrice: { value: 0 },
				t21Price: { value: 0 },
				t21PriceReason: { value: "" },
				membershipPrice: { value: 0 },
				isPriceOverriden: { value: false },
				stockStatus: { value: -1 },
				isBannerChecked: { value: false },
				loadingStock: false,
			}], // used to build an array of ordered products to be created when the user creates an order

			orderedProducts: [],

			orderedProductsTableData: [],

			partialAdmins: [],

			totalPriceForOrderedProducts: {
				shippingCost: null,
				priceForOrderedProducts: null,
			},

			orderForUpdate: null,

			products: [],
			createPrescription: { ...initialCreatePrescriptionState },
			updatePrescription: initialPrescriptionState,
			createComment: initialCreateCommentState,
			flaggedReason: {
				value: "",
				flaggedReason: "",
			},
			urgentReason: {
				value: "",
			},
			removeShippingReason: {
				removeShippingReason: {
					value: ""
				},


			},
			chargeShipping: {
				value: true,
			},
			filteredPrescribers: [],
			filteredPrescribersOriginal: [],
			filteredOrganisations: [],
			filteredPatients: [],
			cancellation: {
				productId: null,
				orderId: null,
			},
			searchListOptions: {
				prescriptionNumbers: [],
				prescriptionOrdersIds: [],
				prescriptionIds: [],
				patientClinicIds: [],
				patientIds: [],
				patients: [],
				patientTelephoneNumber: [],
			},
			contact: { ...contactInitialState },
			assignPrescription: { ...assignPrescriptionInitialState },
			forceCompletedReason: { ...initialForceCompletedState },
			rejectPrescription: { ...rejectPrescriptionInitialState },
			prescriptionsAwaitingRewrite: [],
			refundOrderState: { ...refundOrderInitialState },
			unlinkPrescription: { ...unlinkPrescriptionInitialState },
			removeRewriteRequiredStatus: { ...removeRewriteRequiredStatusInitialState },
			regenerateLabelState: { ...regenerateLabelInitialState },
			resubmitOrder: { ...resubmitOrderInitialState },
			splitOrder: { ...splitOrderInitialState },

			// loading flags
			loadingPrescription: false,
			updatingPrescription: false,
			creatingPrescription: false,
			updatingShippingStatus: false,
			generatingLabel: false,
			cancelingProduct: false,
			creatingTransaction: false,
			loadingPrescriptions: false,
			creatingComment: false,
			loadingGlobalSearch: false,
			loadingToBeAssigned: false,
			archivingPrescription: false,
			unarchivingPrescription: false,
			sendingToOrganisation: false,
			flaggingPrescription: false,
			resubmitingOrder: false,
			loadingSupportComms: false,
			loadingEventHistory: false,
			splittingOrder: false,
		};

		this.validators = {
			updatePrescription: {
				prescriber: [required],
				datePrescribed: [required, isDateWithfourDigitYear],
				dateReceived: [isDateWithfourDigitYear],
				prescriptionNumber: [required, customRegex("^[0-9]{11,11}$")],
				archivedReason: [maxLength(255)],
				unarchivedReason: [maxLength(255)],
				cancelProductReason: [maxLength(255)],
				flaggedReason: [maxLength(255)],
				urgentReason: [maxLength(255)],
			},

			contact: {
				message: [required, maxLength(255)],
				accountType: [required],
			},

			urgentReason: {
				value: [maxLength(255)]
			},

			forceCompletedReason: {
				forceCompletedReason: [maxLength(255)]
			},

			removeRewriteRequiredStatus: {
				reason: [required, maxLength(255)]
			},

			unlinkPrescription: {
				reason: [required, maxLength(255)]
			},

			paymentDetails: {
				customerFirstName: [required],
				customerLastName: [required],
				customerEmail: [required, isEmail],
				customerPhone: [required],
				address1: [required],
				postalCode: [required],
				city: [required],
				country: [required],
				description: [required],
				billingAddress1: [required],
				billingCity: [required],
				billingPostalCode: [required],
			},

			createPrescription: {
				prescriber: [required],
				organisationId: [required],
				prescriptionUrl: [required],
			},

			createComment: {
				commentText: [required, maxLength(255)],
			},
			assignPrescription: {
				patientsFullName: [isTrue],
				patientsFullAddress: [isTrue],
				productName: [isTrue],
				prescriberSigniture: [isTrue],
				patient: [required],
				paperPrescriptionId: [required, customRegex("^[0-9]{11,11}$")],
				datePrescribed: [required],
			},
			rejectPrescription: {
				paperPrescriptionId: [required, customRegex("^[0-9]{11,11}$")],
				comment: [maxLength(255)]
			},
			refundOrderState: {
				refundAmount: [required, isNumeric, isPositiveWithStringConversion],
				refundReason: [required, maxLength(255)],
			},
			regenerateLabelState: {
				address1: [required],
				city: [required],
				zipCode: [required],
				note: [required, maxLength(55)],
			},
			resubmitOrder: {
				prescriptionIsInDate: [isTrue],
				orderHasBeenProcessed: [isTrue],
				productAddedAndInStock: [isTrue],
				prescriptionReadable: [isTrue],
			},
			splitOrder: {
				splitOrderReason: [required, maxLength(255)]
			},
			removeShippingReason: {
				value: [maxLength(255)]
			},
		};

		// throttles
		this.throttles = {
			new: new Throttle(),
			outofstock: new Throttle(),
			readyforpayment: new Throttle(),
			requirepapercopy: new Throttle(),
			readyforpharmacy: new Throttle(),
			readyforpatient: new Throttle(),
			shipped: new Throttle(),
			completed: new Throttle(),
			void: new Throttle(),
			archived: new Throttle(),
			paymentmissed: new Throttle(),
			cancelled: new Throttle(),
			search: new Throttle(),
			toBeAssigned: new Throttle(),
			futureDatedScripts: new Throttle(),
			stockCheck: new Throttle(),
		};

		this.prescriberSearchThrottle = new Throttle();
		this.filterPatientsSelectThrottle = new Throttle();
	}

	resetUploadPrescriptionState = () => {
		this.setState({
			createPrescription: { ...initialCreatePrescriptionState },
		});
	};

	resetPrescriptionState = () => {
		this.setState({
			updatePrescription: initialPrescriptionState,
			orderedProducts: []
		});
	};

	resetRejectPrescriptionState = () => {
		this.setState({
			rejectPrescription: { ...rejectPrescriptionInitialState },
		});
	}

	resetAllFieldsExcept = (formKey, fieldNames = []) => {

		const fieldsNotToReset = {};

		fieldNames.forEach(name => {
			fieldsNotToReset[name] = this.state[formKey][name];
		})

		this.setState({ [formKey]: { ...JSON.parse(JSON.stringify(initialStates[formKey])), ...fieldsNotToReset } });
	}

	setCreateCommentState = (id) => {
		this.setState({
			createComment: {
				prescriptionId: { value: id },
				commentText: { value: "" },
			},
		});
	};

	handleChange = ({ name: accountType }) => {
		accountType = parseInt(accountType);

		const accountTypes = [accountType];

		this.setState({ contact: { ...this.state.contact, accountTypes } });
	};

	//#endregion

	//#region Util methods for inputs

	onFormChange = (e, formKey) => {
		let validators = [];

		if (this.validators[formKey]) {
			validators = this.validators[formKey][e.name];
		}
		if (e.value === "true" || e.value === "false") {
			e.value = JSON.parse(e.value);
		}


		const newField = { value: e.value, error: null };

		for (let i in validators) {
			const result = validators[i](newField.value);

			if (!result.valid) {
				newField.error = result.message;

				break;
			}
		}

		this.setState({ [formKey]: Object.assign(this.state[formKey], { [e.name]: newField }) });
	};

	onFormChangeOrderedProduct = (e, formKey, index) => {
		let validators = [];

		if (this.validators[formKey]) {
			validators = this.validators[formKey][e.name];
		}
		if (e.value === "true" || e.value === "false") {
			e.value = JSON.parse(e.value);
		}

		const newField = { value: e.value, error: null };

		for (let i in validators) {
			const result = validators[i](newField.value);

			if (!result.valid) {
				newField.error = result.message;

				break;
			}
		}

		let updatedArray = [...this.state[formKey]];
		updatedArray[index] = { ...updatedArray[index], [e.name]: newField };

		this.setState({ [formKey]: Object.assign(this.state[formKey], { [index]: updatedArray[index] }) });
	}

	onPrescriptionFileChange = (e, formKey) => {
		const prescriptionFileField = { value: e.event.target.files[0], error: null };
		const prescriptionFileURLField = { value: e.file.url, error: null };

		this.setState({ [formKey]: Object.assign(this.state[formKey], { prescription: prescriptionFileField }) });
		this.setState({ [formKey]: Object.assign(this.state[formKey], { prescriptionUrl: prescriptionFileURLField }) });
	};

	onSelectChange = (e, formKey) => {
		if (e.value[0] === "true" || e.value[0] === "false") {
			e.value[0] = JSON.parse(e.value[0]);
		}

		const newField = { value: e.value[0], error: null };

		this.setState({ [formKey]: Object.assign(this.state[formKey], { [e.name]: newField }) });
	};

	onSelectChangeMultiple = (e, index, formKey) => {
		const newField = { value: e.value[0], error: null };

		let currentValue = this.state[formKey][e.name]

		const updatedValue = currentValue.map((field, i) => {
			if (i === index) {
				return newField;
			}
			return field
		})

		this.setState({ [formKey]: Object.assign(this.state[formKey], { [e.name]: updatedValue }) });
	};

	onTempOrderedProductChange = (e, index, formKey) => {
		if (e.value[0] === "true" || e.value[0] === "false") {
			e.value[0] = JSON.parse(e.value[0]);
		}

		const newField = { value: e.value[0], error: null };

		let updatedArray = [...this.state[formKey]];
		updatedArray[index] = { ...updatedArray[index], [e.name]: newField };

		if (updatedArray[index].isBannerChecked)
			updatedArray[index].isBannerChecked.value = false;

		this.setState({ [formKey]: Object.assign(this.state[formKey], { [index]: updatedArray[index] }) });

		this.filterOutDropdownProducts();
	}

	debouncedSearch = debounce((queryString) => {
		if (queryString && queryString.length >= 3) {
			this.searchPatients(queryString);
		}
	}, 300);

	onSelectKeyDownPrescriptionPatientField = async (queryString) => {
		this.debouncedSearch(queryString);
	};

	appendField = (fieldName, formKey) => {
		const newField = { value: { value: "", label: 'Select...' }, error: null };

		let currentValue = this.state[formKey][fieldName]

		currentValue.push(newField);

		this.setState({ [formKey]: Object.assign(this.state[formKey], { [fieldName]: currentValue }) });
	};

	removeField = (fieldName, formKey, index) => {
		let currentValue = this.state[formKey][fieldName]

		let newValue = currentValue.filter((fieldValue, i) => {
			if (index === i) {
				return false;
			}
			return fieldValue
		})

		this.setState({ [formKey]: Object.assign(this.state[formKey], { [fieldName]: newValue }) });
	};

	isFormValid = (formKey) => {
		return isFormValid(this.state[formKey], this.validators[formKey]);
	};

	sort = (columnIndex, phase, splitBySpace) => {
		if ([undefined, null].includes(phase)) return;

		const headers = this.state.tableHeaders[phase];
		const body = this.state.tableData[phase];

		sortTableDataAndUpdateHeaders(headers, body, columnIndex, splitBySpace);

		const tableHeaders = { ...this.state.tableHeaders, [phase]: headers };
		const tableData = { ...this.state.tableData, [phase]: body };

		this.setState({ tableHeaders, tableData });
	};

	handleColumnSort = (columnIndex, tableName) => {
		let tableHeader = this.state.tableHeaders[PrescriptionPhases[tableName]];

		resetHeaderData(tableHeader, columnIndex);

		tableHeader[columnIndex].ascending = !tableHeader[columnIndex].ascending;

		this.setState(prevState => ({
			tableHeaders: {
				...prevState.tableHeaders, [PrescriptionPhases[tableName]]: tableHeader,
			},
		}));
	}

	setOrderedProductsForSplit = () => {
		const orderedProductsToSplit = JSON.parse(JSON.stringify(this.state.splitOrder.orderToSplit.value.orderedProductsRaw));
		this.setState({ splitOrder: { ...this.state.splitOrder, orderedProductsToSplit: { ...orderedProductsToSplit } } });
	}

	changeSelectedOrderedProductForSplit = (index) => {
		const orderedProductsToSplit = this.state.splitOrder.orderedProductsToSplit;
		if (orderedProductsToSplit[index]) {
			orderedProductsToSplit[index].isSelected = !orderedProductsToSplit[index].isSelected
			this.setState({ splitOrder: { ...this.state.splitOrder, orderedProductsToSplit: { ...orderedProductsToSplit } } });
		}
	}

	//#endregion

	//#region Prescription tables

	getAllPaginated = async (phase, queryString = "", page = 1, pageSize = 25, sortField = "id", sortDirection = 0, cancelToken = null) => {
		try {
			const initialData = {
				tableData: {},
				tablePaginationData: {},
				loadingPaginatedPrescriptions: true
			};
			if (phase === "past") {
				// Set loading flags for all phases to true when fetching past prescriptions
				this.setState({
					loadingPaginatedPrescriptions: {
						[PrescriptionPhases.completed]: true,
						[PrescriptionPhases.void]: true,
						[PrescriptionPhases.archived]: true,
					},
				});

				const phases = ["completed", "void", "archived"];
				// Fetch data for all phases concurrently using Promise.all
				const results = await Promise.all(phases.map(async (phase) => {
					const result = await prescriptionService.filterPaginated(queryString, phase, page, pageSize, sortField, sortDirection, cancelToken);
					return { result, phase };
				}));

				this.processPaginatedResults(results, initialData);
			} else if (phase === "all") {
				// Set loading flags for all phases to true when fetching past prescriptions
				this.setState({
					loadingPaginatedPrescriptions: {
						[PrescriptionPhases.new]: true,
						[PrescriptionPhases.outofstock]: true,
						[PrescriptionPhases.readyforpayment]: true,
						[PrescriptionPhases.paymentmissed]: true,
						[PrescriptionPhases.requirepapercopy]: true,
						[PrescriptionPhases.readyforpharmacy]: true,
						[PrescriptionPhases.readyforpatient]: true,
						[PrescriptionPhases.shipped]: true,
						[PrescriptionPhases.cancelled]: true,
					},
				});

				const phases = ["new", "outofstock", "readyforpayment", "paymentmissed", "requirepapercopy", "readyforpharmacy", "readyforpatient", "shipped", "cancelled"];
				// Fetch data for all phases concurrently using Promise.all
				const results = await Promise.all(phases.map(async (phase) => {
					//Everything other than to be processed table should be sorted by expiry date
					if (phase === "outofstock") {
						sortField = "expiryDate";
						sortDirection = 0;
					}
					const result = await prescriptionService.filterPaginated(queryString, phase, page, pageSize, sortField, sortDirection, cancelToken);
					return { result, phase };
				}));

				this.processPaginatedResults(results, initialData);
			} else {
				// Set loading flag for the specific phase to true
				this.setState(prevState => ({
					loadingPaginatedPrescriptions: {
						...prevState.loadingPaginatedPrescriptions,
						[PrescriptionPhases[phase]]: true,
					},
				}));
				// Fetch data for the specified phase
				const result = await prescriptionService.filterPaginated(queryString, phase, page, pageSize, sortField, sortDirection, cancelToken);
				this.processPaginatedResults([{ result, phase }], initialData);
			}
		} catch (error) {
			if (axios.isCancel(error)) {
			} else {
				errorHelper.handleError(error);
			}
		}
	};

	getTableReport = async (phase) => {
		try {
			let response = await prescriptionService.getTableReport(phase);
			return MetricsService._handleDownloadCSV(response, `${phase} report`)
		} catch (error) {
			errorHelper.handleError(error);
		}
	}

	processPaginatedResults = (results, initialData) => {
		results.forEach(({ result, phase }) => {
			const { items, ...resultPaginationData } = result;
			// Update state with empty object if no items are returned
			if (!items.length) {
				this.setState(prevState => ({

					tableData: { ...prevState.tableData, [PrescriptionPhases[phase]]: {} },
					tablePaginationData: { ...prevState.tablePaginationData, [PrescriptionPhases[phase]]: { totalCount: 0 } },
					loadingPaginatedPrescriptions: { ...prevState.loadingPaginatedPrescriptions, [PrescriptionPhases[phase]]: false },
				}));
				return;
			}

			const tableRows = items.map(prescription => this.getTableRow(prescription, phase));
			initialData.tableData[PrescriptionPhases[phase]] = tableRows;
			initialData.tablePaginationData[PrescriptionPhases[phase]] = resultPaginationData;

			this.setState(prevState => ({
				tableData: { ...prevState.tableData, ...initialData.tableData },
				tablePaginationData: { ...prevState.tablePaginationData, ...initialData.tablePaginationData },
				loadingPaginatedPrescriptions: { ...prevState.loadingPaginatedPrescriptions, [PrescriptionPhases[phase]]: false },
			}));
		});
	};

	getTableRow = (prescription, phase) => {
		// Return the appropriate table row based on the phase
		switch (phase) {
			case "new":
				return this._getPrescriptionTableRowForProcessing(prescription);
			case "outofstock":
				return this._getPrescriptionTableRowForAwaitingStock(prescription);
			case "readyforpayment":
				return this._getPrescriptionTableRowForPayment(prescription);
			case "paymentmissed":
				return this._getPrescriptionTableRowForPayment(prescription);

			case "completed":
				return this._getPrescriptionTableRowForCompleted(prescription);
			case "void":
				return this._getPrescriptionTableRow(prescription);
			case "archived":
				return this._getPrescriptionTableRowForArchived(prescription);

			default:
				return this._getPrescriptionTableRow(prescription);
		}
	};

	/**
	 * Setups a throttle for filtering
	 * so that search doesn't happen every
	 * time a key is pressed
	*/
	setFilterTimeout = async (queryString, prescriptionPhase) => {
		if ([undefined, null].includes(prescriptionPhase)) return;

		const throttle = this.throttles[prescriptionPhase];

		// if searching in specific table, don't reset data
		if (throttle) throttle.setTimeout(() => this._filterCallback(queryString, prescriptionPhase), 300);
	};

	_filterCallback = async (queryString, prescriptionPhase) => {
		try {
			const result = await prescriptionService.filter(queryString, prescriptionPhase);

			// copy data from state and reset only the table for phase that is being filtered
			let tableData = {
				...this.state.tableData,
				[PrescriptionPhases[prescriptionPhase]]: [],
			};

			// turn prescription data from api
			// into table data
			for (let prescription of result) {
				for (let orderStatus of prescription.orderStatuses) {
					this._addPrescriptionToPhase(orderStatus, prescription, tableData, prescriptionPhase);
				}
			}
			sortTableData(tableData, prescriptionPhase)
			this.setState({ tableData });
		} catch (error) {
			errorHelper.handleError(error);
		}
	};

	async quickSearch(queryString) {
		const throttle = this.throttles.search;
		const cancelToken = axios.CancelToken.source();

		if (this.state.cancelToken) {
			this.state.cancelToken.cancel("Cancelling this request");
		}

		//timeout is increased to 1000 since we allow searching for strings of length > 1
		if (throttle) throttle.setTimeout(() => this._quickSearchCallBack(queryString, cancelToken), 1000);

		this.setState({ cancelToken: cancelToken })

	}

	async _quickSearchCallBack(queryString, cancelToken) {

		try {
			this.setState({ loadingGlobalSearch: true });

			const result = await prescriptionService.quickSearch(queryString, cancelToken);

			if (
				!result.prescriptionNumbers.length &&
				!result.prescriptionIds.length &&
				!result.prescriptionOrdersIds.length &&
				!result.patients.length &&
				!result.patientIds.length &&
				!result.patientClinicIds.length &&
				!result.patientTelephoneNumber.length) {
				this.setState({ noResultsFromGlobalSearch: true });
			} else {
				this.setState({ noResultsFromGlobalSearch: false });
			}

			this.setState({ searchListOptions: mapResult(result) })
			this.setState({ loadingGlobalSearch: false });

		} catch (error) {

			if (error.message !== "Cancelling this request") {
				errorHelper.handleError(error);
				this.setState({ loadingGlobalSearch: false });
			}

		}

		function mapResult(result) {
			const data = [];
			for (const [key, value] of Object.entries(result)) {
				if (!data.hasOwnProperty(key)) {
					data[key] = [];
				}

				value.forEach(item => {
					data[key].push({
						...item,
						isSelected: false
					});
				})
			}
			return data;
		}
	}

	/**
	 * Puts a @param prescription into appropriate list in @param tableData
	 * based on @param orderStatus
	 */
	_addPrescriptionToPhase(orderStatus, prescription, tableData) {
		const phases = OrderStatusesToPhaseMapper[orderStatus];
		for (let phase of phases) {
			const tableRow = this._createTableRowBasedOnPhase(prescription, phase);
			if (this._isPrescriptionIdAlreadyInPhase(phase, prescription.id, tableData)) continue;

			tableData[phase].push(tableRow);
		}

	}

	_createTableRowBasedOnPhase(prescription, phase) {
		if (phase === PrescriptionPhases.new) return this._getPrescriptionTableRowForProcessing(prescription);
		else if (phase === PrescriptionPhases.readyforpayment) return this._getPrescriptionTableRowForPayment(prescription);
		else if (phase === PrescriptionPhases.paymentmissed) return this._getPrescriptionTableRowForPayment(prescription);
		else if (phase === PrescriptionPhases.archived) return this._getPrescriptionTableRowForArchived(prescription);
		else if (phase === PrescriptionPhases.completed) return this._getPrescriptionTableRowForCompleted(prescription);
		else if (phase === PrescriptionPhases.outofstock) return this._getPrescriptionTableRowForAwaitingStock(prescription);
		else return this._getPrescriptionTableRow(prescription);
	}

	_getPrescriptionTableRowForProcessing(prescription) {
		return [
			{
				text: renderFlagForRewrite(prescription),
				value: prescription.id,
				href: `/prescriptions/edit/${prescription.id}`,
				column: 1.5,
				tdClass: prescription.isUrgent ? "td--border--left--red--background"
					: prescription.isFlagged ? "td--border--left--red"
						: "",
				expiryDate: prescription.expiryDate,
				isUrgent: prescription.isUrgent,
			},
			{
				text: renderPaperCopyIcon(prescription),
				href: `/prescriptions/edit/${prescription.id}`,
				column: 2,
				tdClass: prescription.isUrgent ? "td--background--red" : "",
			},
			{
				text: renderFlagForPatient(prescription),
				href: `/patients/edit/${prescription.patient.id}`,
				column: 2,
				tdClass: prescription.isUrgent ? "td--background--red" : "",
			},
			{
				text: renderPrescriptionOwnerDropdown(prescription, this.assignAnOwner, this.state.partialAdmins),
				column: 1.5,
				value: prescription.adminOwner?.name || "none",
				tdClass: prescription.isUrgent ? "td--background--red" : "",
			},
			{
				text: prescription.prescriber ? `${prescription.prescriber.firstName} ${prescription.prescriber.lastName}` : "N/A",
				column: 2,
				tdClass: prescription.isUrgent ? "td--background--red" : "",
			},
			{
				text: prescription.organisation.name || "N/A",
				column: 2,
				tdClass: prescription.isUrgent ? "td--background--red" : "",
			},
			{
				text: formatDateForPresenting(prescription.createdAt, false),
				column: 1,
				right: true,
				type: "date",
				tdClass: prescription.isUrgent ? "td--background--red" : "",
			},
		];
	}

	_getPrescriptionTableRowForPayment(prescription) {
		let paymentIcon = <XIcon />;
		let paymentRequestedColumnValue;

		if (prescription.isPaymentRequested) {
			paymentIcon = <TickedIcon />;
			paymentRequestedColumnValue = "1";
		} else {
			paymentRequestedColumnValue = "2";
		}

		console.log(prescription);

		return [
			{
				text: renderFlagForRewrite(prescription),
				value: prescription.id,
				href: `/prescriptions/edit/${prescription.id}`,
				column: 1,
				tdClass: prescription.isUrgent ? "td--border--left--red--background"
					: prescription.isFlagged ? "td--border--left--red"
						: "",
				expiryDate: prescription.expiryDate,
				isUrgent: prescription.isUrgent,
			},
			{
				text: renderPaperCopyIcon(prescription),
				href: `/prescriptions/edit/${prescription.id}`,
				column: 1.5,
				tdClass: prescription.isUrgent ? "td--background--red" : "",
			},
			{
				text: renderFlagForPatient(prescription),
				href: `/patients/edit/${prescription.patient.id}`,
				column: 1.5,
				tdClass: prescription.isUrgent ? "td--background--red" : "",
			},
			{
				text: renderPrescriptionOwnerDropdown(prescription, this.assignAnOwner, this.state.partialAdmins),
				value: prescription.adminOwner?.name || "none",
				column: 1.5,
				tdClass: prescription.isUrgent ? "td--background--red" : "",
			},
			{
				text: prescription.prescriber ? `${prescription.prescriber.firstName} ${prescription.prescriber.lastName}` : "N/A",
				column: 1.5,
				tdClass: prescription.isUrgent ? "td--background--red" : "",
			},
			{
				text: prescription.organisation.name || "N/A",
				column: 2,
				tdClass: prescription.isUrgent ? "td--background--red" : "",
			},
			{
				text: formatDateForPresenting(prescription.createdAt, false),
				column: 1,
				right: true,
				type: "date",
				tdClass: prescription.isUrgent ? "td--background--red" : "",
			},
			{
				text: prescription.expiryDate ? formatDateForPresenting(prescription.expiryDate, false) : "N/A",
				column: 1,
				right: true,
				type: "date",
				tdClass: prescription.isUrgent ? "td--background--red" : "",
			},
			{
				text: paymentIcon,
				value: paymentRequestedColumnValue,
				column: 1,
				right: true,
				tdClass: prescription.isUrgent ? "td--background--red" : "",
			},
		];
	}

	_getPrescriptionTableRowForAwaitingStock(prescription) {

		let hasUnavailableProduct = false;
		let orderIsValid = true
		for (const order of prescription.orders) {
			if (order.orderedProducts.find((orderedProduct) => getProductWithStatus(orderedProduct.product, orderedProduct.amount).status === StockCheckStatus.UnavailableNotInStock && orderedProduct.isCancelled === false)) {
				hasUnavailableProduct = true;
				break;
			}

		}

		if (hasUnavailableProduct) {
			orderIsValid = false
		}

		let isAllOrdersValidIcon = <XIcon />;
		let isAllOrderValidIconColumValue;
		if (orderIsValid) {
			isAllOrdersValidIcon = <TickedIcon />
			isAllOrderValidIconColumValue = "1";
		} else {
			isAllOrderValidIconColumValue = "2";
		}

		return [
			{
				text: renderFlagForRewrite(prescription),
				value: prescription.id,
				href: `/prescriptions/edit/${prescription.id}`,
				tdClass: !orderIsValid || prescription.isUrgent ? "td--background--red" : "",
				column: 1,
				expiryDate: prescription.expiryDate,
				isUrgent: prescription.isUrgent,
			},
			{
				text: renderPaperCopyIcon(prescription),
				href: `/prescriptions/edit/${prescription.id}`,
				column: 2,
				tdClass: !orderIsValid || prescription.isUrgent ? "td--background--red" : "",
			},
			{
				text: renderFlagForPatient(prescription),
				href: `/patients/edit/${prescription.patient.id}`,
				column: 2,
				tdClass: !orderIsValid || prescription.isUrgent ? "td--background--red" : "",
			},
			{
				text: renderPrescriptionOwnerDropdown(prescription, this.assignAnOwner, this.state.partialAdmins),
				value: prescription.adminOwner?.name || "none",
				column: 2,
				tdClass: !orderIsValid || prescription.isUrgent ? "td--background--red" : "",
			},
			{
				text: prescription.organisation.name || "N/A",
				column: 2,
				tdClass: !orderIsValid || prescription.isUrgent ? "td--background--red" : "",
			},
			{
				text: prescription.expiryDate ? formatDateForPresenting(prescription.expiryDate, false) : "N/A",
				column: 1.5,
				right: true,
				type: "date",
				tdClass: !orderIsValid || prescription.isUrgent ? "td--background--red" : "",
			},
			{
				text: isAllOrdersValidIcon,
				value: isAllOrderValidIconColumValue,
				column: 1.5,
				right: true,
				tdClass: !orderIsValid || prescription.isUrgent ? "td--background--red" : "",
			},
		];
	}

	_getPrescriptionTableRowForArchived(prescription) {
		return [
			{
				text: renderFlagForRewrite(prescription),
				value: prescription.id,
				href: `/prescriptions/edit/${prescription.id}`,
				column: 1,
				tdClass: prescription.isUrgent ? "td--border--left--red--background"
					: prescription.isFlagged ? "td--border--left--red"
						: "",
				expiryDate: prescription.expiryDate,
				isUrgent: prescription.isUrgent,
			},
			{
				text: prescription.patient ? renderFlagForPatient(prescription) : "N/A",
				href: prescription.patient ? `/patients/edit/${prescription?.patient?.id}` : "",
				column: 2,
				tdClass: prescription.isUrgent ? "td--background--red" : "",
			},
			{
				text: prescription.prescriber ? `${prescription.prescriber.firstName} ${prescription.prescriber.lastName}` : "N/A",
				column: 2,
				tdClass: prescription.isUrgent ? "td--background--red" : "",
			},
			{
				text: prescription.organisation.name || "N/A",
				column: 2,
				tdClass: prescription.isUrgent ? "td--background--red" : "",
			},
			{
				text: prescription.archivedReason,
				column: 2,
				left: true,
				tdClass: prescription.isUrgent ? "td--background--red" : "",
			},

			{
				text: formatDateForPresenting(prescription.createdAt, false),
				column: 1.5,
				right: true,
				type: "date",
				tdClass: prescription.isUrgent ? "td--background--red" : "",
			},
			{
				text: prescription.archivedDateTime ? formatDateForPresenting(prescription.archivedDateTime, false) : "N/A",
				column: 1.5,
				right: true,
				type: "date",
				tdClass: prescription.isUrgent ? "td--background--red" : "",
			},
		];
	}

	_getPrescriptionTableRowForCompleted = (prescription) => {
		return [
			{
				text: renderFlagForRewrite(prescription),
				value: prescription.id,
				href: `/prescriptions/edit/${prescription.id}`,
				column: 1,
				tdClass: prescription.isUrgent ? "td--border--left--red--background"
					: prescription.isFlagged ? "td--border--left--red"
						: "",
				expiryDate: prescription.expiryDate,
				isUrgent: prescription.isUrgent,
			},
			{
				text: renderPaperCopyIcon(prescription),
				href: prescription.number ? `/prescriptions/edit/${prescription.id}` : "",
				column: 1.5,
				tdClass: prescription.isUrgent ? "td--background--red" : "",
			},
			{
				text: prescription.patient ? renderFlagForPatient(prescription) : "N/A",
				href: prescription.patient ? `/patients/edit/${prescription?.patient?.id}` : "",
				column: 1.5,
				tdClass: prescription.isUrgent ? "td--background--red" : "",
			},
			{
				column: 1.5,
				text: renderPrescriptionOwnerDropdown(prescription, this.assignAnOwner, this.state.partialAdmins),
				value: prescription.adminOwner?.name || "none",
				tdClass: prescription.isUrgent ? "td--background--red" : "",
			},
			{
				text: prescription.prescriber ? `${prescription.prescriber.firstName} ${prescription.prescriber.lastName}` : "N/A",
				column: 1.5,
				tdClass: prescription.isUrgent ? "td--background--red" : "",
			},
			{
				text: prescription.organisation.name || "N/A",
				column: 2,
				tdClass: prescription.isUrgent ? "td--background--red" : "",
			},
			{
				text: formatDateForPresenting(prescription.createdAt, false),
				column: 1,
				right: true,
				type: "date",
				tdClass: prescription.isUrgent ? "td--background--red" : "",
			},
			{
				text: prescription.expiryDate ? formatDateForPresenting(prescription.expiryDate, false) : "N/A",
				column: 1,
				right: true,
				type: "date",
				tdClass: prescription.isUrgent ? "td--background--red" : "",
			},
			{
				text: prescription.dateCompleted ? formatDate(prescription.dateCompleted, false, undefined, "DD/MM/YYYY") : "N/A",
				column: 1,
				right: true,
				type: "date",
				tdClass: prescription.isUrgent ? "td--background--red" : "",
			},
		];
	}

	_getPrescriptionTableRow(prescription) {
		return [
			{
				text: renderFlagForRewrite(prescription),
				value: prescription.id,
				href: `/prescriptions/edit/${prescription.id}`,
				column: 1,
				tdClass: prescription.isUrgent ? "td--border--left--red--background"
					: prescription.isFlagged ? "td--border--left--red"
						: "",
				expiryDate: prescription.expiryDate,
				isUrgent: prescription.isUrgent,
			},
			{
				text: renderPaperCopyIcon(prescription),
				href: prescription.number ? `/prescriptions/edit/${prescription.id}` : "",
				column: 2,
				tdClass: prescription.isUrgent ? "td--background--red" : "",
			},
			{
				text: prescription.patient ? renderFlagForPatient(prescription) : "N/A",
				href: prescription.patient ? `/patients/edit/${prescription?.patient?.id}` : "",
				column: 2,
				tdClass: prescription.isUrgent ? "td--background--red" : "",
			},
			{
				text: renderPrescriptionOwnerDropdown(prescription, this.assignAnOwner, this.state.partialAdmins),
				value: prescription.adminOwner?.name || "none",
				column: 1.5,
				tdClass: prescription.isUrgent ? "td--background--red" : "",
			},
			{
				text: prescription.prescriber ? `${prescription.prescriber.firstName} ${prescription.prescriber.lastName}` : "N/A",
				column: 1.5,
				tdClass: prescription.isUrgent ? "td--background--red" : "",
			},
			{
				text: prescription.organisation.name || "N/A",
				column: 2,
				tdClass: prescription.isUrgent ? "td--background--red" : "",
			},
			{
				text: prescription.expiryDate ? formatDateForPresenting(prescription.expiryDate, false) : "N/A",
				column: 1,
				right: true,
				type: "date",
				tdClass: prescription.isUrgent ? "td--background--red" : "",
			},
			{
				text: formatDateForPresenting(prescription.createdAt, false),
				column: 1,
				right: true,
				type: "date",
				tdClass: prescription.isUrgent ? "td--background--red" : "",
			}
		];
	}

	_isPrescriptionIdAlreadyInPhase(phase, prescriptionId, tableData) {
		const index = tableData[phase].findIndex((prescription) => {
			return prescriptionId === prescription[0].value;
		});
		return index >= 0;
	}

	_isPrescriptionNumberAlreadyInPhase(phase, prescriptionNumber, tableData) {
		const index = tableData[phase].findIndex((prescription) => {
			return prescriptionNumber === prescription[0].text;
		});
		return index >= 0;
	}

	//#endregion

	//#region CRUD Prescription

	setForCreate = async (patientName, patientId) => {
		this.setState({ loadingPrescription: true });

		try {
			await this._getOrganisations();

		} catch (error) {
			errorHelper.handleError(error);
		}

		if ((patientName && patientId)) {
			this.setState({ createPrescription: { ...this.state.createPrescription, patient: { value: { value: patientId, label: patientName } } } })
		}

		this.setState({ loadingPrescription: false });
	};

	getPrescribersFromOrg = async (orgId) => {
		try {
			await this._getPrescribersWithoutPatients(orgId);

		} catch (error) {
			errorHelper.handleError(error);
		}
	}

	setForUpdate = async (id) => {
		this.setState({ loadingPrescription: true });

		try {
			const prescription = await prescriptionService.getById(id);

			const updatePrescription = await this._convertPresciprtionFromAPI(prescription);
			const fieldsToAssignToRejectPrescriptionState = {
				prescriptionId: { value: id },
				paperPrescriptionId: { value: updatePrescription.prescriptionNumber.value, error: "" },
				patient: {
					value: {
						label: updatePrescription.patient.value.label,
						value: updatePrescription.patient.value.value
					},
					error: null
				}
			}

			this.setState({
				updatePrescription,
				loadingPrescription: false,
				rejectPrescription: Object.assign(
					this.state.rejectPrescription,
					{ ...fieldsToAssignToRejectPrescriptionState }
				)
			});

			await this._getPrescribers();
			await this._getOrganisations();

			this.setState({ orderedProductsTableData: [] }) //set product table data in case the user already added products to a previous (different) presc
		} catch (error) {
			errorHelper.handleError(error);
		}

	};

	setEventHistory = async (id) => {
		try {
			this.setState({ loadingEventHistory: true });
			let eventHistory = await this._getEventHistory(id);
			eventHistory = await this._processEventHistoryFromAPI(eventHistory);

			// Faster than to update the whole updatePrescritpion state
			this.setState({
				updatePrescription: {
					...this.state.updatePrescription,
					eventHistory: eventHistory,
				},
				assignPrescription: {
					...this.state.assignPrescription,
					eventHistory: { value: eventHistory },
				}
			});
		} catch (error) {
			errorHelper.handleError(error);
		}

		this.setState({ loadingEventHistory: false });
	}

	/**
	 * Gets prescribers for select input
	 */
	_getPrescribers = async () => {
		this.setState({ loadingPrescribers: true });

		const result = await prescriberService.filter("");

		const filteredPrescribers = result.map((prescriber) => {
			return {
				label: `${prescriber?.firstName} ${prescriber?.lastName}`,
				//for value, the backend always expects orgEmployeeId and therefore prescriber.id is almost never used
				value: prescriber?.organisationEmployee.id,
				prescriberId: prescriber?.id
			};
		});

		this.setState({ filteredPrescribers, loadingPrescribers: false });
	};
	/**
	 * Gets prescribers for select input
	 */
	_getPrescribersWithoutPatients = async (orgId) => {
		this.setState({ loadingPrescribers: true });

		const result = await prescriberService.getAllPrescribersWithoutPatients(orgId);
		const filteredPrescribers = result.map((prescriber) => {
			return {
				label: `${prescriber?.firstName} ${prescriber?.lastName}`,
				value: prescriber?.organisationEmployee.id,
				prescriberId: prescriber?.id,
			};
		});
		const filteredPrescribersOriginal = [...filteredPrescribers];

		this.setState({ filteredPrescribers, filteredPrescribersOriginal, loadingPrescribers: false });
	};

	/**
	 * Gets organisations for select input
	 */
	_getOrganisations = async () => {
		this.setState({ loadingOrganisations: true });

		const result = await OrganisationsService.filter("");

		const filteredOrganisations = result.map((organisation) => {
			return {
				label: `${organisation?.name}`,
				value: organisation?.id,
			};
		});

		this.setState({ filteredOrganisations, loadingOrganisations: false });
	};

	debouncedSearch = debounce((queryString) => {
		if (queryString && queryString.length >= 2) {
			this.searchPatients(queryString);
		}
	}, 500); // Adjust the 500 milliseconds as needed


	searchPatients = async (queryString) => {
		return await this._searchPatientsCallback(queryString);
	};
	/**
	 * Gets patients for select input
	 */
	_searchPatientsCallback = async (queryString, cancelToken = "") => {

		const result = await patientService.patientSearch(queryString, cancelToken);

		const filteredPatients = result.map((patient) => {
			let patientSignup = this._determinePatientVerification(patient);
			return {
				label: `${patient.firstName} ${patient.lastName} ${patient.dateOfBirth ? "- " + moment(patient.dateOfBirth).format("DD/MM/YYYY") : ""}`,
				value: patient.id,
				patientAccountType: this._determinePatientAccountType(patientSignup.isSignedUp, patientSignup.isPartiallySignedUp, patientSignup.isNotSignedUp),
				patientDetails: {
					...patient
				}
			};
		});

		this.setState({ filteredPatients });

		return filteredPatients;
	};

	_determinePatientVerification = (patient) => {
		let agreedToTerms = patient.carer ? patient.carer?.user?.agreedToTerms : patient.user?.agreedToTerms;
		let isEmailVerified = patient.carer ? patient.carer?.user?.isEmailVerified : patient.user?.isEmailVerified;
		let isIDVerified = patient.carer ? patient.carer?.user?.isIDVerified : patient.user?.isIDVerified;

		let isSignedUp = agreedToTerms && isEmailVerified && isIDVerified;
		let isPartiallySignedUp = !isSignedUp && (agreedToTerms || isEmailVerified || isIDVerified);
		let isNotSignedUp = !agreedToTerms && !isEmailVerified && !isIDVerified;

		return {
			isSignedUp: isSignedUp,
			isPartiallySignedUp: isPartiallySignedUp,
			isNotSignedUp: isNotSignedUp
		}
	}

	_determinePatientAccountType = (isSignedUp, isPartiallySignedUp, isNotSignedUp) => {
		if (isSignedUp) {
			return PatientAccountTypes.isSignedUp;
		}
		if (isPartiallySignedUp) {
			return PatientAccountTypes.isPartiallySignedUp;
		}
		if (isNotSignedUp) {
			return PatientAccountTypes.isNotSignedUp;
		}
	}

	create = async () => {
		this.setState({ creatingPrescription: true });

		try {
			const prescriptionCreate = {
				prescriberId: this.state.createPrescription.prescriber.value.value,
				prescription: this.state.createPrescription.prescription.value,
				envelopeTrackingCode: this.state.createPrescription.envelopeTrackingCode.value,
				organisationId: this.state.createPrescription.organisationId.value.value,
				// Always true for admin prescription upload
				confirmedConsent: this.state.createPrescription.confirmedConsent.value,
			};

			await prescriptionService.create(prescriptionCreate);
			notificationHelper.info(successfullUploadedPrescriptionMessage());
			this.resetUploadPrescriptionState();
		} catch (error) {
			errorHelper.handleError(error);
		}

		this.setState({ creatingPrescription: false });
	};

	update = async () => {
		this.setState({ updatingPrescription: true });

		try {
			const prescriptionUpdate = {
				id: this.state.updatePrescription.id.value,
				paperPrescriptionId: this.state.updatePrescription.prescriptionNumber.value,

				patientId: this.state.updatePrescription.patient.value.value,
				organisationEmployeeId: this.state.updatePrescription.prescriber?.value.value,
				organisationId: this.state.updatePrescription.organisationId?.value.value,

				prescribedDateTime: this.state.updatePrescription.datePrescribed.value,
				dateReceived: this.state.updatePrescription.dateReceived.value,
				dateCompleted: this.state.updatePrescription.dateCompleted.value,

				isPaperPrescriptionReceived: this.state.updatePrescription.isPaperCopyReceived.value,
				isT21Patient: this.state.updatePrescription.isT21.value,
				isUrgent: this.state.updatePrescription.isUrgent.value,
				isProcessed: this.state.updatePrescription.isProcessed.value,
				isArchived: this.state.updatePrescription.isArchived.value,
				isFlagged: this.state.updatePrescription.isFlagged.value,
				isCompleted: this.state.updatePrescription.isCompleted.value,
			};

			let result = await prescriptionService.update(prescriptionUpdate);

			const updatePrescription = await this._convertPresciprtionFromAPI(result);

			this.setState({ updatePrescription });

			notificationHelper.info("Prescription updated!");
		} catch (error) {
			errorHelper.handleError(error);
		}

		this.setState({ updatingPrescription: false });
	};

	archive = async () => {
		this.setState({ updatingPrescription: true });

		try {
			await prescriptionService.archive(this.state.updatePrescription.id.value, this.state.updatePrescription.archivedReason.value);

			this.setState({ updatePrescription: { ...this.state.updatePrescription, isArchived: { value: true } } });

			notificationHelper.info("Prescription archived!");
		} catch (error) {
			errorHelper.handleError(error);
		}

		this.setState({ updatingPrescription: false });
	};

	unarchive = async () => {
		this.setState({ updatingPrescription: true });

		try {
			await prescriptionService.unarchive(this.state.updatePrescription.id.value, this.state.updatePrescription.unarchivedReason.value);

			this.setState({ updatePrescription: { ...this.state.updatePrescription, isArchived: { value: false } } });

			notificationHelper.info("Prescription successfully unarchived!");
		} catch (error) {
			errorHelper.handleError(error);
		}

		this.setState({ updatingPrescription: false });
	};

	flag = async (currentAdminName) => {
		this.setState({ updatingPrescription: true });
		const id = this.state.updatePrescription.id.value;
		try {
			await prescriptionService.flag(this.state.updatePrescription.id.value, this.state.updatePrescription.flaggedReason.value);
			this.setEventHistory(id);

			let adminFlaggedBy = {
				value: {
					name: currentAdminName
				}
			};

			this.setState({ updatePrescription: { ...this.state.updatePrescription, isFlagged: { value: true }, adminFlaggedBy: adminFlaggedBy } });

			notificationHelper.info("Prescription flagged!");
		} catch (error) {
			errorHelper.handleError(error);
		}

		this.setState({ updatingPrescription: false });
	};

	unflag = async () => {
		this.setState({ updatingPrescription: true });
		const id = this.state.updatePrescription.id.value;

		try {
			await prescriptionService.unflag(this.state.updatePrescription.id.value);
			this.setEventHistory(id);

			this.setState({ updatePrescription: { ...this.state.updatePrescription, isFlagged: { value: false }, flaggedReason: { value: "" } } });

			notificationHelper.info("Prescription flag removed!");
		} catch (error) {
			errorHelper.handleError(error);
		}

		this.setState({ updatingPrescription: false });
	};

	setUrgent = async (currentAdminId) => {
		this.setState({ updatingPrescription: true });
		const id = this.state.updatePrescription.id.value;
		try {
			await prescriptionService.setIsUrgent(
				{
					Id: this.state.updatePrescription.id.value,
					isUrgent: !this.state.updatePrescription.isUrgent.value,
					adminId: currentAdminId,
					comment: this.state.updatePrescription.urgentReason.value
				});
			this.setEventHistory(id);

			this.setState({ updatePrescription: { ...this.state.updatePrescription, isUrgent: { value: !this.state.updatePrescription.isUrgent.value }, urgentReason: { value: "" } } });


			if (!this.state.updatePrescription.isUrgent.value)
				notificationHelper.info("Prescription marked as urgent!");
			else
				notificationHelper.info("Prescription un-marked as urgent!");
		} catch (error) {
			errorHelper.handleError(error);
		}

		this.setState({ updatingPrescription: false });
	};

	resubmit = async (orderId, prescriptionId) => {
		this.setState({ resubmitingOrder: true })
		try {
			await prescriptionService.resubmit(orderId);
			await this.setForUpdate(prescriptionId);
			notificationHelper.info("Order is active again. Patient has been notified!");

		} catch (error) {
			errorHelper.handleError(error);
		}
		this.setState({ resubmitingOrder: false })
	};

	sendToOrganisationWithProducts = async (payload, currentLocation) => {
		this.setState({ sendingToOrganisation: true });
		try {
			await prescriptionService.sendToOrganisationWithProducts(payload);
			await this.setEventHistory(payload.prescriptionId, false);

			if (currentLocation.includes("/prescriptions/assign/")) {
				if (moment(payload.datePrescribed, 'YYYY-MM-DD') > moment(Date.now()))
					notificationHelper.info(successfullSendToOrganisationMessageAssignFutureDatedPrescription());
				else
					notificationHelper.info(successfullSendToOrganisationMessageAssignPrescription());
				browserHelper.navigate("/prescriptions/toBeAssigned");
			}
			if (currentLocation.includes("/prescriptions/edit/")) {
				notificationHelper.info(successfullSendToOrganisationMessageEditPrescription());
				this.setForUpdate(payload.prescriptionId)
			}

		} catch (error) {
			errorHelper.handleError(error);
		}

		this.setState({ sendingToOrganisation: false });
	};

	sendToOrganisation = async (payload, currentLocation) => {
		this.setState({ sendingToOrganisation: true });
		try {
			await prescriptionService.sendToOrganisation(payload);
			await this.setEventHistory(payload.prescriptionId, false);

			if (currentLocation.includes("/prescriptions/assign/")) {
				if (moment(payload.datePrescribed, 'YYYY-MM-DD') > moment(Date.now()))
					notificationHelper.info(successfullSendToOrganisationMessageAssignFutureDatedPrescription());
				else
					notificationHelper.info(successfullSendToOrganisationMessageAssignPrescription());
				browserHelper.navigate("/prescriptions/new");
			}
			if (currentLocation.includes("/prescriptions/edit/")) {
				notificationHelper.info(successfullSendToOrganisationMessageEditPrescription());
			}

		} catch (error) {
			errorHelper.handleError(error);
		}

		this.setState({ sendingToOrganisation: false });
	};

	removeAwaitingRewirteStatus = async () => {
		this.setState({ removingAwaitingRewriteStatus: true });
		try {
			const payload = {
				prescriptionId: this.state.removeRewriteRequiredStatus.prescriptionId.value,
				reason: this.state.removeRewriteRequiredStatus.reason.value,
			}

			await prescriptionService.removeAwaitingRewirteStatus(payload);
			await this.setEventHistory(this.state.removeRewriteRequiredStatus.prescriptionId.value);

			this.setState({
				updatePrescription: { ...this.state.updatePrescription, awaitingRewrite: { value: false } },
				assignPrescription: { ...this.state.assignPrescription, prescription: { value: { ...this.state.assignPrescription.prescription.value, awaitingRewrite: false } } }
			})

			notificationHelper.info("Re-write required status removed");

		} catch (error) {
			errorHelper.handleError(error);
		}
		this.setState({ removingAwaitingRewriteStatus: false });
	}

	setForRemovingRewriteStatus = (prescriptionId) => {
		this.setState({ removeRewriteRequiredStatus: { reason: { value: "" }, prescriptionId: { value: prescriptionId } } })
	}

	archiveFromToBeAssigned = async () => {
		this.setState({ archivingPrescription: true });

		try {
			await prescriptionService.archive(this.state.assignPrescription.prescription.value.id, this.state.rejectPrescription.reason.value.value);

			this.setState({ updatePrescription: { ...this.state.updatePrescription, isArchived: { value: true } } });
			notificationHelper.info("Prescription archived!");
			browserHelper.navigate("/prescriptions/toBeAssigned")
		} catch (error) {
			errorHelper.handleError(error);
		}

		this.setState({ archivingPrescription: false });
	};

	UnarchiveFromToBeAssigned = async () => {
		this.setState({ unarchivingPrescription: true });

		try {
			await prescriptionService.unarchive(this.state.assignPrescription.prescription.value.id, this.state.rejectPrescription.reason.value.value);

			this.setState({ updatePrescription: { ...this.state.updatePrescription, isArchived: { value: false } } });
			notificationHelper.info("Prescription successfully unarchived!");
			browserHelper.navigate("/prescriptions/toBeAssigned")
		} catch (error) {
			errorHelper.handleError(error);
		}

		this.setState({ unarchivingPrescription: false });
	};

	flagFromAssign = async (currentAdminName) => {
		this.setState({ flaggingPrescription: true });
		const id = this.state.assignPrescription.prescription.value.id;

		try {
			await prescriptionService.flag(this.state.assignPrescription.prescription.value.id, this.state.flaggedReason.flaggedReason.value);
			this.setEventHistory(id);
			let adminFlaggedBy = {
				name: currentAdminName
			};

			this.setState({ assignPrescription: { ...this.state.assignPrescription, prescription: { value: { ...this.state.assignPrescription.prescription.value, isFlagged: true, flaggedReason: this.state.flaggedReason.flaggedReason.value, adminFlaggedBy } } } });
			this.setState(({ flaggedReason: { flaggedReason: { value: "" } } }))
			notificationHelper.info("Prescription flagged!");
		} catch (error) {
			errorHelper.handleError(error);
		}

		this.setState({ flaggingPrescription: false });
	};

	unflagFromAssign = async () => {
		this.setState({ flaggingPrescription: true });
		const id = this.state.assignPrescription.prescription.value.id;

		try {
			await prescriptionService.unflag(this.state.assignPrescription.prescription.value.id);
			this.setEventHistory(id);
			this.setState({ assignPrescription: { ...this.state.assignPrescription, prescription: { value: { ...this.state.assignPrescription.prescription.value, isFlagged: false } } } });

			notificationHelper.info("Prescription flag removed!");
		} catch (error) {
			errorHelper.handleError(error);
		}

		this.setState({ flaggingPrescription: false });
	};

	splitOrder = async () => {
		this.setState({ splittingOrder: true });

		const orderId = this.state.splitOrder.orderToSplit.value.id

		try {
			const newOrderId = await prescriptionService.splitOrder({
				orderId: orderId,
				orderedProductsToSplit: Object.values(this.state.splitOrder.orderedProductsToSplit).filter(op => { return op.isSelected }).map((op) => { return op.id }),
				splitOrderReason: this.state.splitOrder.splitOrderReason.value
			}
			);


			const oldOrder = await this._performStockCheck(orderId);
			const updatePrescriptionOldOrder = await this._convertPresciprtionFromAPI(oldOrder);
			this.setState({ updatePrescriptionOldOrder });

			const newOrder = await this._performStockCheck(newOrderId.orderId);
			const updatePrescriptionNewOrder = await this._convertPresciprtionFromAPI(newOrder);
			this.setState({ updatePrescriptionNewOrder });

			await this.setForUpdate(this.state.updatePrescription.id.value);

			notificationHelper.info(`Order ${orderId} successfully split`);

		} catch (error) {
			errorHelper.handleError(error);
		}

		this.setState({ splittingOrder: false });
	}

	/**
	 * Replaces a prescription image
	 */
	uploadPrescriptionPhoto = async ({ file }) => {
		try {
			const id = this.state.updatePrescription.id.value;

			const url = await prescriptionService.uploadPrescriptionPhoto(id, file);

			this.setState({ updatePrescription: { ...this.state.updatePrescription, imageUrl: { value: url } } });

			notificationHelper.info("Prescription image changed");
		} catch (error) {
			errorHelper.handleError(error);
		}
	};

	delete = async (id) => {
		try {
			await prescriptionService.delete(id);

			notificationHelper.info("Prescription deleted!");

			browserHelper.navigate("/prescriptions");
		} catch (error) {
			errorHelper.handleError(error);
		}
	};

	//#endregion

	//#region Orders

	/**
	 * Gets products for the modal that creates orders
	 */
	getProducts = async () => {
		try {
			let products = await prescriptionService.getProducts();
			products = products.map((product) => ({ value: product.id, label: product.name, product }));

			this.setState({ products });
			this.setState({ productsForDropdown: products, originalProductsForDropdown: products });
		} catch (error) {
			errorHelper.handleError(error);
		}
	};

	checkStockStatusForProduct = async (index) => {
		const { temporaryOrderedProducts } = this.state;
		const throttle = this.throttles.stockCheck;

		temporaryOrderedProducts[index].loadingStock = true;
		this.setState({
			temporaryOrderedProducts: temporaryOrderedProducts,
		});

		let status = -1;

		if (throttle) throttle.setTimeout(async () => {
			status = await prescriptionService.checkStockStatusForProduct(
				temporaryOrderedProducts[index].productId.value.value,
				temporaryOrderedProducts[index].amount.value
			)

			temporaryOrderedProducts[index].stockStatus.value = status.status;
			temporaryOrderedProducts[index].loadingStock = false;

			this.setState({
				temporaryOrderedProducts: temporaryOrderedProducts,
			});

		}, 300);
	}

	/*
		All of the selected products in ordered product modal are added to temporary ordered products (in the state)
		We iterate through all of them and add them to the newOrderedProducts array
		At the end we update the state with the actual ordered products and populate the table
	*/
	confirmTemporaryOrderedProducts = async () => {
		const patient = this.state.updatePrescription.patientData;
		const isT21Prescription = this.state.updatePrescription.isT21.value;
		const prescriptionOrganisationId = this.state.updatePrescription.organisationId.value.value;

		var tableRows = [];
		var newOrderedProducts = [];

		for (let i = 0; i < this.state.temporaryOrderedProducts.length; i++) {
			const { orderedProductsTableData } = this.state;
			const orderedProduct = this.state.temporaryOrderedProducts[i];

			if (orderedProduct.productId.value === undefined)
				continue;

			const product = this._getProductById(orderedProduct.productId.value.value)

			orderedProduct.isPriceOverriden.value = orderedProduct.fullPrice.value > 0;

			var orderedProductMembershipPrice = this._getOrderedProductMemebershipPrice(patient.subscriptionType, patient.subscriptionTier, product);

			orderedProduct.fullPrice.value = orderedProduct.fullPrice.value > 0 ?
				orderedProduct.fullPrice.value / orderedProduct.amount.value
				: orderedProductMembershipPrice;

			orderedProduct.retailPrice.value = product.retailPrice;

			//calculate t21 price (subsidy / base)
			if (orderedProduct.t21Price.value > 0) {
				orderedProduct.t21Price.value = orderedProduct.t21Price.value
			} else {
				if (product.isSubsidyEligible) {
					orderedProduct.t21PriceReason = " (Subsidy)"
					orderedProduct.t21Price.value = product.t21SubsidyPrice
				}
				else {
					orderedProduct.t21PriceReason = " (Base)"
					orderedProduct.t21Price.value = product.t21BasePrice
				}
			}

			const newOrderedProduct = {
				productId: orderedProduct.productId.value.value,
				amount: orderedProduct.amount.value,
				fullPrice: orderedProduct.fullPrice.value,
				t21Price: orderedProduct.t21Price,
				t21PriceReason: orderedProduct.t21PriceReason,
				isPriceOverriden: orderedProduct.isPriceOverriden.value,
				retailPrice: product.retailPrice.value,
				membershipPrice: orderedProductMembershipPrice,
			};

			const productAvailabilityTagColor = () => {
				if (orderedProduct.stockStatus.value === 0) return "green"
				if (orderedProduct.stockStatus.value === 1) return "orange"
				if (orderedProduct.stockStatus.value === 2) return "light-green-product-status"
				if (orderedProduct.stockStatus.value === 3) return "red"
				if (orderedProduct.stockStatus.value === 4) return "red"
			};

			const tableRow = [
				{ text: orderedProduct.productId.value.label, column: 3 },
				{ text: orderedProduct.amount.value, column: 1, right: true },
				{ text: (orderedProduct.isPriceOverriden.value ? orderedProduct.fullPrice.value.toFixed(2) : orderedProduct.retailPrice.value.toFixed(2)), column: 1.5, right: true },
				{ text: (isT21Prescription ? orderedProduct.t21Price.value.toFixed(2) + orderedProduct.t21PriceReason : "-"), column: 1.5, right: true },
				{ text: (patient.subscriptionType != 0 ? this._getOrderedProductMemebershipPrice(patient.subscriptionType, patient.subscriptionTier, product) : "-"), column: 1.5, right: true },
				{ text: productAvailabilityStatusMapper[orderedProduct.stockStatus.value], column: 2, right: true, color: productAvailabilityTagColor() },
				{ actions: { id: orderedProductsTableData.length }, column: 1.5, right: true },
			];

			tableRows.push(tableRow);
			newOrderedProducts.push(newOrderedProduct);
		}

		let orderedProducts = this.state.orderedProducts.concat(newOrderedProducts);

		this.setState({
			orderedProductsTableData: [...this.state.orderedProductsTableData, ...tableRows],

			orderedProducts: [...this.state.orderedProducts, ...newOrderedProducts],

			totalPriceForOrderedProducts: await prescriptionService
				.getTotalPriceForOrderedProducts({
					orderedProducts,
					isT21Prescription,
					prescriptionOrganisationId,
					patientId: patient.id,
				})
		});

		this.resetStateOfProductModal();
	};

	// reset form for next adding
	resetStateOfProductModal = () => {
		const temporaryOrderedProducts = [{
			productId: { value: "" },
			amount: { value: null },
			fullPrice: { value: 0 },
			retailPrice: { value: 0 },
			t21Price: { value: 0 },
			t21PriceReason: { value: "" },
			membershipPrice: { value: 0 },
			isPriceOverriden: { value: false },
			stockStatus: { value: -1 },
			isBannerChecked: { value: false },
			loadingStock: false,
		}]

		const newProductsForDropdown = this.state.originalProductsForDropdown.filter(product => !this.state.temporaryOrderedProducts.some(tempOrderedProduct => tempOrderedProduct.productId.value?.value === product.value));

		this.setState({
			temporaryOrderedProducts: temporaryOrderedProducts,
			productsForDropdown: newProductsForDropdown
		});
	}

	addTemporaryOrderedProduct = () => {
		const newTempOrderedProduct = {
			productId: { value: "" },
			amount: { value: null },
			fullPrice: { value: 0 },
			retailPrice: { value: 0 },
			t21Price: { value: 0 },
			t21PriceReason: { value: "" },
			membershipPrice: { value: 0 },
			isPriceOverriden: { value: false },
			stockStatus: { value: -1 },
			isBannerChecked: { value: false },
			loadingStock: false,
		}

		const temporaryOrderedProducts = [...this.state.temporaryOrderedProducts, newTempOrderedProduct];

		this.filterOutDropdownProducts();

		this.setState({
			temporaryOrderedProducts: temporaryOrderedProducts,
		});
	}

	onRemoveTemporaryOrderedProduct = (index) => {
		let temporaryOrderedProducts = this.state.temporaryOrderedProducts;
		temporaryOrderedProducts.splice(index, 1);

		this.filterOutDropdownProducts();

		this.setState({
			temporaryOrderedProducts: temporaryOrderedProducts,
		});
	}

	// filter out already added products
	filterOutDropdownProducts = () => {
		const newProductsForDropdown = this.state.originalProductsForDropdown
			.filter(product => !this.state.temporaryOrderedProducts.some(tempOrderedProduct => tempOrderedProduct.productId.value?.value === product.value))
			.filter(product => !this.state.orderedProducts.some(orderedProduct => orderedProduct.productId === product.value));

		this.setState({
			productsForDropdown: newProductsForDropdown
		});
	}

	createOrder = async () => {
		this.setState({ creatingOrder: true });

		try {
			const orderId = await this._submitOrderProducts();

			const result = await this._performStockCheck(orderId);

			const updatePrescription = await this._convertPresciprtionFromAPI(result);

			this.setState({ updatePrescription, creatingOrder: false });

			notificationHelper.info("Order created");
		} catch (error) {
			errorHelper.handleError(error);
		}

		this.setState({ creatingOrder: false });
	};

	_getOrderedProductMemebershipPrice = (subscriptionType, subscriptionTier, product) => {
		const priceForSubscriptionTierMapper = {
			[SubscriptionTypes.None]: {
				[0]: product.retailPrice,
				[1]: product.retailPrice,
				[2]: product.retailPrice,
			},
			[SubscriptionTypes.AllPatient]: {
				[1]: product.allPatientsT1Price,
				[2]: product.allPatientsT2Price,
			},
			[SubscriptionTypes.AccessSchemePatient]: {
				[1]: product.accessSchemeT1Price,
				[2]: product.accessSchemeT2Price,
			},
		};

		const priceForSubscriptionTier = priceForSubscriptionTierMapper[subscriptionType][subscriptionTier];

		return priceForSubscriptionTier ? priceForSubscriptionTier : product.fullPrice;
	}

	_submitOrderProducts = async () => {
		const prescriptionId = this.state.updatePrescription.id.value;
		const patientId = this.state.updatePrescription.patient.value.value;
		const { orderedProducts } = this.state;
		const removeShippingCostReason = this.state.removeShippingReason.removeShippingReason.value;
		const removeShippingCost = !this.state.chargeShipping.value;

		const { orderId } = await prescriptionService.addProductsToPrescription(prescriptionId, patientId, orderedProducts, removeShippingCostReason, removeShippingCost);

		this.setState({ orderedProductsTableData: [], orderedProducts: [] });

		return orderId;
	};

	_performStockCheck = async (orderId) => {
		return await prescriptionService.performStockCheck(orderId);
	};

	checkTempOrderBanner = (index) => {
		let temporaryOrderedProducts = this.state.temporaryOrderedProducts;
		temporaryOrderedProducts[index].isBannerChecked.value = !temporaryOrderedProducts[index].isBannerChecked.value;

		this.setState({
			temporaryOrderedProducts: temporaryOrderedProducts,
		});
	}

	/**
	 * Gets the product from a local list, NOT from the API
	 * @param {*} id
	 */
	_getProductById = (id) => {
		try {
			// eslint-disable-next-line
			const { product } = this.state.products.find((product) => product.value == id);

			return product;
		} catch (error) {
			errorHelper.handleError(error);
		}
	};

	cancelProductInOrder = async () => {
		this.setState({ cancelingProduct: true });

		try {
			const result = await prescriptionService.cancelProductInOrder(this.state.cancellation.orderedProductId, this.state.updatePrescription.cancelProductReason.value);

			const updatedPrescription = await this._convertPresciprtionFromAPI(result);

			this.setState({ updatePrescription: updatedPrescription, cancelingProduct: false });

		} catch (error) {
			errorHelper.handleError(error);
		}

		this.setState({ cancelingProduct: false });
	};


	forceComplete = async () => {
		try {
			if (!this.state.orderForUpdate) return;

			const prescription = await prescriptionService.forceCompleteOrder(this.state.orderForUpdate.id, this.state.forceCompletedReason.forceCompletedReason.value);

			const updatePrescription = await this._convertPresciprtionFromAPI(prescription);

			this.setState({ updatePrescription, orderForUpdate: null, forceCompletedReason: { ...initialForceCompletedState } });
		} catch (error) {
			errorHelper.handleError(error);
		}
	}

	setInitialRefundState = (order) => {
		const refundType = { value: RefundTypes.FullRefund };
		const refundAmount = { value: order.chargedPrice };
		const resetCustomRefundAmmountField = { value: this.state.refundOrderState.resetCustomRefundAmmountField.value + 1 };
		this.setState({ refundOrderState: { ...refundOrderInitialState, refundType, refundAmount, resetCustomRefundAmmountField } })
	}

	refundOrder = async () => {
		try {
			this.setState({ refundingOrder: true });
			if (!this.state.orderForUpdate) return;

			const payload = {
				orderId: this.state.orderForUpdate.id,
				reason: this.state.refundOrderState.refundReason.value,
				amount: this.state.refundOrderState.refundAmount.value
			}
			const prescription = await prescriptionService.refundOrder(payload);

			const updatePrescription = await this._convertPresciprtionFromAPI(prescription);

			this.setState({ updatePrescription, orderForUpdate: null });
			notificationHelper.info("Refund successful");
		} catch (error) {
			errorHelper.handleError(error);
		}
		this.setState({ refundingOrder: false });
	}

	updateShipmentStatus = async (orderId, newStatus) => {
		this.setState({ updatingShippingStatus: true });

		try {
			let updatePrescription = await prescriptionService.updateShipmentStatus(orderId, newStatus, this.state.updatePrescription.id.value);

			updatePrescription = await this._convertPresciprtionFromAPI(updatePrescription);

			this.setState({ updatePrescription });

			notificationHelper.info("Shipment status updated!");
		} catch (error) {
			errorHelper.handleError(error);
		}

		this.setState({ updatingShippingStatus: false });
	};

	generateLabelForOrder = async (id, comments) => {
		this.setState({ updatingShippingStatus: true });
		this.setState({ generatingLabel: true });
		try {
			await prescriptionService.generateLabelForOrder(id, comments);
		} catch (error) {
			errorHelper.handleError(error);
		}

		this.setState({ updatingShippingStatus: false });
		this.setState({ generatingLabel: false });
	};

	regenerateLabelForOrder = async () => {
		this.setState({ generatingLabel: true });
		const regenerateLabelState = this.state.regenerateLabelState;
		try {
			const payload = {
				orderId: regenerateLabelState.orderId.value,
				address1: regenerateLabelState.address1.value,
				address2: regenerateLabelState.address2.value,
				address3: regenerateLabelState.address3.value,
				city: regenerateLabelState.city.value,
				zipCode: regenerateLabelState.zipCode.value,
				note: regenerateLabelState.note.value,
			}
			await prescriptionService.regenerateLabelForOrder(payload);
			this.resetRegenerateLabelState();
		} catch (error) {
			errorHelper.handleError(error);
		}
		this.setState({ generatingLabel: false });
	}

	resetRegenerateLabelState = () => {
		this.setState({ regenerateLabelState: { ...regenerateLabelInitialState } })
	}

	contact = async () => {
		this.setState({ contacting: true });

		try {
			const payload = {
				prescriptionId: this.state.updatePrescription.id.value,
				subject: this.state.contact.subject.value,
				message: this.state.contact.message.value,
				accountTypes: this.state.contact.accountTypes.value,
			};

			await prescriptionService.contact(payload);
			this.setEventHistory(this.state.updatePrescription.id.value);

			notificationHelper.info("Your message has been sent to the patient");
		} catch (error) {
			errorHelper.handleError(error);
		}

		this.setState({ contacting: false });
	};

	removeOrderedProductFromUnsortedList = async (index) => {
		const { orderedProductsTableData, orderedProducts, productsForDropdown, products } = this.state;
		const isT21Prescription = this.state.updatePrescription.isT21.value;
		const prescriptionOrganisationId = this.state.updatePrescription.organisationId.value.value;
		const patient = this.state.updatePrescription.patientData;

		orderedProductsTableData.splice(index, 1);

		let removedProductId = orderedProducts[index].productId
		let productForDropdown = products.find(product => product.product.id === removedProductId)

		orderedProducts.splice(index, 1);

		productsForDropdown.push(productForDropdown)

		const productsForDropdownSorted = productsForDropdown
			.sort((previousProduct, currentProduct) => previousProduct.value - currentProduct.value);

		this.setState({
			orderedProductsTableData,
			orderedProducts,
			productsForDropdown: productsForDropdownSorted,
			totalPriceForOrderedProducts: await prescriptionService
				.getTotalPriceForOrderedProducts({
					orderedProducts,
					isT21Prescription,
					prescriptionOrganisationId,
					patientId: patient.id,
				}),
		});
	}

	//#endregion

	//#region Payment

	async initPayment(order) {
		const mskResponse = await PaymentService.merchantSessionKey();
		const sessionKey = mskResponse.merchantSessionKey;

		this.setState({ orderThatIsBeingPaid: order });

		window
			.sagepayCheckout({
				merchantSessionKey: sessionKey,
				onTokenise: async (tokenisationResult) => {
					if (tokenisationResult.success) {
						const { cardIdentifier } = tokenisationResult;
						const { expiry } = mskResponse;

						this._setPaymentDetails(sessionKey, cardIdentifier, expiry, order);
					} else {
						console.error("Tokenisation failed", tokenisationResult.error.errorMessage);
					}
				},
			})
			.form();
	}

	_setPaymentDetails(merchantSessionKey, cardIdentifier, expiry, order) {
		const {
			customerFirstName,
			customerLastName,
			customerEmail,
			customerPhone,
			address1,
			address2,
			postalCode,
			userId,
		} = this._getCustomerDetails();

		const paymentDetails = {
			orderId: order.id,
			prescriptionId: this.state.updatePrescription.id.value,
			userId,

			merchantSessionKey,
			cardIdentifier,
			expiry,

			amount: { value: null },
			customerFirstName: { value: customerFirstName },
			customerLastName: { value: customerLastName },
			customerEmail: { value: customerEmail },
			customerPhone: { value: customerPhone },

			address1: { value: address1 },
			address2: { value: "" },
			address3: { value: "" },
			postalCode: { value: postalCode },
			city: { value: address2 },
			country: { value: "GB" },
			state: { value: "" },

			billingDetailsAreTheSameAsShippingDetails: { value: true },

			billingFirstName: { value: "" },
			billingLastName: { value: "" },
			billingAddress1: { value: "" },
			billingAddress2: { value: "" },
			billingAddress3: { value: "" },
			billingCity: { value: "" },
			billingPostalCode: { value: "" },
			billingCountry: { value: "GB" },
			billingState: { value: "" },

			currency: "GBP",
			description: { value: `${order.prescriptionNumber}` },
		};

		this.setState({ paymentDetails });
	}

	_getCustomerDetails() {
		let { patientDetailsForBilling: customer } = this.state.updatePrescription;

		if (customer.carer) {
			customer = customer.carer;
		}

		return {
			customerFirstName: customer.firstName,
			customerLastName: customer.lastName,
			customerPhone: customer.telephoneNo,
			customerEmail: customer.user.email,

			address1: customer.address1,
			address2: customer.address2,
			postalCode: customer.zipCode,

			userId: customer.user.id,
		};
	}

	createTransaction = async () => {
		this.setState({ creatingTransaction: true });

		try {
			const { paymentDetails } = this.state;

			const transactionInformation = {
				merchantSessionKey: paymentDetails.merchantSessionKey,
				cardIdentifier: paymentDetails.cardIdentifier,
				currency: paymentDetails.currency,
				amount: paymentDetails.amount.value,
				customerFirstName: paymentDetails.customerFirstName.value,
				customerLastName: paymentDetails.customerLastName.value,
				billingAddress: {
					address1: paymentDetails.address1.value,
					address2: paymentDetails.address2.value,
					address3: paymentDetails.address3.value,
					city: paymentDetails.city.value,
					postalCode: paymentDetails.postalCode.value,
					country: paymentDetails.country.value,
					state: paymentDetails.state.value,
				},
				description: paymentDetails.description.value,
				customerEmail: paymentDetails.customerEmail.value,
				customerPhone: paymentDetails.customerPhone.value,
				orderId: paymentDetails.orderId,
				prescriptionId: paymentDetails.prescriptionId,
				userId: paymentDetails.userId,
			};

			// If the billing details are not the same as shipping details
			// Then billing details become shipping details
			if (!paymentDetails.billingDetailsAreTheSameAsShippingDetails.value) {
				transactionInformation.billingAddress = {
					address1: paymentDetails.billingAddress1.value,
					address2: paymentDetails.billingAddress2.value,
					address3: paymentDetails.billingAddress3.value,
					city: paymentDetails.billingCity.value,
					postalCode: paymentDetails.billingPostalCode.value,
					country: paymentDetails.billingCountry.value,
					state: paymentDetails.billingState.value,
				};
			}

			const prescription = await prescriptionService.payForOrder(transactionInformation);

			const updatePrescription = await this._convertPresciprtionFromAPI(prescription);

			this.setState({ updatePrescription, creatingTransaction: false });

			notificationHelper.info("Payment successful");
		} catch (error) {
			errorHelper.handleError(error);

			// generate another token and form for payment
			this.initPayment(this.state.orderThatIsBeingPaid);
		}

		this.setState({ creatingTransaction: false, paymentDetails: null });
	};

	//#endregion

	//#region Prescription conversion for state

	_convertPresciprtionFromAPI = async (prescription) => {
		const eventHistory = await this._getEventHistory(prescription.id);
		const supportComms = await this._getSupportComms(prescription.prescriptionNumber);

		const result = this._processPrescriptionFromAPI(prescription);

		result.patientData = prescription.patient;
		result.orders = { value: this._processOrdersFromAPI(prescription.orders, prescription.prescriptionNumber, prescription.isT21Patient, result.patientData) };
		result.eventHistory = await this._processEventHistoryFromAPI(eventHistory);
		result.supportComms = this._processSupportCommsFromAPI(supportComms);
		result.tempPatient = { ...prescription.tempPatient };

		result.splitOrders = this._splitOrdersArray(result.orders)

		return result;
	};

	_getEventHistory = async (prescriptionId) => {
		const eventHistory = await prescriptionService.getEventHistoryForPrescription(prescriptionId);

		return eventHistory.reverse();
	};

	_getSupportComms = async (paperPrescriptionId) => {
		try {
			this.setState({ loadingSupportComms: true });
			return await prescriptionService.getSupportComms(paperPrescriptionId);
		} catch (error) {
			errorHelper.handleError(error);
		}

		finally {
			if (!paperPrescriptionId) return [];
			this.setState({ loadingSupportComms: false });
		}

	};

	_processPrescriptionFromAPI = (prescription) => {
		let patientSignup = this._determinePatientVerification(prescription.patient);
		const result = {
			id: { value: prescription.id },
			prescriptionNumber: { value: prescription.prescriptionNumber ?? "" },
			imageUrl: { value: prescription.imageUrl },

			// this is used when payment is processed
			patientDetailsForBilling: prescription.patient,
			patient: {
				value: {
					value: prescription.patient.id,
					label: `${prescription.patient.firstName} ${prescription.patient.lastName} ${prescription.patient.dateOfBirth}`,
					fullName: `${prescription.patient.firstName} ${prescription.patient.lastName}`,

					subscriptionType: prescription.patient.subscriptionType,
					subscriptionTier: prescription.patient.subscriptionTier,

					patientAccountType: this._determinePatientAccountType(patientSignup.isSignedUp, patientSignup.isPartiallySignedUp, patientSignup.isNotSignedUp),
				},
			},
			prescriber: {
				value: {
					value: prescription.prescriber?.id,
					label: prescription.prescriber ? `${prescription.prescriber.firstName} ${prescription.prescriber.lastName}` : "",
					prescriberId: prescription.prescriber?.prescriberId,
				},
			},
			organisationId: {
				value: {
					value: prescription?.organisation?.id,
					label: prescription?.organisation?.name,
				},
			},

			adminFlaggedBy: {
				value: {
					id: prescription.adminFlaggedBy?.name,
					name: prescription.adminFlaggedBy?.name
				},
			},

			submittedBy: { value: prescription.submittedBy },
			expiryDate: { value: prescription.expiryDate },

			datePrescribed: { value: prescription.prescribedDateTime },
			dateReceived: { value: prescription.dateReceived },
			dateCompleted: { value: prescription.dateCompleted },
			isPaperCopyReceived: { value: prescription.isPaperCopyReceived },
			isT21: { value: prescription.isT21Patient },
			isUrgent: { value: prescription.isUrgent },
			isProcessed: { value: prescription.isProcessed },
			isArchived: { value: prescription.isArchived },
			isFlagged: { value: prescription.isFlagged },
			isCompleted: { value: prescription.isCompleted },

			orders: { value: [] },
			orderedProducts: { value: [] },

			canAddProducts: { value: prescription.canAddProducts },

			createdAt: { value: prescription.createdAt },
			updatedAt: { value: prescription.updatedAt },

			rewriteChildPrescriptionId: { value: prescription.rewriteChildPrescriptionId },
			rewriteParentPrescriptionId: { value: prescription.rewriteParentPrescriptionId },
			rewriteReason: { value: prescription.rewriteReason },
			archivedReason: { value: prescription.archivedReason },

			archivedDateTime: { value: prescription.archivedDateTime },

			unarchivedReason: { value: "" },
			flaggedReason: { value: prescription.flaggedReason },
			urgentReason: { value: "" },
			flaggedByAdminId: { value: prescription.flaggedByAdminId },
			awaitingRewrite: { value: prescription.awaitingRewrite },

			doesPrescriberHaveAccount: { value: prescription.doesPrescriberHaveAccount },
			doesPatientHaveAccount: { value: prescription.doesPatientHaveAccount },
			doesCarerHaveAccount: { value: prescription.doesCarerHaveAccount },
			tempPatient: { value: prescription.tempPatient, error: "" },
			organisation: { value: prescription.organisation, error: "" },
			cancelProductReason: { value: "" },
			adminOwner: { value: prescription.adminOwner },
			doesPatientHaveACarer: { value: !!prescription.patient.carer },

		};

		return result;
	};
	processOrderedProductsFromAPI = (order, isT21Prescription, patientData) => {
		if (order.status === OrderStatuses.OutOfStock) {
			return this._processOrderedProductsFromAPIIntoAwaitingStockTable(order.orderedProducts, isT21Prescription, patientData)
		}
		if (order.status === OrderStatuses.PaymentDeadlineMissed) {
			return this._processOrderedProductsFromAPIIntoPaymentMissedTable(order.orderedProducts, isT21Prescription, patientData)
		}
		return this._processOrderedProductsFromAPIIntoTableData(order.orderedProducts, isT21Prescription, patientData)
	}

	_processOrdersFromAPI = (orders, prescriptionNumber, isT21Prescription, patientData) => {
		const result = orders.map((order) => {
			const orderHeading = OrderStatusesToOrderHeaderMapper[order.status];
			const totalPrice = this._calculateOrderPrice(order, patientData);
			const orderedProducts = this.processOrderedProductsFromAPI(order, isT21Prescription, patientData);

			return {
				id: order.id,
				status: order.status,
				invoiceName: order.invoiceName,
				invoiceUrl: order.invoiceUrl,
				heading: `Order ${order.id} - ${orderHeading.text}`,
				headingClass: order.color,
				orderedProducts,
				orderedProductsRaw: order.orderedProducts,
				paymentTransactionData: order.paymentTransactionData,
				chargedPrice: order.chargedPrice,
				totalPrice,
				externalTrackingCode: order.externalTrackingCode,
				refunded: order.refunded,
				prescriptionNumber,
				isSplit: order.isSplit
			};
		});

		return result;
	};

	_splitOrdersArray(orders) {
		var numberOfSplit = orders.value.filter(order => order.isSplit === true).length
		var reversedOrders = [...orders.value];
		reversedOrders = reversedOrders.reverse();

		let result = [];
		var numberOfSkipped = 0;
		reversedOrders.forEach((el, index) => {
			if (el.isSplit) {
				result.push(`Auto-split order ${index + 1 - numberOfSkipped} / ${numberOfSplit}`);
			} else {
				result.push("");
				numberOfSkipped++;
			}
		})

		return result.reverse();
	}

	_calculateOrderPrice(order, patientData) {
		const additionalCost = order.shipmentCost + order.tax;
		var totalPrice = 0;

		//for non subscribers
		if (patientData.subscriptionType == 0) {
			totalPrice = order.orderedProducts
				.filter(e => !e.isCancelled)
				.reduce((accumulate, orderProduct) =>
					accumulate + orderProduct.fullPrice * orderProduct.amount, additionalCost
				);
		}
		//for subscribers
		else {
			order.orderedProducts.forEach(orderedProduct => {
				orderedProduct.membershipPrice = this._getOrderedProductMemebershipPrice(
					patientData.subscriptionType,
					patientData.subscriptionTier,
					orderedProduct.product
				);
			});

			totalPrice = order.orderedProducts
				.filter(e => !e.isCancelled)
				.reduce((accumulate, orderProduct) =>
					accumulate + orderProduct.fullPrice * orderProduct.amount, additionalCost //should be reverted to membership price for phase 2 of subscriptions
				);
		}
		return roundPrice(totalPrice);
	}

	_processOrderedProductsFromAPIIntoTableData = (orderedProducts, isT21Prescription, patientData) => {
		return orderedProducts.map((orderedProduct) => {
			return [
				{
					text: orderedProduct.name,
					href: `/products/edit/${orderedProduct.product.id}`,
					column: 3,
				},
				{
					text: orderedProduct.amount,
					column: 1,
					right: true,
				},
				{
					text: orderedProduct.retailPrice,
					column: 1.5,
					right: true,
				},
				{
					text: isT21Prescription ? (orderedProduct.t21Price + orderedProduct.t21PriceReason) : "-",
					column: 1.5,
					right: true,
				},
				{
					text: patientData.subscriptionType != 0 ? this._getOrderedProductMemebershipPrice(patientData.subscriptionType, patientData.subscriptionTier, this._getProductById(orderedProduct.product.id)) : "-",
					column: 1.5,
					right: true,
				},
				{
					...orderedProduct.statusLabel,
					column: 2,
					right: true,
				},
				{
					actions: orderedProduct.isCancelled ? null : { id: orderedProduct.id },
					column: 1.5,
					right: true,
				},
			];
		});
	};

	_processOrderedProductsFromAPIIntoAwaitingStockTable = (orderedProducts, isT21Prescription, patientData) => {
		return orderedProducts.map((orderedProduct) => {
			const statusLabel = orderedProduct.isCancelled ? orderedProduct.statusLabel : StockStatusToProductLabelMapper[orderedProduct.product.status]
			return [
				{
					text: orderedProduct.name,
					href: `/products/edit/${orderedProduct.product.id}`,
					column: 3,
				},
				{
					text: orderedProduct.amount,
					column: 1,
					right: true,
				},
				{
					text: orderedProduct.fullPrice,
					column: 1.5,
					right: true,
				},
				{
					text: orderedProduct.t21Price + orderedProduct.t21PriceReason,
					column: 1.5,
					right: true,
				},
				{
					text: patientData.subscriptionType != 0 ? this._getOrderedProductMemebershipPrice(patientData.subscriptionType, patientData.subscriptionTier, this._getProductById(orderedProduct.product.id)) : "-",
					column: 1.5,
					right: true,
				},
				{
					...statusLabel,
					column: 2,
					right: true,
				},
				{
					actions: orderedProduct.isCancelled ? null : { id: orderedProduct.id },
					column: 1.5,
					right: true,
				},
			];
		});
	};

	_processOrderedProductsFromAPIIntoPaymentMissedTable = (orderedProducts, isT21Prescription, patientData) => {
		return orderedProducts.map((orderedProduct) => {
			const statusLabel = orderedProduct.isCancelled ? orderedProduct.statusLabel : StockStatusToProductLabelMapper[orderedProduct.product.status]
			return [
				{
					text: orderedProduct.name,
					href: `/products/edit/${orderedProduct.product.id}`,
					column: 3,
				},
				{
					text: orderedProduct.amount,
					column: 1,
					right: true,
				},
				{
					text: orderedProduct.fullPrice,
					column: 1.5,
					right: true,
				},
				{
					text: orderedProduct.t21Price + orderedProduct.t21PriceReason,
					column: 1.5,
					right: true,
				},
				{
					text: patientData.subscriptionType != 0 ? this._getOrderedProductMemebershipPrice(patientData.subscriptionType, patientData.subscriptionTier, this._getProductById(orderedProduct.product.id)) : "-",
					column: 1.5,
					right: true,
				},
				{
					...statusLabel,
					column: 2,
					right: true,
				},
				{
					actions: orderedProduct.isCancelled ? null : { id: orderedProduct.id },
					column: 1.5,
					right: true,
				},
			];
		});
	};

	_processEventHistoryFromAPI = async (eventHistory) => {
		const result = eventHistory.map((event) => {
			return [
				{
					text: formatText(event.eventContent),
					column: 6,
				},
				{
					text: formatDateForPresenting(event.createdAt, true, ""),
					column: 6,
					right: true,
				},
			];
		});

		return result;
	};

	_processSupportCommsFromAPI = (supportComms) => {
		const tickets = supportComms.results ?? [];

		const result = tickets.map((supportComm) => [
			{
				text: supportComm.url,
				href: supportComm.url,
				externalLink: true,
				column: 3,
			},
			{
				text: supportComm.subject || "N/A",
				column: 3,
			},
			{
				text: supportComm.via.channel,
				column: 2,
				right: true,
			},
			{
				text: supportComm.status,
				column: 2,
				right: true,
			},
			{
				text: formatDate(supportComm.created_at),
				column: 2,
				right: true,
			},
		]);

		return result;
	};

	//#endregion

	//#region Comments
	createComment = async (currentAdministrator) => {
		try {
			this.setState({ creatingComment: true });
			const commentCreate = {
				eventContent: "{{" + currentAdministrator + "}}: " + this.state.createComment.commentText.value,
				prescriptionId: this.state.createComment.prescriptionId.value,
			};

			await prescriptionService.createComment(commentCreate);

			await this.setEventHistory(this.state.createComment.prescriptionId.value);
			this.setCreateCommentState(this.state.createComment.prescriptionId.value);

			notificationHelper.info("Comment added!");
		} catch (error) {
			errorHelper.handleError(error);
		}

		this.setState({ creatingComment: false });
	};
	//#endregion

	//#region Payment link
	sendPaymentLink = async (orderId) => {
		try {
			await prescriptionService.sendPaymentLink(orderId);
			notificationHelper.info("Payment link successfully sent to patient");
		} catch (error) {
			errorHelper.handleError(error);
		}
	}
	//#endregion

	//region assign prescription

	assignImage = async () => {
		const payload = {
			prescriptionId: this.state.assignPrescription.prescription.value.id,
			patientId: this.state.assignPrescription.patient.value.value,
			paperPrescriptionId: this.state.assignPrescription.paperPrescriptionId.value,
			prescribedDate: this.state.assignPrescription.datePrescribed.value,
		}
		try {
			await prescriptionService.assignPrescriptionImage(payload);
			const patientName = this.state.assignPrescription.patient.value.label.split(" ");
			if (moment(Date.now()) > moment(this.state.assignPrescription.datePrescribed.value, 'YYYY-MM-DD')) {
				notificationHelper.info(successfullAssignedMessage(`${patientName[0]} ${patientName[1]}`))
			} else {
				notificationHelper.info("Prescription moved to Future Dated Scripts")
			}
			browserHelper.navigate('/prescriptions/toBeAssigned');
		} catch (error) {
			errorHelper.handleError(error);
		}

	}

	setForAssigning = async (id) => {
		this.setCreateCommentState(id);
		this.setState({ loadingPrescription: true });
		this.getProducts();
		this.resetRejectPrescriptionState()

		try {
			await this._getPrescribers();
			const prescription = await prescriptionService.getById(id);

			const convertedPrescription = await this._convertPresciprtionFromAPI(prescription);

			const fieldsToAssignToRejectPrescriptionState = {
				prescriptionId: { value: id },
				paperPrescriptionId: { value: convertedPrescription.prescriptionNumber.value, error: "" },
			}

			if (convertedPrescription.patient.value.value) {
				Object.assign(fieldsToAssignToRejectPrescriptionState, {
					patient: {
						value: {
							label: convertedPrescription.patient.value.label,
							value: convertedPrescription.patient.value.value
						},
						error: null
					}
				})
			}

			let prescriber = {
				fullName: prescription.prescriber.firstName + ' ' + prescription.prescriber.lastName,
				id: prescription.prescriber.id,
			}

			let newAssignPrescriptionState = {
				...this.state.assignPrescription,
				prescription: { value: prescription },
				prescriber: { value: prescriber },
				trackingNumber: { value: prescription.envelopeTrackingCode },
				eventHistory: { value: convertedPrescription.eventHistory },
				tempPatient: { value: convertedPrescription.tempPatient }
			}

			if (convertedPrescription.patient.value.value) {
				Object.assign(newAssignPrescriptionState, {
					patient: {
						value: {
							label: convertedPrescription.patient.value.label,
							value: convertedPrescription.patient.value.value
						},
						error: null
					}
				})
			}

			this.setState({ assignPrescription: newAssignPrescriptionState, rejectPrescription: { ...this.state.rejectPrescription, ...fieldsToAssignToRejectPrescriptionState } })

		} catch (error) {
			errorHelper.handleError(error);
		}

		this.setState({ loadingPrescription: false });
	};

	resetAssignPrescriptionState = () => {
		this.setState({ assignPrescription: assignPrescriptionInitialState })
	}

	getAllToBeAssigned = async () => {
		try {
			this.setState({ loadingToBeAssigned: true });
			const result = await prescriptionService.filterUnasigned('');
			const tableData = this._createTableData(result);
			this.setState({ tableData: { ...this.state.tableData, toBeAssigned: tableData } });
		} catch (error) {
			console.log(error);
		}
		this.setState({ loadingToBeAssigned: false });

	}

	getAllAwaitingRewrite = async () => {
		try {
			this.setState({ loadingAwaitingRewrite: true });

			this._filterCallbackAwaitingRewrite("");
		} catch (error) {
		}
		this.setState({ loadingAwaitingRewrite: false });
	}

	checkAssignPatientToPrescription = async (patientId, prescriptionId) => {
		const payload = {
			prescriptionId,
			patientId
		}
		const result = await prescriptionService.checkAssignPatientToPrescription(payload);

		this.setState({ assignPrescription: Object.assign(this.state.assignPrescription, { patientsActivePrescriptions: { value: result } }) })

		try {
		} catch (error) {
			errorHelper.handleError(error)
		}
	}

	getAllFutureScripts = async () => {
		try {
			this.setState({ loadingFutureScripts: true });

			this._filterCallbackFutureScripts("");
		} catch (error) {
		}
		this.setState({ loadingFutureScripts: false });
	}

	_createTableData = (data) => {
		return data.map(prescription => this._createTableRow(prescription));
	}

	_createTableRow = (prescription) => {
		return [
			{
				text: renderFlagForRewrite(prescription),
				value: prescription.id,
				column: 2,
				href: `/prescriptions/assign/${prescription.id}`,
				tdClass: prescription.isUrgent ? "td--border--left--red--background"
					: prescription.isFlagged ? "td--border--left--red"
						: "",
				expiryDate: prescription.expiryDate,
				isUrgent: prescription.isUrgent,
			},
			{
				text: prescription.prescriber,
				column: 2.5,
				tdClass: prescription.isUrgent ? "td--background--red" : "",
			},
			{
				text: prescription.organisationName,
				column: 2.5,
				tdClass: prescription.isUrgent ? "td--background--red" : "",
			},
			{
				text: prescription.trackingNumber,
				column: 2.5,
				tdClass: prescription.isUrgent ? "td--background--red" : "",
			},
			{
				text: prescription.createdAt,
				value: prescription.createdAt,
				type: 'date',
				column: 2.5,
				tdClass: prescription.isUrgent ? "td--background--red" : "",
				right: true,
			},
		]
	}

	_createTableRowForAwaitingRewrite = (prescription) => {
		let hrefToPrescriptionPage = prescription.patient ? `/prescriptions/edit/${prescription.id}` : `/prescriptions/assign/${prescription.id}`;
		return [
			{
				text: renderFlagForRewrite(prescription),
				value: prescription.id,
				column: 2,
				href: hrefToPrescriptionPage,
				tdClass: prescription.isUrgent ? "td--border--left--red--background"
					: prescription.isFlagged ? "td--border--left--red"
						: "",
				expiryDate: prescription.expiryDate,
				isUrgent: prescription.isUrgent,
			},
			{
				text: prescription.paperPrescriptionId || "N/A",
				column: 2,
				href: prescription.paperPrescriptionId ? hrefToPrescriptionPage : null,
				tdClass: prescription.isUrgent ? "td--background--red" : "",
			}, {
				text: `${prescription.patient?.firstName ?? "N/A"} ${prescription.patient?.lastName ?? ""}`,
				column: 2,
				href: prescription.patient ? `/patients/edit/${prescription.patient.id}` : null,
				tdClass: prescription.isUrgent ? "td--background--red" : "",
			},
			{
				text: prescription.organisationName,
				column: 2,
				tdClass: prescription.isUrgent ? "td--background--red" : "",
			},
			{
				text: prescription.rewriteReason,
				column: 2,
				tdClass: prescription.isUrgent ? "td--background--red" : "",
			},
			{
				text: prescription.rewriteDateTime ? formatDateForPresenting(prescription.rewriteDateTime, false) : "N/A",
				column: 2,
				right: true,
				tdClass: prescription.isUrgent ? "td--background--red" : "",
				type: "date",
			},
		]
	}

	_createTableRowForFutureDatedScripts = (prescription) => {
		let hrefToPrescriptionPage = prescription.patient ? `/prescriptions/edit/${prescription.id}` : `/prescriptions/assign/${prescription.id}`;
		return [
			{
				text: renderFlagForRewrite(prescription),
				value: prescription.id,
				column: 2,
				href: hrefToPrescriptionPage,
				tdClass: prescription.isUrgent ? "td--border--left--red--background"
					: prescription.isFlagged ? "td--border--left--red"
						: "",
				expiryDate: prescription.expiryDate,
				isUrgent: prescription.isUrgent,
			},
			{
				text: `${prescription.patient?.firstName ?? "N/A"} ${prescription.patient?.lastName ?? ""}`,
				column: 3,
				href: prescription.patient ? `/patients/edit/${prescription.patient.id}` : null,
				tdClass: prescription.isUrgent ? "td--background--red" : "",
			},
			{
				text: prescription.organisationName,
				column: 3,
				tdClass: prescription.isUrgent ? "td--background--red" : "",
			},
			{
				text: prescription.createdAt,
				value: prescription.createdAt,
				type: 'date',
				column: 2,
				tdClass: prescription.isUrgent ? "td--background--red" : "",
			},
			{
				text: moment(prescription.prescribedDateTime).format("DD/MM/YY"),
				value: moment(prescription.prescribedDateTime).format("DD/MM/YY"),
				type: 'date',
				column: 2,
				tdClass: prescription.isUrgent ? "td--background--red" : "",
				right: true
			},
		]
	}

	_createTableDataForAwaitingRewrite = (data) => {
		return data.map(prescription => this._createTableRowForAwaitingRewrite(prescription));
	}

	_createTableDateForFutureDatedScripts = (data) => {
		return data.map(prescription => this._createTableRowForFutureDatedScripts(prescription));
	}

	_filterCallbackAssignPrescription = async (queryString) => {
		try {
			const result = await prescriptionService.filterUnasigned(queryString);

			const tableData = this._createTableData(result);

			this.setState({ tableData: { ...this.state.tableData, toBeAssigned: tableData } });

		} catch (error) {
			errorHelper.handleError(error);
		}
	};

	_filterCallbackAwaitingRewrite = async (queryString = "") => {

		try {
			const result = await prescriptionService.filterAwaitingRewrite(queryString);
			const tableData = this._createTableDataForAwaitingRewrite(result);

			this.setState({ tableData: { ...this.state.tableData, awaitingRewrite: tableData } });

		} catch (error) {
			errorHelper.handleError(error);
		}
	};

	_filterCallbackFutureScripts = async (queryString = "") => {

		try {
			const result = await prescriptionService.filterFutureDatedScripts(queryString);
			const tableData = this._createTableDateForFutureDatedScripts(result);

			this.setState({ tableData: { ...this.state.tableData, futureDatedScripts: tableData } });

		} catch (error) {
			errorHelper.handleError(error);
		}
	};

	setFilterTimeoutAssignPrescription = async (queryString) => {
		const throttle = this.throttles.toBeAssigned;

		if (throttle) throttle.setTimeout(() => this._filterCallbackAssignPrescription(queryString), 300);
	};

	setFilterTimeoutAwaitingRewrite = async (queryString) => {
		const throttle = this.throttles.toBeAssigned;

		if (throttle) throttle.setTimeout(() => this._filterCallbackAwaitingRewrite(queryString), 300);
	};

	setFilterTimeOutForFutureDatedScripts = async (queryString) => {
		const throttle = this.throttles.futureDatedScripts;

		if (throttle) throttle.setTimeout(() => this._filterCallbackFutureScripts(queryString), 300);
	};

	getAllPatientsAwaitingRewritePrescriptions = async (patientId) => {
		try {
			this.state.loadingAllpatientsAwaitingRewritePrescriptions = true;

			const prescriptionsAwaitingRewrite = await prescriptionService.getAllAwaitingRewriteByPatientId(patientId);

			const firstPres = prescriptionsAwaitingRewrite[0];

			const rewritePrescriptionIdForLinking = {
				rewritePrescriptionIdForLinking: { value: firstPres ? firstPres.id : "" }
			}

			this.setState({ assignPrescription: Object.assign(this.state.assignPrescription, rewritePrescriptionIdForLinking) })

			const arrayOfPrescriptionIds = prescriptionsAwaitingRewrite.map(p => p.id);

			this.setState({ prescriptionsAwaitingRewrite: arrayOfPrescriptionIds })

		} catch (error) {
			errorHelper.handleError(error);
		}
		this.state.loadingAllpatientsAwaitingRewritePrescriptions = false;
	}

	linkForRewrite = async (payload) => {
		this.setState({ linkingForRewrite: true });

		try {
			await prescriptionService.linkForRewrite(payload);

			this.setState({
				assignPrescription: {
					...this.state.assignPrescription,
					prescription: {
						value:
						{
							...this.state.assignPrescription.prescription.value,
							rewriteChildPrescriptionId: payload.rewritePrescriptionId
						}
					}
				}
			}
			)

			notificationHelper.info(successfullLinkAsRewriteMesage())

		} catch (error) {
			errorHelper.handleError(error);
		}

		this.setState({ linkingForRewrite: false });
	}
	//end reegion assign prescription

	unlinkPrescription = async () => {
		this.setState({ unlinkingPrescription: true });
		try {
			const payload = {
				prescriptionId: this.state.unlinkPrescription.prescriptionId.value,
				rewritePrescriptionId: this.state.unlinkPrescription.rewritePrescriptionId.value,
				reason: this.state.unlinkPrescription.reason.value,
			}
			await prescriptionService.unlinkPrescription(payload);
			await this.setEventHistory(this.state.unlinkPrescription.prescriptionId.value);

			if (this.state.updatePrescription.rewriteParentPrescriptionId.value === this.state.unlinkPrescription.rewritePrescriptionId.value) {
				this.setState({
					updatePrescription: {
						...this.state.updatePrescription, rewriteParentPrescriptionId: { value: "" }

					}
				}
				)
			} else {
				this.setState({
					assignPrescription: {
						...this.state.assignPrescription,
						prescription: {
							value:
							{
								...this.state.assignPrescription.prescription.value,
								rewriteChildPrescriptionId: null
							}
						}
					},
					updatePrescription: {
						...this.state.updatePrescription, rewriteChildPrescriptionId: { value: "" }

					}
				}
				)
			}

			notificationHelper.info("Prescriptions unlinked");

		} catch (error) {
			errorHelper.handleError(error);
		}
		this.setState({ unlinkingPrescription: false });
	}

	setForUnlinking = (payload) => {
		const newUnlinkState = {
			prescriptionId: { value: payload.prescriptionId },
			rewritePrescriptionId: { value: payload.rewritePrescriptionId },
			reason: { value: "" },
		}

		this.setState({ unlinkPrescription: newUnlinkState })
	}

	getAllAdminsPartial = async () => {
		const partialAdmins = await prescriptionService.getAllAdminsPartial();

		this.setState({ partialAdmins })
	}

	assignAnOwner = async (prescriptionId, admin) => {
		try {
			await prescriptionService.assignOwner(prescriptionId, admin.value);

			if (admin.value) {
				notificationHelper.info(`${admin.label} allocated as owner of Prescription ID ${prescriptionId}`)
			} else {
				notificationHelper.info(`No owner allocated for Prescription ID ${prescriptionId}`)
			}

		} catch (error) {
			errorHelper.handleError(error);
		}
	}
}

export default PrescriptionContainer;

const successfullUploadedPrescriptionMessage = () => {
	return <div>
		Prescription uploaded and moved to&nbsp;
		<span style={{ textDecoration: 'underline', color: 'blue' }} onClick={() => { browserHelper.navigate('/prescriptions/toBeAssigned') }}>
			To be assigned
		</span>
	</div>
}

const successfullSendToOrganisationMessageAssignPrescription = () => {
	return <div>
		Prescription sent to organisation for re-write and is now assigned to patient and in&nbsp;
		<span style={{ textDecoration: 'underline', color: 'blue' }} onClick={() => { browserHelper.navigate('/prescriptions/new') }}>
			to be processed
		</span>
	</div>
}

const successfullSendToOrganisationMessageAssignFutureDatedPrescription = () => {
	return <div>
		Prescription sent to organisation for re-write and is now assigned to patient and in&nbsp;
		<span style={{ textDecoration: 'underline', color: 'blue' }} onClick={() => { browserHelper.navigate('/prescriptions/toBeAssigned') }}>
			Future dated scripts
		</span>
	</div>
}

const successfullSendToOrganisationMessageEditPrescription = () => {
	return <div>
		Prescription sent to organisation for re-write
	</div>
}

const successfullAssignedMessage = (text) => {
	return <div>
		Prescription assigned to
		<span style={{ fontWeight: 'bold' }}>
			&nbsp;{text}
		</span>
		&nbsp;
		and
		&nbsp;
		<span style={{ textDecoration: 'underline', color: 'blue' }} onClick={() => { browserHelper.navigate('/prescriptions/all') }}>
			ready to be processed
		</span>
	</div>
}

const successfullLinkAsRewriteMesage = () => {
	return <div>
		Re-write status assigned to prescription
	</div>
}

const renderFlagForRewrite = (prescription) => {
	return <div style={{ display: "flex" }}>
		<div style={{ marginRight: "3px", wordBreak: "initial" }} >
			{prescription.id}
		</div>
		{prescription.isUrgent &&
			<div style={{ marginRight: "3px" }}>
				<CustomIconWithTooltip toolTipText={'Urgent prescription'} icon={<UrgentIconSmall />} />
			</div>}
		{prescription.awaitingRewrite &&
			<div style={{ marginRight: "3px" }}>
				<CustomIconWithTooltip toolTipText={"Re-write required"} icon={<Flag />} />
			</div>}
		{prescription.rewriteChildPrescriptionId &&
			<div style={{ marginRight: "3px" }}>
				<CustomIconWithTooltip toolTipText={'Re-write'} icon={<RWIcon />} />
			</div>}
		{prescription.isFlagged &&
			<div >
				<UniversalTooltip
					hoverableContent={<Flag squareColor={"#FFF5E6"} flagColor={"#F59404"} />}
					left={150}
					contentForTheTooltip={<FlaggedPrescriptionTooltipContent prescription={prescription} />}
				/>
			</div>}
	</div>
}

const renderFlagForPatient = (prescription) => {
	return <div style={{ display: "flex", alignItems: "center" }}>
		<div style={{ marginRight: "8px", wordBreak: "initial" }} >
			{`${prescription.patient.firstName} ${prescription.patient.lastName}`}
		</div>
		{prescription.patient.noteAdmin &&
			<div >
				<UniversalTooltip
					hoverableContent={<NoteIcon squareColor={"#FFF5E6"} flagColor={"#F59404"} />}
					left={150}
					contentForTheTooltip={<FlaggedPatientTooltipContent patient={prescription.patient} />}
				/>
			</div>}
	</div>
}

const renderPaperCopyIcon = (prescription) => {
	return <div style={{ display: "flex", alignItems: "center" }}>
		<div>{prescription.number || "N/A"}</div>
		{prescription.isPaperPrescriptionReceived &&
			<CustomIconWithTooltip
				style={{ margin: '5px', fontSize: "14px", maxWidth: '300px' }}
				toolTipText={"Paper copy received"}
				icon={<PaperCopyIcon size="13" color="#719C80" />}
			/>
		}
		{prescription.isPaperPrescriptionReceived === false &&
			<CustomIconWithTooltip
				style={{ margin: '5px', fontSize: "14px", maxWidth: '300px' }}
				toolTipText={"Paper copy not received"}
				icon={<PaperCopyIcon size="13" color="#CCCCCC" />}
			/>
		}
	</div>
}

function renderPrescriptionOwnerDropdown(prescription, onChange, options = []) {
	const value = {
		value: prescription.adminOwner?.id ? prescription.adminOwner?.id : "",
		label: `${prescription.adminOwner?.name || "None"}`,
	}


	return <OverflowSelect
		value={value}
		options={options}
		name=""
		onChange={(e) => {
			onChange(prescription.id, e.value[0]);
			const index = options.findIndex((o) => {
				return o.value === e.value[0].value
			})

			if (e.value[0].value) {
				options.splice(index, 1)
				options.splice(1, 0, e.value[0])
			}

		}}
		prescription={prescription}
	/>

}

function formatText(val) {
	if (val.startsWith("{{")) {
		//remove the {{ }} brackets
		let substring = val.replace("{{", "").replace("}}", "")
		let pivot = substring.indexOf(":") + 1
		let adminName = substring.slice(0, pivot)
		substring = substring.slice(pivot)

		return <div>
			<b>{adminName}</b>
			{substring}
		</div>
	}
	return val;
}

