import {
	ProductTypes,
	ProductSubtypes,
	PatientRelationship,
	PrescriptionPhases,
	HighLevelRejectReasons,
	DoctorErrorRejectReasons,
	AdminErrorRejectReasons,
	PrescriberErrorRejectReasons,
	PrescriberProductErrorRejectReasons,
	ProductAvailability,
	ProductPhEur,
	SubscriptionTypes,
	ProducMediaAccessLevel
} from "../../enums";

class ContentHelper {
	resolveProductType(type) {
		return {
			[ProductTypes.Oil]: "Oil",
			[ProductTypes.Flower]: "Flower",
			[ProductTypes.Cartridge]: "Cartridge",
		}[type];
	}

	resolveProductAvailabilityStatus(productAvailabilityStatus) {
		return {
			[ProductAvailability.Available]: "Available",
			[ProductAvailability.Unavailable]: "Unavailable",
			[ProductAvailability.Discontinued]: "Discontinued",
			[ProductAvailability.AvailableToOrder]: "Available to order",
		}[productAvailabilityStatus];
	}

	getProductTypesAsDropdownItems() {
		return [
			{ text: "Oil", value: ProductTypes.Oil },
			{ text: "Flower", value: ProductTypes.Flower },
			{ text: "Cartridge", value: ProductTypes.Cartridge },
		];
	}

	getProductSubtypesAsDropdownItems() {
		return [
			{ text: "None", value: ProductSubtypes.None },
			{ text: "Indica", value: ProductSubtypes.Indica },
			{ text: "Sativa", value: ProductSubtypes.Sativa },
			{ text: "Hybrid", value: ProductSubtypes.Hybrid },
		];
	}

	getProductPhEurAsDropdownItems() {
		return [
			{ text: "None", value: ProductPhEur.None },
			{ text: "5.1.4", value: ProductPhEur["5.1.4"] },
			{ text: "5.1.8b", value: ProductPhEur["5.1.8b"] },
		];
	}

	getHighLevelRejectReasonsAsDropDownItems() {
		return [
			{ label: "Product not in stock", value: HighLevelRejectReasons.ProductNotInStock },
			{ label: "Prescriber product error", value: HighLevelRejectReasons.PrescriberProductError },
			{ label: "Prescription expired / close to expiry", value: HighLevelRejectReasons.PrescriptionExpired },
			{ label: "Prescriber error", value: HighLevelRejectReasons.PrescriberError },
			{ label: "Discontinued product", value: HighLevelRejectReasons.DiscontinuedProduct },
		];
	}

	getHighLevelArchiveReasonsAsDropDownItems() {
		return [
			{ label: "Doctor error", value: HighLevelRejectReasons.DoctorError },
			{ label: "Admin error", value: HighLevelRejectReasons.AdminError },
			{ label: "Other", value: HighLevelRejectReasons.Other },
		];
	}

	getAdminErrorArchiveReasons() {
		return [
			{ label: "This is a duplicate", value: AdminErrorRejectReasons.ThisIsADupicate },
			{ label: "Image uploaded by mistake", value: AdminErrorRejectReasons.ImageUploadedByMistake },
		];
	}

	getDoctorErrorArchiveReasons() {
		return [
			{ label: "Doctor has sent a duplicate", value: DoctorErrorRejectReasons.DoctorSentADuplicate },
			{ label: "Jersey/Guernsey patient", value: DoctorErrorRejectReasons.JerseyGuernsey },
		];
	}

	getPrescriberErrorRejectReasons() {
		return [
			{ label: "Signature and/or date prescribed missing", value: PrescriberErrorRejectReasons.SignitureOrDateMissing },
			{ label: "The contents are unreadable", value: PrescriberErrorRejectReasons.ContentsUnreadable },
			{ label: "Patient’s date of birth missing", value: PrescriberErrorRejectReasons.DobMissing },
			{ label: "Patient’s full name missing", value: PrescriberErrorRejectReasons.FullNameMissing },
			{ label: "Patient’s address missing", value: PrescriberErrorRejectReasons.AddressMissing },
		];
	}

	getPrescriberProductErrorRejectReasons() {
		return [
			{ label: "Product name missing, incomplete or unreadable", value: PrescriberProductErrorRejectReasons.ProductNameUnreadable },
			{ label: "Product dosage missing, incomplete  or unreadable", value: PrescriberProductErrorRejectReasons.DosageMissing },
			{ label: "Product strength missing, incomplete  or unreadable", value: PrescriberProductErrorRejectReasons.StrengthMissing },
			{ label: "Product frequency missing, incomplete or unreadable", value: PrescriberProductErrorRejectReasons.FrequencyMissing },
			{ label: "Product quantity missing, incomplete or unreadable", value: PrescriberProductErrorRejectReasons.QuantityMissing },
		];
	}

	getDoctorProductErrorRejectReasons() {
		return [
			{ label: "This is a duplicate", value: AdminErrorRejectReasons.thisIsADupicate },
			{ label: "Image uploaded by mistake", value: AdminErrorRejectReasons.ImageUploadedByMistake },
		];
	}

	getTitlesAsDropdownItems() {
		return [
			{ text: "Ms", value: "Ms" },
			{ text: "Mr", value: "Mr" },
			{ text: "Miss", value: "Miss" },
			{ text: "Mrs", value: "Mrs" },
			{ text: "Mx", value: "Mx" },
			{ text: "Master", value: "Master" },
		];
	}

	getAccountTypeItems(isDoctorPresent, isCarerSet) {
		const result = [{ text: "Select one", value: null }];

		if (isDoctorPresent) result.push({ text: "Doctor", value: 2 });

		if (isCarerSet) result.push({ text: "Carer", value: 1 });
		else result.push({ text: "Patient", value: 0 });

		return result;
	}

	getYesNoDropdownItems() {
		return [
			{ text: "Yes", value: true },
			{ text: "No", value: false },
		];
	}

	getPatientRelationshipAsDropdownItems() {
		return [
			{ text: "Parent", value: PatientRelationship.Parent },
			{ text: "Guardian", value: PatientRelationship.Guardian },
		];
	}

	normalizePrescriptionPhase(phase) {
		phase = phase.toLowerCase();

		if (!phase) return "all";

		if (PrescriptionPhases[phase] === undefined) return "all";

		return phase;
	}

	getPrescriptionProgressBarSteps() {
		return [
			{
				label: "Processed",
				name: "processed",
			},
			{
				label: "Awaiting stock",
				name: "awaitingStock",
			},
			{
				label: "Payment",
				name: "payment",
			},
			{
				label: "Pharmacy",
				name: "pharmacy",
			},
			{
				label: "In Transit",
				name: "shipped",
			},
		];
	}

	getPrescriptionTableHeadingByPhase(phase) {
		return (
			{
				active: "Active prescriptions",
				new: "Ready to be processed",
				outOfStock: "Awaiting Stock",
				readyForPayment: "Ready for payment",
				requirepapercopy: "Require paper copy",
				readyForPharmacy: "Ready for pharmacy",
				readyForPatient: "Ready for patient",
				shipped: "Prescriptions in transit",
				past: "Past prescriptions",
				completed: "Completed prescriptions",
				void: "Void prescriptions",
				archived: "Archived prescriptions",
			}[phase] || "Prescriptions"
		);
	}

	getPluralText(text, number, lowerCase = false) {
		const s = lowerCase ? "s" : "S";
		if (number !== 1) return `${text}${s}`;

		return `${text}`;
	}

	getSubscriptionTypesAsDropdowns() {
		return [
			{ text: "Pay as you go", value: SubscriptionTypes.None },
			{ text: "All patients", value: SubscriptionTypes.AllPatient },
			{ text: "Access Scheme Patients", value: SubscriptionTypes.AccessSchemePatient },
		];
	}

	getSubscriptionTiersAsDropdowns() {
		return [
			{ text: "Tier 1", value: 1 },
			{ text: "Tier 2", value: 2 },
		];
	}

	getMediaAccessLevelsAsDropdowns() {
		return [
			{ text: "Admins only", value: ProducMediaAccessLevel.AdminOnly },
			{ text: "Patient only", value: ProducMediaAccessLevel.PatientOnly },
			{ text: "Doctor only", value: ProducMediaAccessLevel.DoctorOnly },
			{ text: "Everyone", value: ProducMediaAccessLevel.PatientAndDoctor },
		];
	}
}

export default new ContentHelper();
