/*eslint-disable eqeqeq*/
import React from "react";
import { Container } from "unstated";
import notificationHelper from "../util/helpers/notificationHelper";

import productService from "../services/ProductsService";
import prescriptionService from "../services/PrescriptionsService";

import contentHelper from "../util/helpers/contentHelper";
import browserHelper from "../util/helpers/browserHelper";
import errorHelper from "../util/helpers/errorHelper";
import { Throttle } from "../util/Throttle";
import { required, maxLength, isFormValid, minValue, isPositive, isEnum, customRegex } from "../util/validation/validation";
import tableHeaders from "../util/constants/tableHeaders";
import { OrderStatusesToOrderHeaderMapper, ProducMediaAccessLevel, productAvailabilityStatusMapper, ProductUnit } from "../enums";
import { containerSort, resetHeaderData, sortPassedData } from "../util/helpers/sorting";
import { formatDate, formatDateForPresenting } from "../util/dates";
import { getOrderStatus, getProductWithStatus } from "../converters/prescriptionsConverter";
import axios from "axios";
import UniversalTooltip from "../components/Tooltips/UniversalTooltip";
import { FlaggedProductIcon } from "../assets/icons";
import ProductPrescriberNoteTooltipContent
	from "../components/Tooltips/ContentForTooltips/ProductPrescriberNoteTooltipContent";
import MetricsService from "../services/MetricsService";
import { sanitizeString } from "../util/sanitizeString";

const newProductInitialState = {
	sku: { value: "" },
	name: { value: "" },
	type: { value: 0 },
	subtype: { value: 0 },
	phEur: { value: 0 },
	description: { value: "" },
	thcLessThen: { value: false },
	thc: { value: "" },
	cbdLessThen: { value: false },
	cbd: { value: "" },
	activeIngredient: { value: "" },
	manufacturer: { value: "" },
	country: { value: "" },
	weight: { value: "" },
	containerWeight: { value: "" },
	isPotContainer: { value: false },
	productUnit: { value: 0 },
	retailPrice: { value: null },
	wholesalePrice: { value: null },
	allPatientsT1Price: { value: 0 },
	allPatientsT2Price: { value: 0 },
	accessSchemeT1Price: { value: 0 },
	accessSchemeT2Price: { value: 0 },
	irradiatedType: { value: 0 },

	isT21Eligible: { value: false },
	isMixAndMatchEligible: { value: false },
	isSubsidyEligible: { value: false },

	t21BasePrice: { value: 0 },
	t21FirstQuantityCap: { value: 0 },
	t21FirstQuantityCapPrice: { value: 0 },
	t21SecondQuantityCap: { value: 0 },
	t21SecondQuantityCapPrice: { value: 0 },
	t21AdditionalAmountPrice: { value: 0 },
	t21SubsidyPrice: { value: 0 },
	productTerpeneProfileType: { value: 0 },
	availability: { value: 0 },
	supplier: { value: -1 },
	expiryDate: { value: "" },

	terpeneIds: { value: [{ label: "", value: 0, percentage: { value: 0.0 }, majorMinor: { value: { label: "", value: 0 } } }] }
};

const updateProductInitialState = {
	id: { value: "" },
	sku: { value: "" },
	name: { value: "" },
	type: { value: "" },
	subtype: { value: "" },
	phEur: { value: "" },
	description: { value: "" },
	thcLessThen: { value: false },
	thc: { value: "" },
	cbdLessThen: { value: false },
	cbd: { value: "" },
	activeIngredient: { value: "" },
	manufacturer: { value: "" },
	country: { value: "" },
	weight: { value: "" },
	containerWeight: { value: "" },
	isPotContainer: { value: false },
	productUnit: { value: 0 },
	retailPrice: { value: null },
	wholesalePrice: { value: null },
	allPatientsT1Price: { value: 0 },
	allPatientsT2Price: { value: 0 },
	accessSchemeT1Price: { value: 0 },
	accessSchemeT2Price: { value: 0 },
	irradiatedType: { value: 0 },

	isT21Eligible: { value: false },
	isMixAndMatchEligible: { value: false },
	isSubsidyEligible: { value: false },

	t21BasePrice: { value: 0 },
	t21FirstQuantityCap: { value: 0 },
	t21FirstQuantityCapPrice: { value: 0 },
	t21SecondQuantityCap: { value: 0 },
	t21SecondQuantityCapPrice: { value: 0 },
	t21AdditionalAmountPrice: { value: 0 },
	t21SubsidyPrice: { value: 0 },

	availability: { value: 0 },
	supplier: { value: -1 },

	images: [{
		id: "",
		mediaUrl: ""
	}],

	documents: [{
		id: "",
		mediaUrl: ""
	}],
	expiryDate: { value: "" },
	prescriberNote: { value: "" },
	prescriberNoteAdmin: { value: {} },

	productTerpeneProfileType: { value: 0 },
	terpeneIds: { value: [{ label: "", value: 0, percentage: { value: 0.0 }, majorMinor: { value: { label: "", value: 0 } } }] }

};

const initialCreateCommentState = {
	productId: { value: -1 },
	text: { value: "" },
	adminId: { value: "" }
};

class ProductContainer extends Container {
	//#region State
	constructor() {
		super();

		this.state = {
			tableHeaders: tableHeaders.products,
			tableData: [],
			newProduct: { ...newProductInitialState },

			updateProduct: { ...updateProductInitialState },
			terpenes: [],
			loadingTerpenes: false,
			// loading flags
			creatingProduct: false,
			updatingProduct: false,
			loadingAllProducts: false,
			loadingSuppliers: false,

			ordersAwaitingStock: {
				value: []
			},

			productsActiveOrders: {
				value: []
			},

			comments: {
				tableData: [],
				value: [],
			},

			createComment: { ...initialCreateCommentState },
		};

		this.validators = {
			newProduct: {
				sku: [required, maxLength(8), customRegex("[a-zA-Z]{5}[0-9]{3}")],
				name: [required, maxLength(255)],
				description: [required],
				thcLessThen: [required],
				thc: [required, minValue(0)],
				cbdLessThen: [required],
				cbd: [required, minValue(0)],
				activeIngredient: [required, maxLength(64)],
				weight: [required, isPositive],
				containerWeight: [required, isPositive],
				manufacturer: [maxLength(128)],
				country: [maxLength(64)],
				productUnit: [isEnum(ProductUnit)],
				retailPrice: [required, isPositive],
				wholesalePrice: [required, isPositive],
				allPatientsT1Price: [isPositive],
				allPatientsT2Price: [isPositive],
				accessSchemeT1Price: [isPositive],
				accessSchemeT2Price: [isPositive],
				isT21Eligible: [required],
			},
			updateProduct: {
				sku: [required, maxLength(8), customRegex("[a-zA-Z]{5}[0-9]{3}")],
				name: [required, maxLength(255)],
				description: [required],
				thcLessThen: [required],
				thc: [required, minValue(0)],
				cbdLessThen: [required],
				cbd: [required, minValue(0)],
				activeIngredient: [required, maxLength(64)],
				weight: [required, isPositive],
				containerWeight: [required, isPositive],
				manufacturer: [maxLength(128)],
				country: [maxLength(64)],
				productUnit: [isEnum(ProductUnit)],
				retailPrice: [required, isPositive],
				wholesalePrice: [required, isPositive],
				allPatientsT1Price: [isPositive],
				allPatientsT2Price: [isPositive],
				accessSchemeT1Price: [isPositive],
				accessSchemeT2Price: [isPositive],
				isT21Eligible: [required],
			},
			createComment: {
				text: [required, maxLength(255)]
			},
		};

		this.throttle = new Throttle();
	}

	resetNewState = () => {
		this.setState({
			newProduct: newProductInitialState,
		});
	};

	resetUpdateState = () => {
		this.setState({
			updateProduct: updateProductInitialState,
		});
	};

	resetOrdersAwaitingStock = () => {
		this.setState({
			ordersAwaitingStock: { value: [] }
		});
	}

	//#endregion

	//#region Util functions for inputs

	onFormChange = (e, formKey) => {
		const validators = this.validators[formKey][e.name] || [];
		if (e.value === "true" || e.value === "false") {
			e.value = JSON.parse(e.value);
		}

		const newField = { value: e.value, error: null };

		for (let i in validators) {
			const result = validators[i](newField.value);

			if (!result.valid) {
				newField.error = result.message;

				break;
			}
		}

		this.setState({ [formKey]: Object.assign(this.state[formKey], { [e.name]: newField }) });
	};

	onSelectChange = (e, formKey) => {
		if (e.value[0] === "true" || e.value[0] === "false") {
			e.value[0] = JSON.parse(e.value[0]);
		}

		const newField = { value: e.value[0], error: null };

		this.setState({ [formKey]: Object.assign(this.state[formKey], { [e.name]: newField }) });
	};

	isFormValid = (formKey) => {
		return isFormValid(this.state[formKey], this.validators[formKey]);
	};

	//#endregion

	//#region Product table data

	sort = containerSort.bind(this);

	sortActiveProducts = sortPassedData.bind(this)

	handleColumnSort = (columnIndex) => {
		let tableHeaders = this.state.tableHeaders;

		resetHeaderData(tableHeaders, columnIndex);

		tableHeaders[columnIndex].ascending = !tableHeaders[columnIndex].ascending;

		this.setState({ tableHeaders: tableHeaders })
	}

	filter = async (queryString, page = 1, pageSize = 25, sortField = "", sortDirection = 0) => {
		const cancelToken = axios.CancelToken.source();

		if (this.state.cancelToken) {
			this.state.cancelToken.cancel("Cancelling this request");
		}

		await this.throttle.setTimeout(() => this._filterCallback(queryString, page, pageSize, sortField, sortDirection, cancelToken), 1000);

		this.setState({ cancelToken: cancelToken })
	};

	_filterCallback = async (queryString, page, pageSize, sortField, sortDirection, cancelToken) => {
		this.setState({ loadingAllProducts: true });
		try {
			const result = await productService.filter(queryString, page, pageSize, sortField, sortDirection, cancelToken);
			const { items, ...paginationData } = result;
			this.setState({ paginationData: paginationData });

			var products = result.items ? items : result;

			const newTableData = products.map((product) => {
				const productWithStatus = getProductWithStatus(product, product.stockRequired)
				//Watching stock required and amount in stock

				const productAvailability = contentHelper.resolveProductAvailabilityStatus(product.availability)
				const productTagColor = () => {
					if (productAvailability === "Available") return "green"
					if (productAvailability === "Unavailable") return "red"
					if (productAvailability === "Discontinued") return "gray"
					if (productAvailability === "Available to order") return "light-orange"
				}

				return [
					{
						text: this._renderFlagForPrescriberNote(product),
						column: 2.5,
						href: `/products/edit/${product.id}`,
					},
					{
						text: product.sku,
						column: 1.5,
						href: `/products/edit/${product.id}`,
					},
					{
						text: productAvailability,
						column: 1.5,
						color: productTagColor()
					},
					{
						text: productAvailabilityStatusMapper[productWithStatus.status],
						column: 2,
					},
					{
						text: product.stockRequired,
						column: 1.5,
					},
					{
						text: product.stockAllocated,
						column: 1.5,
					},
					{
						text: product.stockQuantity,
						inlineActions: {
							id: product.id, data: {
								name: product.name,
								stockRequired: product.stockRequired,
								stockAllocated: product.stockAllocated,
								stockQuantity: product.stockQuantity
							}
						},
						column: 1.5,
						center: true
					},
				];
			});

			this.setState({ tableData: newTableData });
		} catch (error) {
			if (error.message !== "Cancelling this request") {
				errorHelper.handleError(error);
			}
		}
		this.setState({ loadingAllProducts: false });
	};

	_renderFlagForPrescriberNote = (product) => {
		return <div style={{ display: "flex", alignItems: "center" }}>
			<div style={{ display: "contents", wordBreak: "initial", width: "min-content" }} >
				{product.name}
			</div>
			{product.prescriberNote &&
				<div style={{ marginLeft: "8px" }}>
					<UniversalTooltip
						hoverableContent={<FlaggedProductIcon />}
						left={150}
						contentForTheTooltip={<ProductPrescriberNoteTooltipContent product={product} />}
					/>
				</div>}
		</div>
	}

	_getTerpenes = async () => {
		this.setState({ loadingTerpenes: true });

		const result = await productService.getAllTerpenes();

		const mappedTerpenes = result.map((terpene) => {
			return {
				label: `${terpene?.name}`,
				value: terpene?.id,
			};
		});


		this.setState({ terpenes: mappedTerpenes, loadingTerpenes: false });
	};

	onTerpeneSelectChange = (e, index, isEdit) => {
		let terpeneIds = isEdit ? this.state.updateProduct.terpeneIds.value : this.state.newProduct.terpeneIds.value;
		if (e.value.length > 0) {

			terpeneIds[index] = { ...terpeneIds[index], ...e.value[0] };

			isEdit ?
				this.setState({ updateProduct: { ...this.state.updateProduct, terpeneIds: { value: terpeneIds } } })
				:
				this.setState({ newProduct: { ...this.state.newProduct, terpeneIds: { value: terpeneIds } } });

		}
	};

	onTerpinePercentageChange = (e, index, isEdit) => {

		let terpeneIds = isEdit ? this.state.updateProduct.terpeneIds.value : this.state.newProduct.terpeneIds.value;
		if (e.value) {
			terpeneIds[index] = { ...terpeneIds[index], percentage: { value: e.value } };

			isEdit ?
				this.setState({ updateProduct: { ...this.state.updateProduct, terpeneIds: { value: terpeneIds } } })
				:
				this.setState({ newProduct: { ...this.state.newProduct, terpeneIds: { value: terpeneIds } } });
		}
	};

	onTerpineMajorMinorChange = (e, index, isEdit) => {
		let terpeneIds = isEdit ? this.state.updateProduct.terpeneIds.value : this.state.newProduct.terpeneIds.value;
		if (e.value) {
			terpeneIds[index] = { ...terpeneIds[index], majorMinor: { value: e.value[0] } };

			isEdit ?
				this.setState({ updateProduct: { ...this.state.updateProduct, terpeneIds: { value: terpeneIds } } })
				:
				this.setState({ newProduct: { ...this.state.newProduct, terpeneIds: { value: terpeneIds } } });
		}
	};

	setProductTerpeneProfileType = (type, isEdit) => {
		isEdit ?
			this.setState({ updateProduct: { ...this.state.updateProduct, productTerpeneProfileType: { value: type } } })
			:
			this.setState({ newProduct: { ...this.state.newProduct, productTerpeneProfileType: { value: type } } });
	}

	addTerpene = (isEdit) => {
		const newTerpine = { label: "", value: 0, percentage: { value: 0.0 }, majorMinor: { value: { text: "", value: 0 } } };
		const terpenes = isEdit ? this.state.updateProduct.terpeneIds.value : this.state.newProduct.terpeneIds.value
		if (terpenes && terpenes.length < 15) {
			isEdit ?
				this.setState({ updateProduct: { ...this.state.updateProduct, terpeneIds: { value: [...this.state.updateProduct.terpeneIds.value, newTerpine] } } })
				:
				this.setState({ newProduct: { ...this.state.newProduct, terpeneIds: { value: [...this.state.newProduct.terpeneIds.value, newTerpine] } } })
		}

	}

	onRemoveTerpene = (index, isEdit) => {
		let terpeneIds = isEdit ? this.state.updateProduct.terpeneIds.value : this.state.newProduct.terpeneIds.value;

		isEdit ?
			this.setState({ updateProduct: { ...this.state.updateProduct, terpeneIds: { value: terpeneIds.filter((e, idIndex) => idIndex !== index) } } })
			:
			this.setState({ newProduct: { ...this.state.newProduct, terpeneIds: { value: terpeneIds.filter((e, idIndex) => idIndex !== index) } } });
	};

	getAllProducts = async () => {
		this.setState({ loadingAllProducts: true });

		await this._filterCallback("", 1, 9999999, "", 0);

		this.setState({ loadingAllProducts: false });
	}

	getPaginatedProducts = async (page = 1, pageSize = 25) => {
		this.setState({ loadingAllProducts: true });

		await this._filterCallback("", page, pageSize, "", 0);

		this.setState({ loadingAllProducts: false });
	}

	getAllSuppliers = async (formKey) => {
		return await this._getAllSuppliersCallback(formKey);
	}

	_getAllSuppliersCallback = async (formKey) => {
		this.setState({ loadingSuppliers: true });
		const result = await productService.getAllSuppliers();

		const suppliers = result.map((supplier) => {
			return {
				text: `${supplier.name}`,
				value: supplier.id,
			};
		});

		this.setState({ suppliers });
		if (formKey === 'newProduct')
			this.setState({ [formKey]: Object.assign(this.state[formKey], { ['supplier']: { value: suppliers[0].value } }) });
		this.setState({ loadingSuppliers: false });
		return suppliers;
	}

	//#region CRUD Product
	mapProductTerpines = (terpenes, type) => {
		const mappedTerpenes = terpenes.value.map((terpene) => {
			return {
				productId: 0,
				terpeneId: terpene.value,
				type: type.value,
				value: type.value === 0 ? terpene.percentage.value : terpene.majorMinor.value.value
			}
		});
		return mappedTerpenes;
	}

	create = async () => {
		this.setState({ creatingProduct: true });
		const thc = this.state.newProduct.thcLessThen.value ? -1 * this.state.newProduct.thc.value : this.state.newProduct.thc.value;
		const cbd = this.state.newProduct.cbdLessThen.value ? -1 * this.state.newProduct.cbd.value : this.state.newProduct.cbd.value;
		try {
			const payload = {
				sku: this.state.newProduct.sku.value,
				name: this.state.newProduct.name.value,
				type: this.state.newProduct.type.value,
				subtype: this.state.newProduct.subtype.value,
				phEur: this.state.newProduct.phEur.value,
				description: sanitizeString(this.state.newProduct.description.value),
				thc: thc,
				cbd: cbd,
				activeIngredient: this.state.newProduct.activeIngredient.value,
				weight: this.state.newProduct.weight.value,
				containerWeight: this.state.newProduct.containerWeight.value,
				isPotContainer: this.state.newProduct.isPotContainer.value,
				manufacturer: this.state.newProduct.manufacturer.value,
				country: this.state.newProduct.country.value,
				productUnit: this.state.newProduct.productUnit.value,
				retailPrice: this.state.newProduct.retailPrice.value,
				wholesalePrice: this.state.newProduct.wholesalePrice.value,
				allPatientsT1Price: this.state.newProduct.allPatientsT1Price.value,
				allPatientsT2Price: this.state.newProduct.allPatientsT2Price.value,
				accessSchemeT1Price: this.state.newProduct.accessSchemeT1Price.value,
				accessSchemeT2Price: this.state.newProduct.accessSchemeT2Price.value,
				irradiatedType: this.state.newProduct.irradiatedType.value,

				isT21Eligible: this.state.newProduct.isT21Eligible.value,
				isMixAndMatchEligible: this.state.newProduct.isMixAndMatchEligible.value,
				isSubsidyEligible: this.state.newProduct.isSubsidyEligible.value,

				t21BasePrice: this.state.newProduct.t21BasePrice.value,
				t21FirstQuantityCap: this.state.newProduct.t21FirstQuantityCap.value,
				t21FirstQuantityCapPrice: this.state.newProduct.t21FirstQuantityCapPrice.value,
				t21SecondQuantityCap: this.state.newProduct.t21SecondQuantityCap.value,
				t21SecondQuantityCapPrice: this.state.newProduct.t21SecondQuantityCapPrice.value,
				t21AdditionalAmountPrice: this.state.newProduct.t21AdditionalAmountPrice.value,
				t21SubsidyPrice: this.state.newProduct.t21SubsidyPrice.value,

				availability: +this.state.newProduct.availability.value,
				supplierId: +this.state.newProduct.supplier.value,
				expiryDate: this.state.newProduct.expiryDate.value,
				productTerpenes: this.mapProductTerpines(this.state.newProduct.terpeneIds, this.state.newProduct.productTerpeneProfileType)
			};

			payload.productTerpenes = payload.productTerpenes.filter(terpene => terpene.terpeneId !== 0);

			const product = await productService.create(payload);

			this.state.newProduct.images.forEach(image => {
				this.submitProductPhoto(product.id, image)
			});

			this.state.newProduct.documents.forEach(document => {
				this.submitProductDocument(product.id, document)
			});

			notificationHelper.info("Product created!");

			browserHelper.navigate("/products");
		} catch (error) {
			errorHelper.handleError(error);
		}

		this.setState({ creatingProduct: false, newProduct: newProductInitialState });
	};

	mapTerpenesForUpdate = (terpeneProfiles) => {
		const mappedTerpene = terpeneProfiles.map((terpeneProfile) => {
			return {
				label: this.state.terpenes.find(terpene => terpene.value == terpeneProfile.terpeneId).label,
				value: terpeneProfile.terpeneId,
				percentage: { value: terpeneProfile.type == 0 ? terpeneProfile.value : 0.0 },
				majorMinor: { value: terpeneProfile.type == 1 ? { label: terpeneProfile.value == 0 ? "Minor" : "Major", value: terpeneProfile.value } : { label: "", value: 0 } }
			}
		})
		return mappedTerpene
	}

	setForUpdate = async (id) => {
		this.setState({ updatingProduct: true });

		try {
			const result = await productService.getById(id);
			const imagesForProduct = await productService.getAllProductImagesById(id)
			const documentsForProduct = await productService.getAllProductDocumentsById(id)
			const thcLessThen = result.thc < 0;
			const cbdLessThen = result.cbd < 0;

			const updateProduct = {
				id: { value: result.id },
				sku: { value: result.sku },
				name: { value: result.name },
				type: { value: result.type },
				subtype: { value: result.subtype },
				phEur: { value: result.phEur },
				description: { value: result.description },
				thcLessThen: { value: thcLessThen },
				thc: { value: Math.abs(result.thc) },
				cbdLessThen: { value: cbdLessThen },
				cbd: { value: Math.abs(result.cbd) },
				activeIngredient: { value: result.activeIngredient },
				weight: { value: result.weight },
				containerWeight: { value: result.containerWeight },
				isPotContainer: { value: result.isPotContainer },
				manufacturer: { value: result.manufacturer },
				country: { value: result.country },
				productUnit: { value: result.productUnit },
				retailPrice: { value: result.retailPrice },
				wholesalePrice: { value: result.wholesalePrice },
				allPatientsT1Price: { value: result.allPatientsT1Price },
				allPatientsT2Price: { value: result.allPatientsT2Price },
				accessSchemeT1Price: { value: result.accessSchemeT1Price },
				accessSchemeT2Price: { value: result.accessSchemeT2Price },
				irradiatedType: { value: result.irradiatedType },

				isT21Eligible: { value: result.isT21Eligible || false },
				isMixAndMatchEligible: { value: result.isMixAndMatchEligible || false },
				isSubsidyEligible: { value: result.isSubsidyEligible || false },

				t21BasePrice: { value: result.t21BasePrice },
				t21FirstQuantityCap: { value: result.t21FirstQuantityCap },
				t21FirstQuantityCapPrice: { value: result.t21FirstQuantityCapPrice },
				t21SecondQuantityCap: { value: result.t21SecondQuantityCap },
				t21SecondQuantityCapPrice: { value: result.t21SecondQuantityCapPrice },
				t21AdditionalAmountPrice: { value: result.t21AdditionalAmountPrice },
				t21SubsidyPrice: { value: result.t21SubsidyPrice },

				availability: { value: result.availability },
				supplier: { value: result.supplierId },

				images: imagesForProduct,
				documents: documentsForProduct,
				expiryDate: { value: formatDate(result.expiryDate, false) },
				prescriberNote: { value: result.prescriberNote || "" },
				prescriberNoteAdmin: { value: result.prescriberNoteAdmin },
				terpeneIds: { value: this.mapTerpenesForUpdate(result.productTerpeneProfiles) },
				productTerpeneProfileType: { value: result.productTerpeneProfiles[0]?.type }
			};
			await this.setProductsActiveOrders(result.id);

			this.getAllCommentsByProductId(id);

			this.setState({ updateProduct });
		} catch (error) {
			errorHelper.handleError(error);
		}

		this.setState({ updatingProduct: false });
	};

	setProductsActiveOrders = async (productId) => {
		let result = await productService.getAllOrdersForProduct({ productId })

		result = result.sort((a, b) => {
			return new Date(b.createdAt) - new Date(a.createdAt);
		});

		this.setState({
			productsActiveOrders: {
				value: this.convertActiveOrdersFromApi(result, productId)
			}
		})
	}

	convertActiveOrdersFromApi = (orders, productId) => {
		return orders.map(order => {
			return [
				{
					text: formatDateForPresenting(order.createdAt, false),
					column: 2,
				},
				{
					text: order.patient.firstName + " " + order.patient.lastName,
					column: 3,
					href: `/patients/edit/${order.patient.id}`,
				},
				{
					text: order.id,
					column: 2,
					href: `/prescriptions/edit/${order.prescription.id}`,
				},
				{
					text: OrderStatusesToOrderHeaderMapper[getOrderStatus(order.prescription, order)].text,
					column: 2,
				},
				{
					text: `${order.orderedProducts.find(e => e.product.id == productId)?.amount}`,
					column: 1
				},
				{
					text: "See prescription",
					column: 2,
					href: `/prescriptions/edit/${order.prescription.id}`,
					right: true
				}
			];
		})
	}

	mapProductTerpines = (terpenes, type) => {
		const mappedTerpenes = terpenes.value.map((terpene) => {
			return {
				productId: 0,
				terpeneId: terpene.value,
				type: type.value,
				value: type.value === 0 ? terpene.percentage.value : terpene.majorMinor.value.value
			}
		});
		return mappedTerpenes;
	}

	update = async () => {
		this.setState({ updatingProduct: true });

		const thc = this.state.updateProduct.thcLessThen.value ?
			-1 * this.state.updateProduct.thc.value :
			this.state.updateProduct.thc.value;

		const cbd = this.state.updateProduct.cbdLessThen.value ?
			-1 * this.state.updateProduct.cbd.value :
			this.state.updateProduct.cbd.value;

		try {
			await productService.update({
				id: this.state.updateProduct.id.value,
				sku: this.state.updateProduct.sku.value,
				name: this.state.updateProduct.name.value,
				type: this.state.updateProduct.type.value,
				subtype: this.state.updateProduct.subtype.value,
				phEur: this.state.updateProduct.phEur.value,
				description: sanitizeString(this.state.updateProduct.description.value),
				thc: thc,
				cbd: cbd,
				activeIngredient: this.state.updateProduct.activeIngredient.value,
				weight: this.state.updateProduct.weight.value,
				containerWeight: this.state.updateProduct.containerWeight.value,
				isPotContainer: this.state.updateProduct.isPotContainer.value,
				manufacturer: this.state.updateProduct.manufacturer.value,
				country: this.state.updateProduct.country.value,
				productUnit: this.state.updateProduct.productUnit.value,
				retailPrice: this.state.updateProduct.retailPrice.value,
				wholesalePrice: this.state.updateProduct.wholesalePrice.value,
				allPatientsT1Price: this.state.updateProduct.allPatientsT1Price.value,
				allPatientsT2Price: this.state.updateProduct.allPatientsT2Price.value,
				accessSchemeT1Price: this.state.updateProduct.accessSchemeT1Price.value,
				accessSchemeT2Price: this.state.updateProduct.accessSchemeT2Price.value,
				irradiatedType: this.state.updateProduct.irradiatedType.value,

				isT21Eligible: this.state.updateProduct.isT21Eligible.value,
				isMixAndMatchEligible: this.state.updateProduct.isMixAndMatchEligible.value,
				isSubsidyEligible: this.state.updateProduct.isSubsidyEligible.value,

				t21BasePrice: this.state.updateProduct.t21BasePrice.value,
				t21FirstQuantityCap: this.state.updateProduct.t21FirstQuantityCap.value,
				t21FirstQuantityCapPrice: this.state.updateProduct.t21FirstQuantityCapPrice.value,
				t21SecondQuantityCap: this.state.updateProduct.t21SecondQuantityCap.value,
				t21SecondQuantityCapPrice: this.state.updateProduct.t21SecondQuantityCapPrice.value,
				t21AdditionalAmountPrice: this.state.updateProduct.t21AdditionalAmountPrice.value,
				t21SubsidyPrice: this.state.updateProduct.t21SubsidyPrice.value,

				availability: +this.state.updateProduct.availability.value,
				supplierId: +this.state.updateProduct.supplier.value,
				expiryDate: this.state.updateProduct.expiryDate.value,
				productTerpenes: this.mapProductTerpines(this.state.updateProduct.terpeneIds, this.state.updateProduct.productTerpeneProfileType)
			});

			// Upload new images
			this.state.updateProduct.images.filter(image => image.isNew === true).forEach(image => {
				this.submitProductPhoto(this.state.updateProduct.id.value, image)
			});

			// Upload new documents
			this.state.updateProduct.documents.filter(image => image.isNew === true).forEach(document => {
				this.submitProductDocument(this.state.updateProduct.id.value, document)
			});

			// Update Access Level for existing images if needed
			this.state.updateProduct.images.filter(image => image.accessLevelChanged === true && image.isNew === undefined || image.isNew === false).forEach(image => {
				this.updateMediaAccessLevel(image.id, image.mediaAccessLevel)
			});

			//Update Access level for existing documents if needed
			this.state.updateProduct.documents.filter(document => document.accessLevelChanged === true && document.isNew === undefined || document.isNew === false).forEach(document => {
				this.updateMediaAccessLevel(document.id, document.mediaAccessLevel)
			});

			notificationHelper.info("Product updated!");

			this.getAllCommentsByProductId(this.state.updateProduct.id.value);

			browserHelper.navigate("/products");
		} catch (error) {
			errorHelper.handleError(error);
		}

		this.setState({ updatingProduct: false });
	};

	delete = async (id) => {
		try {
			await productService.delete(id);

			notificationHelper.info("Product deleted!");

			browserHelper.navigate("/products");
		} catch (error) {
			errorHelper.handleError(error);
		}
	};

	//#endregion

	getOrdersAwaitingStock = async (productId, amount) => {

		let orders = await prescriptionService.getOrdersAwaitingStock(productId, amount);

		for (let order of orders) {
			order.expirationDateTime = formatDateForPresenting(order.expirationDateTime, false);
		}

		if (orders.length === 0) {
			notificationHelper.info("There are no orders available for stock allocation!");
		}

		this.setState({
			ordersAwaitingStock: { value: orders }
		});
	}

	changeSelectedOrder = (index) => {
		const orders = this.state.ordersAwaitingStock.value;
		if (orders[index]) {
			orders[index].isSelected = !orders[index].isSelected

			this.setState({
				ordersAwaitingStock: { value: orders }
			});
		}
	}

	updateStockForProduct = async (productId, newStockAmount) => {
		try {
			const product = await productService.updateStockForProduct({ productId, newStockAmount });

			notificationHelper.info(`New stock amount allocated to ${product.name}!`);

			this.setState({
				ordersAwaitingStock: { value: [] }
			});

		}
		catch (error) {
			errorHelper.handleError(error);
		}

	}

	editStockForProduct = async (productId, newStockAmount) => {
		try {
			const product = await productService.editStockForProduct({ productId, newStockAmount });

			notificationHelper.info(`New stock amount allocated to ${product.name}!`);

			this.setState({
				ordersAwaitingStock: { value: [] }
			});

		}
		catch (error) {
			errorHelper.handleError(error);
		}
	}

	updateStockForProductManually = async (productId, newStockAmount, orderIds) => {
		try {
			const product = await productService.updateStockForProductManually({ productId, newStockAmount, orderIds });

			notificationHelper.info(`New stock amount allocated to ${product.name}!`);

			this.setState({
				ordersAwaitingStock: { value: [] }
			});
		}
		catch (error) {
			errorHelper.handleError(error);
		}

	}

	setCreateCommentState = (adminId, productId) => {
		this.setState({
			createComment: {
				adminId: { value: adminId },
				productId: { value: productId },
				text: { value: "" },
			},
		});
	};

	createComment = async () => {
		try {
			const commentCreate = {
				adminId: this.state.createComment.adminId.value,
				productId: this.state.createComment.productId.value,
				eventContent: this.state.createComment.text.value,
			};

			await productService.createComment(commentCreate);

			this.setCreateCommentState(this.state.createComment.adminId.value, this.state.createComment.productId.value);
			this.getAllCommentsByProductId(this.state.createComment.productId.value);

			notificationHelper.info("Comment added!");
		} catch (error) {
			errorHelper.handleError(error);
			this.setCreateCommentState(this.state.createComment.adminId.value, this.state.createComment.productId.value);
		}
	};

	getAllCommentsByProductId = async (id) => {
		try {
			const comments = await productService.getAllCommentsByProductId(id);

			this.setState({ comments: { value: comments } });
			this._setCommentsTableData(comments);
		} catch (error) {
			errorHelper.handleError(error);
		}
	};

	_setCommentsTableData(comments) {
		let commentsTableRows = [];

		for (let comment of comments) {
			let tableRow = this._getCommentTableRow(comment);
			commentsTableRows.push(tableRow);
		}

		this.setState({ comments: { tableData: commentsTableRows } });
	}

	_getCommentTableRow(comment) {
		return [
			{
				text: comment.eventContent,
				column: 7,
			},
			{
				text: `${comment.admin ? comment.admin.name : "API"}`,
				column: 3,
			},
			{
				text: formatDateForPresenting(comment.createdAt, true, ""),
				column: 2,
				right: true,
				type: "date",
			}
		];
	}

	getAllProductImagesById = async (productId) => {
		try {
			const productImages = await productService.getAllProductImagesById(productId);

			return productImages
		} catch (error) {
			errorHelper.handleError(error);
		}
	};

	getAllProductDocumentsById = async (productId) => {
		try {
			const productDocuments = await productService.getAllProductDocumentsById(productId);

			return productDocuments
		} catch (error) {
			errorHelper.handleError(error);
		}
	};

	submitProductPhoto = async (productId, image) => {
		image.mediaAccessLevel = image.mediaAccessLevel !== undefined ? image.mediaAccessLevel : ProducMediaAccessLevel.AdminOnly;

		try {
			const data = await productService.submitProductImage(productId, image);

			this.setState({ updateProduct: { ...this.state.updateProduct, images: [...this.state.updateProduct.images, data] } });

			return data
		} catch (error) {
			console.warn(error)
		}
	};

	submitProductDocument = async (productId, document) => {
		document.mediaAccessLevel = document.mediaAccessLevel !== undefined ? document.mediaAccessLevel : ProducMediaAccessLevel.AdminOnly;

		try {
			const data = await productService.submitDocument(productId, document);

			this.setState({ updateProduct: { ...this.state.updateProduct, documents: [...this.state.updateProduct.documents, data] } });

			return data
		} catch (error) {
			console.warn(error)
		}
	};

	deleteProductImage = async (index) => {
		try {
			const productMediaId = this.state.updateProduct.images[index].id
			this.state.updateProduct.images.splice(index, 1);

			await productService.deleteProductMedia(productMediaId);

			notificationHelper.info("Product photo deleted");

		} catch (error) {
			errorHelper.handleError(error);
		}
	};

	deleteProductDocument = async (index) => {
		try {
			const productMediaId = this.state.updateProduct.documents[index].id
			this.state.updateProduct.documents.splice(index, 1);

			await productService.deleteProductMedia(productMediaId);

			notificationHelper.info("Documentation deleted");

		} catch (error) {
			errorHelper.handleError(error);
		}
	};

	updateMediaAccessLevel = async (index, newAccessLevel) => {
		try {
			await productService.updateProductMediaAccessLevel(index, newAccessLevel);
		} catch (error) {
			console.warn(error)
		}
	}

	addNoteToProduct = async (noteValue, currentAdministrator) => {
		try {
			const addNote = {
				productId: this.state.updateProduct.id.value,
				prescriberNote: noteValue,
			};

			await productService.addPrescriberNote(addNote);

			this.setState({
				updateProduct: {
					...this.state.updateProduct, prescriberNote: { value: noteValue }, prescriberNoteAdmin: {
						value: {
							name: currentAdministrator.name
						}
					}
				}
			})
			notificationHelper.info(`Note for Prescriber added`);
			this.getAllCommentsByProductId(this.state.updateProduct.id.value);
		}
		catch (error) {
			errorHelper.handleError(error);
		}
	}

	removePrescriberNote = async () => {
		try {
			const productId = this.state.updateProduct.id.value;

			await productService.removePrescriberNote(productId);

			this.setState({ updateProduct: { ...this.state.updateProduct, prescriberNote: { value: "" }, prescriberNoteAdmin: { value: {} } } })
			this.getAllCommentsByProductId(this.state.updateProduct.id.value);
		}
		catch (error) {
			errorHelper.handleError(error)
		}
	}

	getProductReport = async () => {
		try {
			let response = await productService.getProductStockMetrics();
			return MetricsService._handleDownloadCSV(response, "Product report")
		} catch (error) {
			errorHelper.handleError(error);
		}
	}
}

export default ProductContainer;
