/*eslint-disable eqeqeq*/
import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { Subscribe } from "unstated";

import Button from "../../components/Form/Button";
import Dropdown from "../../components/Form/Dropdown";
import Checkbox from "../../components/Form/Checkbox";
import Textarea from "../../components/Form/Textarea";
import TextInput from "../../components/Form/TextInput";
import NumberInput from "../../components/Form/NumberInput";
import FormRow from "../../components/Form/FormRow";

import Prompt from "../../components/Modal/Prompt";

import ProductContainer from "../../state/ProductContainer";
import AuthenticationContainer from "../../state/AuthenticationContainer";

import { AdminType, BannerTypes, ProductTypes, } from "../../enums";
import contentHelper from "../../util/helpers/contentHelper";
import { SpinnerIcon } from "../../assets/icons";
import Table from "../../components/Table/Table";
import tableHeaders from "../../util/constants/tableHeaders";
import LoadingCard from "../../components/SharedComponents/LoadingCard";
import ImageDragAndDrop from "../../components/ImageDragAndDrop/ImageDragAndDrop";
import DocumentDragAndDrop from "../../components/DocumentDragAndDrop/DocumentDragAndDrop";
import DateInput from "../../components/Form/DateInput";
import FlagIcon from "../../assets/icons/FlagIcon";
import Banner from "../../components/Banner/Banner"
import CustomSelect from "../../components/Form/CustomSelect";
import RemoveLinkedOrganisationIcon from "../../assets/icons/RemoveLinkedOrganisationIcon";
import EditFlagButton from "../../components/Form/EditFlagButton";
import EditNoteIcon from "../../assets/icons/EditNoteIcon";

function EditProduct({ container, currentAdministrator }) {
	const { id } = useParams();

	const editProductFormKey = "updateProduct";
	const editProduct = container.state[editProductFormKey];
	const productsActiveOrdersKey = "productsActiveOrders";
	const productsActiveOrders = container.state[productsActiveOrdersKey].value;
	const suppliers = container.state.suppliers;
	const commentFormKey = "createComment";
	const commentsTableDataKey = "tableData";
	const comment = container.state[commentFormKey];
	const commentsTableData = container.state.comments[commentsTableDataKey];
	const onCommentChange = (event) => container.onFormChange(event, commentFormKey);

	const onFieldChangeEditProduct = (event) => { container.onFormChange(event, editProductFormKey) };
	// editProduct.type.value is a string when you select from dropdown again, that's why ==
	const fieldHeaderSign = editProduct.type.value == ProductTypes.Oil ? "mg/ml" : "%";

	const [showPrompt, setPromptVisibility] = useState(false);

	const [showDeleteProductPhotoPrompt, setDeleteProductPhotoPrompt] = useState(false);

	const [itemToDelete, setItemToDelete] = useState();

	const [showDeleteProductDocumentPrompt, setDeleteProductDocumentPrompt] = useState(false);
	const [showNoteModal, setNoteModal] = useState(false);

	const isAdminRoleSuperAdmin = [AdminType.Super].includes(currentAdministrator.type)

	const loadingProductInfo = container.state.updatingProduct

	const [images, setImages] = useState(editProduct.images);
	const [documents, setDocuments] = useState(editProduct.documents);

	const [isPercentageSelected, setIsPercentageSelected] = useState()
	const [isMajorMinorSelected, setIsMajorMinorSelected] = useState()

	const onTerpeneSelectChange = (event, index) => container.onTerpeneSelectChange(event, index, true);
	const onRemoveTerpene = (index) => container.onRemoveTerpene(index, true);

	const [openEditNoteDropdown, setOpenEditNoteDropdown] = useState(false);

	const updateProduct = () => {
		editProduct.images = images
		editProduct.documents = documents
		container.update()
	}

	useEffect(() => {
		setImages(editProduct.images)
	}, [editProduct.images])

	useEffect(() => {
		setDocuments(editProduct.documents)
	}, [editProduct.documents])
	const [noteValue, setNoteValue] = useState(editProduct.prescriberNote.value);

	useEffect(() => {
		container.setForUpdate(id);
		container.setCreateCommentState(currentAdministrator.id, id);

		return container.resetUpdateState;
	}, [id, container]);

	useEffect(() => {
		container.getAllSuppliers(editProductFormKey)
		container._getTerpenes();

	}, [])

	useEffect(() => {
		if (editProduct.productTerpeneProfileType.value == 1) {
			setIsPercentageSelected(false);
			setIsMajorMinorSelected(true);
		} else {
			setIsPercentageSelected(true);
			setIsMajorMinorSelected(false);
		}
	}, [editProduct.productTerpeneProfileType])

	//Click outside hook
	const ref = useRef(null);

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (ref.current && !ref.current.contains(event.target)) {
				setOpenEditNoteDropdown(false)
			}
		};
		document.addEventListener('click', handleClickOutside, true);
		return () => {
			document.removeEventListener('click', handleClickOutside, true);
		};
	}, [setOpenEditNoteDropdown]);

	const openDeletePhotoModal = (index, showModal) => {
		setDeleteProductPhotoPrompt(showModal)
		setItemToDelete(index)
	}

	const onDeleteImage = (index) => {
		setImages((prevImages) => {
			const newImages = [...prevImages];
			newImages.splice(index, 1);
			return newImages;
		});
	};

	const openDeleteDocumentModal = (index, showModal) => {
		setItemToDelete(index)
		setDeleteProductDocumentPrompt(showModal)
	}

	const onDeleteDocument = (index) => {
		setDocuments((prevDocuments) => {
			const newDocuments = [...prevDocuments];
			newDocuments.splice(index, 1);
			return newDocuments;
		});
	};
	useEffect(() => {
		setNoteValue(editProduct.prescriberNote.value)
	}, [editProduct.prescriberNote.value])


	function renderModals() {
		return (
			<div>
				<Prompt
					isOpen={showNoteModal}
					title="Note for Prescriber"
					description="Please enter your note for the prescriber regarding this product."
					onYes={() => {
						container.addNoteToProduct(noteValue, currentAdministrator)
						setNoteModal(false);
					}}
					onNo={() => {
						setNoteModal(false)
						setNoteValue("")
					}}
					onClose={() => {
						setNoteModal(false)
						setNoteValue("")
					}}
					yesDisabled={!noteValue || noteValue.length > 255}
				>
					<div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
						<p style={{ marginBottom: "40px" }}>Your note will be visible to Prescribers that view this product in the Formulary section of their Lyphe Dispensary account.</p>
						<Textarea
							name="prescriberNote"
							placeholder="Add comment..."
							value={noteValue || ""}
							onChange={(event) => setNoteValue(event.value)}
							maxLength={255}
						/>
					</div>
				</Prompt>

				<Prompt
					isOpen={showPrompt}
					title="Remove product"
					description="Are you sure? All the data related to this entity will be removed."
					onYes={() => {
						container.delete(id);
						setPromptVisibility(false);
					}}
					onNo={() => setPromptVisibility(false)}
					onClose={() => setPromptVisibility(false)}
				/>

				<Prompt
					isOpen={showDeleteProductPhotoPrompt}
					title="Delete product photo"
					description="Please confirm you would like to delete the selected product photo"
					onYes={() => {
						onDeleteImage(itemToDelete);
						container.deleteProductImage(itemToDelete)
						setDeleteProductPhotoPrompt(false);
					}}
					onNo={() => setDeleteProductPhotoPrompt(false)}
					onClose={() => setDeleteProductPhotoPrompt(false)}
				/>

				<Prompt
					isOpen={showDeleteProductDocumentPrompt}
					title="Delete documentation"
					description="Please confirm you would like to delete the selected documentation photo"
					onYes={() => {
						container.deleteProductDocument(itemToDelete)
						onDeleteDocument(itemToDelete);
						setDeleteProductDocumentPrompt(false);
					}}
					onNo={() => setDeleteProductDocumentPrompt(false)}
					onClose={() => setDeleteProductDocumentPrompt(false)}
				/>
			</div>
		);
	}

	const handleOpenEditNoteDropdown = () => {
		setOpenEditNoteDropdown(!openEditNoteDropdown)
	}

	const handleEditNoteButton = () => {
		setNoteModal(true)
		setOpenEditNoteDropdown(false)
	}

	const handleRemoveNoteButton = () => {
		container.removePrescriberNote()
		setOpenEditNoteDropdown(false)
	}

	function renderNoteBannerContent() {
		return <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center', position: "relative" }} >
			<div className="m-r-s" >
				<b>{editProduct.prescriberNoteAdmin?.value?.name}:</b> {editProduct.prescriberNote.value}
			</div>
			{isAdminRoleSuperAdmin && <EditFlagButton>
				<EditNoteIcon onClick={handleOpenEditNoteDropdown} />
				<div ref={ref} className={`edit-flag-button--dropdown ${openEditNoteDropdown ? "edit-flag-button--dropdown--expand" : ""}`}>
					<div onClick={handleEditNoteButton}>Edit</div>
					<div onClick={handleRemoveNoteButton}>Remove</div>
				</div>
			</EditFlagButton>
			}
		</div>
	}

	return (
		<>
			{loadingProductInfo ?
				<LoadingCard text={"Loading product information"} />
				:
				<div className="card p-m">
					{renderModals()}

					<FormRow>
						<h1 className="h4">Edit product</h1>
					</FormRow>

					{editProduct.prescriberNote.value && <FormRow>
						<Banner type={BannerTypes.Note} text={renderNoteBannerContent()} />
					</FormRow>}

					<FormRow>
						<h2 className="h6">Availability</h2>
					</FormRow>
					<div className="j-c-sb">
						<FormRow className="j-c-s" style={{ alignItems: "center" }}>
							<div className="col-2">
								<Dropdown
									name="availability"
									value={editProduct.availability.value}
									error={editProduct.availability.error}
									onChange={onFieldChangeEditProduct}
									label="Supplier status"
									options={[
										{ text: "Available", value: 0 },
										{ text: "Unavailable", value: 1 },
										{ text: "Discontinued", value: 2 },
										{ text: "Available to order", value: 3 },
									]}
									disabled={!isAdminRoleSuperAdmin}
								/>
							</div>
							<div className="col-2">
								<DateInput
									value={editProduct.expiryDate?.value}
									error={editProduct.expiryDate?.error}
									onChange={onFieldChangeEditProduct}
									name="expiryDate"
									label="Expiry Date"
									placeholder="e.g. 10/09/2020"
									disabled={!isAdminRoleSuperAdmin}>
								</DateInput>
							</div>
						</FormRow>
						<>
							{(editProduct.prescriberNote.value === "" && isAdminRoleSuperAdmin) && <Button
								className="note_button"
								onClick={() => setNoteModal(true)}
							>
								<FlagIcon />
								<span>Add note for prescriber</span>
							</Button>}

							{(editProduct.prescriberNote.value && isAdminRoleSuperAdmin) && <Button
								className="note_button"
								onClick={() => container.removePrescriberNote()}
							>
								<FlagIcon />
								<span>Remove note for prescriber</span>
							</Button>}
						</>
					</div>

					<FormRow>
						<h1 className="h6">Product info</h1>
					</FormRow>

					<FormRow>
						<div className="col-2">
							<TextInput
								name="sku"
								value={editProduct.sku.value}
								error={editProduct.sku.error}
								onChange={onFieldChangeEditProduct}
								label="sku"
								placeholder="e.g. 402384901123"
								disabled={!isAdminRoleSuperAdmin}
							/>
						</div>
						<div className="col-4">
							<TextInput
								name="name"
								value={editProduct.name.value}
								error={editProduct.name.error}
								onChange={onFieldChangeEditProduct}
								label="Name"
								placeholder="e.g. High potency CBD oil"
								disabled={!isAdminRoleSuperAdmin}
							/>
						</div>

						<div className="col-2">
							<Dropdown
								name="type"
								value={editProduct.type.value}
								error={editProduct.type.error}
								onChange={onFieldChangeEditProduct}
								label="Type"
								options={contentHelper.getProductTypesAsDropdownItems()}
								disabled={!isAdminRoleSuperAdmin}
							/>
						</div>
						<div className="col-2">
							<Dropdown
								name="subtype"
								value={editProduct.subtype.value}
								error={editProduct.subtype.error}
								onChange={onFieldChangeEditProduct}
								label="Subtype"
								options={contentHelper.getProductSubtypesAsDropdownItems()}
								disabled={!isAdminRoleSuperAdmin}
							/>
						</div>
						<div className="col-2">
							<Dropdown
								name="phEur"
								value={editProduct.phEur.value}
								error={editProduct.phEur.error}
								onChange={onFieldChangeEditProduct}
								label="Ph. Euro"
								options={contentHelper.getProductPhEurAsDropdownItems()}
								disabled={!isAdminRoleSuperAdmin}
							/>
						</div>
					</FormRow>
					<FormRow>
						<div className="col-12">
							<Textarea
								name="description"
								value={editProduct.description.value}
								error={editProduct.description.error}
								onChange={onFieldChangeEditProduct}
								label="Description"
								placeholder="Type in product description here..."
								disabled={!isAdminRoleSuperAdmin}
							/>
						</div>
					</FormRow>
					<FormRow>
						<div className="col-4">
							<Checkbox
								name="thcLessThen"
								value={editProduct.thcLessThen.value}
								error={editProduct.thc.error}
								onChange={onFieldChangeEditProduct}
								label={`<`}
								right={true}
								disabled={!isAdminRoleSuperAdmin}
							/>
							<NumberInput
								name="thc"
								value={editProduct.thc.value}
								error={editProduct.thc.error}
								onChange={onFieldChangeEditProduct}
								label={`THC (${fieldHeaderSign})`}
								placeholder="e.g. 4"
								disabled={!isAdminRoleSuperAdmin}
							/>
						</div>
						<div className="col-4">
							<Checkbox
								name="cbdLessThen"
								value={editProduct.cbdLessThen.value}
								error={editProduct.cbd.error}
								onChange={onFieldChangeEditProduct}
								label={`<`}
								right={true}
								disabled={!isAdminRoleSuperAdmin}
							/>
							<NumberInput
								name="cbd"
								value={editProduct.cbd.value}
								error={editProduct.cbd.error}
								onChange={onFieldChangeEditProduct}
								label={`CBD (${fieldHeaderSign})`}
								placeholder="e.g. 22"
								disabled={!isAdminRoleSuperAdmin}
							/>
						</div>
						<div className="col-4">
							<TextInput
								name="activeIngredient"
								value={editProduct.activeIngredient.value}
								error={editProduct.activeIngredient.error}
								onChange={onFieldChangeEditProduct}
								label="Active ingredient"
								placeholder="e.g. CBD"
								disabled={!isAdminRoleSuperAdmin}
							/>
						</div>
					</FormRow>

					<FormRow>
						<div className="col-2">
							<NumberInput
								name="weight"
								value={editProduct.weight.value}
								error={editProduct.weight.error}
								onChange={onFieldChangeEditProduct}
								label="Product Weight"
								placeholder="e.g. 12 (g)"
								disabled={!isAdminRoleSuperAdmin}
							/>
						</div>
						<div className="col-2">
							<Dropdown
								name="productUnit"
								value={editProduct.productUnit.value}
								error={editProduct.productUnit.error}
								onChange={onFieldChangeEditProduct}
								label="Unit"
								options={[
									{ text: "g", value: 0 },
									{ text: "ml", value: 1 },
								]}
								disabled={!isAdminRoleSuperAdmin}
							/>
						</div>
						<div className="col-2">
							<NumberInput
								name="containerWeight"
								value={editProduct.containerWeight.value}
								error={editProduct.containerWeight.error}
								onChange={onFieldChangeEditProduct}
								label="Product & Container Weight"
								placeholder="e.g. 12 (g)"
								disabled={!isAdminRoleSuperAdmin}
							/>
						</div>
						<div className="col-3">
							{+editProduct.type.value === ProductTypes.Flower &&
								(<Dropdown
									name="isPotContainer"
									value={editProduct.isPotContainer.value}
									error={editProduct.isPotContainer.error}
									onChange={onFieldChangeEditProduct}
									label="Container Type"
									options={[
										{ text: "Pouch", value: false },
										{ text: "Pot", value: true },
									]}
								/>)}
						</div>
						<div className="col-3">
							<Dropdown
								name="irradiatedType"
								value={editProduct.irradiatedType.value}
								error={editProduct.irradiatedType.error}
								onChange={onFieldChangeEditProduct}
								label="Irradiated Type"
								options={[
									{ text: "Non Irradiated ", value: 0 },
									{ text: "Beta Irradiated", value: 1 },
									{ text: "Gamma Irradiated", value: 2 },
								]}
							/>
						</div>
					</FormRow>
					<FormRow>
						<div className="col-3">
							<NumberInput
								name="retailPrice"
								value={editProduct.retailPrice.value}
								error={editProduct.retailPrice.error}
								onChange={onFieldChangeEditProduct}
								label="Retail price (£)"
								placeholder="e.g. 18.99"
								disabled={!isAdminRoleSuperAdmin}
							/>
						</div>
						<div className="col-3">
							<NumberInput
								name="wholesalePrice"
								value={editProduct.wholesalePrice.value}
								error={editProduct.wholesalePrice.error}
								onChange={onFieldChangeEditProduct}
								label="Wholesale price (£)"
								placeholder="e.g. 18.99"
								disabled={!isAdminRoleSuperAdmin}
							/>
						</div>

						<div className="col-6">

						</div>
					</FormRow>
					<FormRow>
						<div className="col-2">
							<Dropdown
								name="isT21Eligible"
								value={editProduct.isT21Eligible.value}
								error={editProduct.isT21Eligible.error}
								onChange={onFieldChangeEditProduct}
								label="Is T21 Eligible"
								options={[
									{ text: "No", value: false },
									{ text: "Yes", value: true },
								]}
								disabled={!isAdminRoleSuperAdmin || editProduct.isSubsidyEligible.value}
							/>
						</div>
						<div className="col-2">
							<Dropdown
								name="isSubsidyEligible"
								value={editProduct.isSubsidyEligible.value}
								error={editProduct.isSubsidyEligible.error}
								onChange={onFieldChangeEditProduct}
								label="Subsidy Eligible"
								options={[
									{ text: "No", value: false },
									{ text: "Yes", value: true },
								]}
								disabled={editProduct.isT21Eligible.value || !isAdminRoleSuperAdmin}
							/>
						</div>
						<div className="col-2">
							{editProduct.isT21Eligible.value && (
								<Dropdown
									name="isMixAndMatchEligible"
									value={editProduct.isMixAndMatchEligible.value}
									error={editProduct.isMixAndMatchEligible.error}
									onChange={onFieldChangeEditProduct}
									label="Is MixAndMatch Eligible"
									options={[
										{ text: "No", value: false },
										{ text: "Yes", value: true },
									]}
									disabled={!isAdminRoleSuperAdmin}
								/>
							)}

						</div>
						<div className="col-2">
							{editProduct.isT21Eligible.value && (
								<NumberInput
									name="t21BasePrice"
									value={editProduct.t21BasePrice.value}
									error={editProduct.t21BasePrice.error}
									onChange={onFieldChangeEditProduct}
									label="T21 Base Price"
									placeholder="e.g 65"
									disabled={!isAdminRoleSuperAdmin}
								/>
							)}
						</div>
						<div className="col-2">
							{editProduct.isT21Eligible.value && (
								<NumberInput
									name="t21FirstQuantityCap"
									value={editProduct.t21FirstQuantityCap.value}
									error={editProduct.t21FirstQuantityCap.error}
									onChange={onFieldChangeEditProduct}
									label="T21 First Quantity Cap"
									placeholder="e.g 2"
									disabled={!isAdminRoleSuperAdmin}
								/>
							)}
						</div>
						<div className="col-2">
							{editProduct.isT21Eligible.value && (
								<NumberInput
									name="t21FirstQuantityCapPrice"
									value={editProduct.t21FirstQuantityCapPrice.value}
									error={editProduct.t21FirstQuantityCapPrice.error}
									onChange={onFieldChangeEditProduct}
									label="T21 First Quantity Cap Price"
									placeholder="e.g 120"
									disabled={!isAdminRoleSuperAdmin}
								/>
							)}
						</div>
					</FormRow>
					{editProduct.isSubsidyEligible.value && (
						<FormRow>
							<div className="col-4">
								<NumberInput
									name="t21SubsidyPrice"
									value={editProduct.t21SubsidyPrice.value}
									error={editProduct.t21SubsidyPrice.error}
									onChange={onFieldChangeEditProduct}
									label="T21 Subsidy Price (£)"
									placeholder="e.g 3"
									disabled={!isAdminRoleSuperAdmin}
								/>
							</div>
						</FormRow>
					)}
					{editProduct.isT21Eligible.value && (
						<FormRow>
							<div className="col-4">
								<NumberInput
									name="t21SecondQuantityCap"
									value={editProduct.t21SecondQuantityCap.value}
									error={editProduct.t21SecondQuantityCap.error}
									onChange={onFieldChangeEditProduct}
									label="T21 Second Quantity Cap"
									placeholder="e.g 3"
									disabled={!isAdminRoleSuperAdmin}
								/>
							</div>
							<div className="col-4">
								<NumberInput
									name="t21SecondQuantityCapPrice"
									value={editProduct.t21SecondQuantityCapPrice.value}
									error={editProduct.t21SecondQuantityCapPrice.error}
									onChange={onFieldChangeEditProduct}
									label="T21 Second Quantity Cap Price"
									placeholder="e.g 150"
									disabled={!isAdminRoleSuperAdmin}
								/>
							</div>
							<div className="col-4">
								<NumberInput
									name="t21AdditionalAmountPrice"
									value={editProduct.t21AdditionalAmountPrice.value}
									error={editProduct.t21AdditionalAmountPrice.error}
									onChange={onFieldChangeEditProduct}
									label="T21 Additional Amount Price"
									placeholder="e.g 150"
									disabled={!isAdminRoleSuperAdmin}
								/>
							</div>
						</FormRow>
					)}
					<FormRow>
						<h2 className="h6">Subscription pricing</h2>
					</FormRow>
					<FormRow>
						<div className="col-3">
							<NumberInput
								name="allPatientsT1Price"
								value={editProduct.allPatientsT1Price.value}
								error={editProduct.allPatientsT1Price.error}
								onChange={onFieldChangeEditProduct}
								label="All patients Tier 1 Price (£)"
								placeholder="e.g. 18.99"
								disabled={!isAdminRoleSuperAdmin}
							/>
						</div>
						<div className="col-3">
							<NumberInput
								name="allPatientsT2Price"
								value={editProduct.allPatientsT2Price.value}
								error={editProduct.allPatientsT2Price.error}
								onChange={onFieldChangeEditProduct}
								label="All patients Tier 2 Price (£)"
								placeholder="e.g. 18.99"
								disabled={!isAdminRoleSuperAdmin}
							/>
						</div>
						<div className="col-3">
							<NumberInput
								name="accessSchemeT1Price"
								value={editProduct.accessSchemeT1Price.value}
								error={editProduct.accessSchemeT1Price.error}
								onChange={onFieldChangeEditProduct}
								label="Access scheme patients Tier 1 Price (£)"
								placeholder="e.g. 18.99"
								disabled={!isAdminRoleSuperAdmin}
							/>
						</div>
						<div className="col-3">
							<NumberInput
								name="accessSchemeT2Price"
								value={editProduct.accessSchemeT2Price.value}
								error={editProduct.accessSchemeT2Price.error}
								onChange={onFieldChangeEditProduct}
								label="Access scheme patients Tier 2 Price (£)"
								placeholder="e.g. 18.99"
								disabled={!isAdminRoleSuperAdmin}
							/>
						</div>
					</FormRow>
					<FormRow>
						<h2 className="h6">Terpene profile</h2>
					</FormRow>
					<FormRow>
						<div className="col-6">
							<div style={{ marginRight: "50px" }}>
								<Checkbox
									name="percent"
									value={isPercentageSelected}
									onChange={() => { setIsPercentageSelected(true); setIsMajorMinorSelected(false); container.setProductTerpeneProfileType(0, true) }}
									label={`%`}
									right={false}
									disabled={!isAdminRoleSuperAdmin}
								/></div>
							<Checkbox
								name="major_minor"
								value={isMajorMinorSelected}
								onChange={() => { setIsPercentageSelected(false); setIsMajorMinorSelected(true); container.setProductTerpeneProfileType(1, true) }}
								label={`Major/Minor`}
								right={false}
								disabled={!isAdminRoleSuperAdmin}
							/>
						</div>
					</FormRow>

					{editProduct.terpeneIds.value.map((terpene, index) => {
						return <FormRow className="j-c-s col-10" key={container.state.terpenes.length + index}>
							<div className="col-4">
								<CustomSelect
									label="Terpene name"
									name="terpeneSelect"
									values={terpene ? [terpene] : []}
									options={container.state.terpenes}
									onChange={(event) => { onTerpeneSelectChange(event, index, true) }}
									placeholder={"Search and select..."}
									disabled={!isAdminRoleSuperAdmin}
								/>
							</div>
							{isMajorMinorSelected && <div style={{ marginLeft: "20px" }}>
								<CustomSelect
									name="majorMinor"
									values={terpene.majorMinor ? [terpene.majorMinor.value] : []}
									onChange={(e) => container.onTerpineMajorMinorChange(e, index, true)}
									label="Value"
									options={[
										{ label: "Minor", value: 0 },
										{ label: "Major", value: 1 },
									]}
									disabled={!isAdminRoleSuperAdmin}
								/>
							</div>}
							{isPercentageSelected && <div style={{ marginLeft: "20px", width: "9%" }}>
								<NumberInput
									name="percentage"
									value={terpene.percentage.value}
									error={terpene.percentage.error}
									onChange={(e) => { container.onTerpinePercentageChange(e, index, true) }}
									label="Value (%)"
									placeholder="e.g."
									disabled={!isAdminRoleSuperAdmin}
								/>
							</div>}
							{(editProduct.terpeneIds.value.length > 1 && isAdminRoleSuperAdmin) &&
								<div className="col-2">
									<Button
										className="remove_list_item"
										onClick={() => { onRemoveTerpene(index) }}
									>
										<RemoveLinkedOrganisationIcon />
									</Button>
								</div>
							}
						</FormRow>
					}
					)}
					{(editProduct.terpeneIds.value.length < 15 && isAdminRoleSuperAdmin) &&
						<FormRow>
							<div onClick={() => { container.addTerpene(true) }} className="add_list_element_button">
								<i className="icon-plus" />
								<p className="uppercase m-l-8">ADD TERPENE</p>
							</div>
						</FormRow>
					}

					<FormRow>
						<h2 className="h6">Manufacturing information</h2>
					</FormRow>
					<FormRow>
						<div className="col-4">
							<Dropdown
								name="supplier"
								value={editProduct.supplier.value}
								error={editProduct.supplier.error}
								onChange={onFieldChangeEditProduct}
								label="supplier"
								options={suppliers}
								disabled={!isAdminRoleSuperAdmin}
							/>
						</div>
					</FormRow>
					<FormRow>
						<div className="col-3">
							<TextInput
								name="manufacturer"
								value={editProduct.manufacturer.value}
								error={editProduct.manufacturer.error}
								onChange={onFieldChangeEditProduct}
								label="Manufacturer"
								placeholder="e.g. Some manufacturer"
								disabled={!isAdminRoleSuperAdmin}
							/>
						</div>
					</FormRow>
					<FormRow>
						<div className="col-2">
							<TextInput
								name="country"
								value={editProduct.country.value}
								error={editProduct.country.error}
								onChange={onFieldChangeEditProduct}
								label="Country"
								placeholder="e.g. GB"
								disabled={!isAdminRoleSuperAdmin}
							/>
						</div>
					</FormRow>

					<FormRow>
						<h2 className="h6">Product photos</h2>
					</FormRow>

					<FormRow>
						<ImageDragAndDrop
							productId={editProduct.id.value}
							onItemDelete={(index, showModal) => openDeletePhotoModal(index, showModal)}
							images={images}
							setImages={setImages}
							disabled={!isAdminRoleSuperAdmin} />
					</FormRow>

					<FormRow>
						<h2 className="h6">Documentation</h2>
					</FormRow>

					<FormRow>
						<DocumentDragAndDrop
							productId={editProduct.id.value}
							onItemDelete={(index, showModal) => openDeleteDocumentModal(index, showModal)}
							documents={documents}
							setDocuments={setDocuments}
							disabled={!isAdminRoleSuperAdmin} />
					</FormRow>

					<FormRow>{isAdminRoleSuperAdmin &&
						<div className="col-12">
							<Button
								onClick={() => updateProduct()}
								disabled={!container.isFormValid(editProductFormKey) || container.state.updatingProduct}
							>
								{container.state.updatingProduct ? <SpinnerIcon /> : "Save"}
							</Button>
							<div className="m-r-s"></div>

							<Button text="Delete"
								onClick={() => setPromptVisibility(true)}
								disabled={!isAdminRoleSuperAdmin}
							/>
						</div>
					}
					</FormRow>

					<FormRow>
						<div className="col-12">
							<Table
								title="Active orders with this product"
								tableData={{
									head: tableHeaders.productsActiveOrders,
									body: productsActiveOrders,
								}}
								small
								showSpinner={container.state.loadingEventHistory}
								onColumnSort={(columnIndex) => container.sortActiveProducts(columnIndex, tableHeaders.productsActiveOrders, productsActiveOrders)}
							/>
						</div>
					</FormRow>

					<FormRow>
						<div className="col-12">
							<Table
								title="Comments"
								tableData={{
									head: tableHeaders.patientComments,
									body: commentsTableData
								}}
								adjustable
								small
								bottomContent={<></>}
							/>
						</div>
					</FormRow>
					{isAdminRoleSuperAdmin && <>
						<FormRow>
							<Textarea
								name="text"
								placeholder="Add a comment"
								value={comment.text.value}
								error={comment.text.error}
								onChange={onCommentChange}
								maxLength={255}
							/>
						</FormRow>

						<FormRow>
							<Button disabled={!container.isFormValid(commentFormKey)} onClick={container.createComment}>Add Comment</Button>
						</FormRow>
					</>
					}
				</div>}
		</>
	);
}

function SubscribedEditProduct(props) {
	return <Subscribe to={[ProductContainer, AuthenticationContainer]}>{(container, authContainer) => <EditProduct {...props} container={container} currentAdministrator={authContainer.state.currentAdministrator} />}</Subscribe>;
}

export default SubscribedEditProduct;
