import axios from "axios";
import { tokenKey, env, urls } from "../config.json";

const apiBaseUrl = urls[env];

/**
 * Intercepts each request.
 * If error code is 403, this will redirect to /login
 */
axios.interceptors.response.use(
	(next) => Promise.resolve(next),

	(error) => {
		if (error?.response?.status === 401) {
			localStorage.removeItem(tokenKey);

			// Authenticated route component will redirect the user.
			window.location.reload();
		}

		return Promise.reject(error);
	}
);

class ApiClient {
	/**
	 * Generic method for entity creation. Example usage: apiClient.create('prescriptions')({ ... })
	 * @param {*} collection REST collection over which the action should be performed.
	 */
	create = (collection) => (entity) => this.makeRequest(`POST`, `/${collection}/create`, entity);

	/**
	 * Generic method for entity creation. Example usage: apiClient.create('prescriptions')({ ... })
	 * @param {*} collection REST collection over which the action should be performed.
	 * The same as create but it sends data to server as form data
	 */
	createUsingFromData = (collection) => (entity) => this.makeRequest(`POST`, `/${collection}/create`, entity, true);

	/**
	 * Generic method for entity filtering. Example usage: apiClient.filter('prescriptions')({ ... })
	 * @param {*} collection REST collection over which the action should be performed.
	 */
	filter = (collection) => (filters, cancelToken) => this.makeRequest(`GET`, `/${collection}/filter?${this.getRouteParams(filters)}`, undefined, undefined, cancelToken);

	/**
	 * Generic method for entity retrieval. Example usage: apiClient.getById('prescriptions')({ ... })
	 * @param {*} collection REST collection over which the action should be performed.
	 */
	getById = (collection) => (id) => this.makeRequest(`GET`, `/${collection}/getbyid/${id}`);

	/**
	 * Generic method for entity update. Example usage: apiClient.update('prescriptions')({ ... })
	 * @param {*} collection REST collection over which the action should be performed.
	 */
	update = (collection) => (entity) => this.makeRequest(`PATCH`, `/${collection}/update`, entity);

	/**
	 * Generic method for entity removal. Example usage: apiClient.delete('prescriptions')({ ... })
	 * @param {*} collection REST collection over which the action should be performed.
	 */
	delete = (collection) => (id) => this.makeRequest(`DELETE`, `/${collection}/delete?${this.getRouteParams(id)}`);

	deleteById = (collection) => (id) => this.makeRequest(`DELETE`, `/${collection}/delete/${id}`);

	// SPECIFIC API CALLS

	login = (credentials) => this.makeRequest("POST", "/auth/admin", credentials);

	requestPasswordReset = (email) => this.makeRequest("POST", `/admins/RequestPasswordReset?email=${email}`);

	resetPassword = (payload) => this.makeRequest("POST", "/admins/resetPassword", payload);

	getAdminData = () => this.makeRequest("GET", "/auth/getadmindata");

	filterUnassigned = (filters) => this.makeRequest(`GET`, `/prescriptions/filterunassigned?${this.getRouteParams(filters)}`);

	filterAwaitingRewrite = (filters) => this.makeRequest(`GET`, `/prescriptions/GetAllAwaitingRewrite?${this.getRouteParams(filters)}`);

	filterFutureDatedScripts = (filters) => this.makeRequest(`GET`, `/prescriptions/GetAllFutureDated?${this.getRouteParams(filters)}`);

	filterActive = (filters) => this.makeRequest(`GET`, `/prescriptions/filteractive?${this.getRouteParams(filters)}`);

	filterPast = (filters, cancelToken) => this.makeRequest(`GET`, `/prescriptions/filterpast?${this.getRouteParams(filters)}`, undefined, undefined, cancelToken);

	filterCompleted = (filters, cancelToken) => this.makeRequest(`GET`, `/prescriptions/filterCompleted?${this.getRouteParams(filters)}`, undefined, undefined, cancelToken);

	filterVoid = (filters, cancelToken) => this.makeRequest(`GET`, `/prescriptions/filterVoid?${this.getRouteParams(filters)}`, undefined, undefined, cancelToken);

	filterArchived = (filters, cancelToken) => this.makeRequest(`GET`, `/prescriptions/filterArchived?${this.getRouteParams(filters)}`, undefined, undefined, cancelToken);

	getPrescriptionPhaseReport = (filters) => this.makeRequest(`GET`, `/prescriptions/getPhaseReport?${this.getRouteParams(filters)}`);

	quickSearch = (filters, cancelToken) => this.makeRequest(`GET`, `/prescriptions/quickSearch?${this.getRouteParams(filters)}`, undefined, undefined, cancelToken);

	assignPrescriptionImage = (payload) => this.makeRequest("PATCH", `/prescriptions/AssignPatientToPrescription?${this.getRouteParams(payload)}`);

	checkAssignPatientToPrescription = (payload) => this.makeRequest("GET", `/prescriptions/CheckAssignPatientToPrescription?${this.getRouteParams(payload)}`);

	processPrescription = (entity) => this.makeRequest("POST", "/prescriptions/process", entity);

	addProductsToPrescription = (entity) => this.makeRequest("POST", "/orderedproducts/createwithorder", entity);

	sendPaymentLink = (orderId) => this.makeRequest(`POST`, `/orders/SendPaymentLinkForOrder?id=${orderId}`);

	performStockCheck = (orderId) => this.makeRequest("POST", `/stockcheck/checkstockforneworder`, { orderId });

	getOrdersAwaitingStock = (payload) => this.makeRequest("GET", `/stockcheck/getordersawaitingstock?${this.getRouteParams(payload)}`);

	updateStockForProduct = (payload) => this.makeRequest("POST", `/stockcheck/updatestockforproduct`, payload);

	editStockForProduct = (payload) => this.makeRequest("POST", `/stockcheck/ReduceStockForProduct`, payload);

	updateStockForProductManually = (payload) => this.makeRequest("POST", `/stockcheck/updatestockforproductmanually`, payload);

	checkStockStatusForProduct = (payload) => this.makeRequest("GET", `/stockcheck/checkstockstatusforproduct?${this.getRouteParams(payload)}`);

	cancelProductInOrder = (orderedProductId, cancellationReason) => this.makeRequest("PATCH", `/orderedproducts/cancelproductinorder`, { orderedProductId, cancellationReason });

	getAllOrdersForProduct = (payload) => this.makeRequest("GET", `/orders/getallforproduct?${this.getRouteParams(payload)}`);

	payForOrder = (transactionInfo) => this.makeRequest("POST", `/transactions/create`, transactionInfo);

	getEventHistoryForPrescription = (prescriptionId) =>
		this.makeRequest(`GET`, `/prescriptionevents/getallbyprescriptionid?${this.getRouteParams({ prescriptionId })}`);

	getEventHistoryForAdmin = (adminId) =>
		this.makeRequest(`GET`, `/adminevents/getallbyadminid?${this.getRouteParams({ adminId })}`);

	getEventHistoryForOrganisationEmployee = (organisationEmployeeId) =>
		this.makeRequest(`GET`, `/employeeevents/getallbyemployeeid?${this.getRouteParams({ employeeId: organisationEmployeeId })}`);

	getSupportComms = (paperPrescriptionId) => this.makeRequest(`GET`, `/orders/gettickets?${this.getRouteParams({ paperPrescriptionId })}`);

	updateShipmentStatus = (payload) => this.makeRequest(`PATCH`, `/orders/updateshippingstatus`, payload);

	contact = (payload) => this.makeRequest(`POST`, `/prescriptions/contactusers`, payload);

	getAllPrescribersWithoutPatientsByOrgId = (orgId) => this.makeRequest(`GET`, `/prescribers/getAllByOrganisationForAdmin?organisationId=${orgId}`);

	getAllAdminsPartial = () => this.makeRequest(`GET`, `/admins/getAllPartial`);

	assignOwner = (payload) => this.makeRequest(`PATCH`, `/prescriptions/assignOwner`, payload);

	patientsFilterForCarer = (filters) => this.makeRequest(`GET`, `/patients/filterforcarer?${this.getRouteParams(filters)}`);

	patientSearch = (filters, cancelToken) => this.makeRequest(`GET`, `/patients/patientsearch?${this.getRouteParams(filters)}`, null, false, cancelToken);

	getMerchantSessionKey = () => this.makeRequest(`GET`, `/transactions/merchantsessionkey`);

	getByGmc = (gmc) => this.makeRequest(`GET`, `/prescribers/GetByGmc?gmc=${gmc}`);

	generateLabelForOrder = (id, comments) => this.makeRequest(`POST`, `/orders/generatelabel?orderId=${id}&comments=${comments}`);

	regenerateLabelForOrder = (payload) => this.makeRequest(`POST`, `/orders/RegenerateLabel?${this.getRouteParams(payload)}`);

	getMetrics = (url) => this.makeRequest(`GET`, url);

	getMetricsWithDate = (url, startDate) => this.makeRequest(`GET`, `${url}?${this.getRouteParams({ startDate })}`);

	getProductStockMetrics = () => this.makeRequest(`GET`, `/products/getStockMetrics`);

	uploadPrescriptionPhoto = (prescription) => this.makeRequest(`POST`, `/prescriptions/changeimage`, prescription, true);

	uploadPrescription = (prescription) => this.makeRequest(`POST`, `/prescriptions/submitbyadmin`, prescription, true);

	archive = (payload) => this.makeRequest(`POST`, `/prescriptions/archive`, payload);

	unarchive = (payload) => this.makeRequest(`POST`, `/prescriptions/unarchive`, payload);

	flag = (payload) => this.makeRequest(`POST`, `/prescriptions/flag`, payload);

	unflag = (payload) => this.makeRequest(`POST`, `/prescriptions/RemoveFlag`, payload);

	resubmit = (payload) => this.makeRequest(`POST`, `/orders/ResubmitOrder`, payload);

	splitOrder = (payload) => this.makeRequest(`POST`, `/orders/SplitOrder`, payload);

	sendToClinic = (payload) => this.makeRequest(`POST`, `/prescriptions/sendToClinic`, payload);

	removeAwaitingRewirteStatus = (payload) => this.makeRequest(`POST`, `/prescriptions/RemoveRewriteFlag`, payload);

	sendToOrganisationWithProducts = (payload) => this.makeRequest(`POST`, `/prescriptions/sendToClinicWithProducts`, payload);

	getClinicNames = (payload, cancelToken) => this.makeRequest(`GET`, `/clinics/GetClinicCQCFiltered/?${this.getRouteParams(payload)}`, null, false, cancelToken);

	getAllOrganisationEmployeesFromOrganisation = (organisationId) => this.makeRequest(`GET`, `/organisationEmployees/getAllFromOrganisation?orgId=${organisationId}`);

	deleteOrganisationEmployeeOrganisationAccount = (payload) => this.makeRequest(`POST`, `/organisationEmployees/DeleteEmployeeAccount`, payload);

	refundOrder = (payload) => this.makeRequest(`POST`, `/transactions/createrefund`, payload);

	forceCompleteOrder = (payload) => this.makeRequest(`PATCH`, `/orders/forcecomplete`, payload);

	getAllCommentsByPatientId = (patientId) => this.makeRequest(`GET`, `/comments/getallbypatientid?${this.getRouteParams({ patientId })}`);

	getAllCommentsByProductId = (productId) => this.makeRequest(`GET`, `/productevents/getallbyproductid?${this.getRouteParams({ productId })}`);

	getTotalPriceForOrderedProducts = (payload) => this.makeRequest(`POST`, `/orderedproducts/CalculateTotalPriceForOrderedProducts`, payload);

	getAllProductPartials = () => this.makeRequest("GET", "/products/GetAllProductPartials")

	// Product Media
	getAllProductImagesById = (productId) => this.makeRequest("GET", `/products/GetAllProductImagesById?productId=${productId}`)

	submitProductImage = (product) => this.makeRequest("POST", `/products/submitImage`, product);

	getAllProductDocumentsById = (productId) => this.makeRequest("GET", `/products/GetAllProductDocumentsById?productId=${productId}`)

	submitDocument = (document) => this.makeRequest("POST", `/products/SubmitDocument`, document);

	deleteProductMedia = (productMediaId) => this.makeRequest(`DELETE`, `/products/DeleteProductMedia?productMediaId=${productMediaId}`);

	updateProductMediaAccessLevel = (productMediaId, accessLevel) => this.makeRequest(`PATCH`, `/products/UpdateProductMediaAccessLevel?productMediaId=${productMediaId}&accessLevel=${accessLevel}`);

	addPrescriberNote = (payload) => this.makeRequest(`POST`, `/products/addprescribernote`, payload)

	removePrescriberNote = (productId) => this.makeRequest(`DELETE`, `/products/removeprescribernote?productId=${productId}`)

	getAllSuppliers = () => this.makeRequest(`GET`, `/suppliers/getAll`);

	bulkCreatePatientAccounts = (payload) => this.makeRequest(`POST`, `/patients/RegisterMultipleFromAdmin`, payload)

	bulkUploadPrescriptionImages = (payload) => this.makeRequest(`POST`, `/prescriptions/submitMultipleByAdmin`, payload, true);

	setAreEmailNotificationsEnabled = (payload) => this.makeRequest(`PATCH`, `/users/ChangeEmailNotificationSettings`, payload);

	setAreAftercareNotificationsEnabled = (payload) => this.makeRequest(`PATCH`, `/patients/ChangeAftercareNotificationSettings`, payload);

	setIsPatientUrgent = (payload) => this.makeRequest(`PATCH`, `/patients/setIsUrgent`, payload);

	addPatientNote = (payload) => this.makeRequest(`POST`, `/patients/addNote`, payload)

	removePatientNote = (patientId) => this.makeRequest(`DELETE`, `/patients/removeNote?patientId=${patientId}`)

	setIsPrescriptionUrgent = (payload) => this.makeRequest(`Post`, `/prescriptions/setIsUrgent`, payload);

	getAllAwaitingRewriteByPatientId = (patientId) => this.makeRequest(`GET`, `/prescriptions/GetAllAwaitingRewriteByPatientId?${this.getRouteParams({ patientId })}`);

	linkForRewrite = (payload) => this.makeRequest(`POST`, `/prescriptions/linkForRewrite?${this.getRouteParams(payload)}`);

	unlinkPrescription = (payload) => this.makeRequest(`POST`, `/prescriptions/UnlinkForRewrite?${this.getRouteParams(payload)}`);

	resetPatientOnboardingLink = (patientId) => this.makeRequest(`PATCH`, `/patients/UpdateMetadata?${this.getRouteParams({ patientId })}`);

	getAllOrganisationsForOrganisationEmployee = (prescriberId) => this.makeRequest(`GET`, `/organisations/GetAllForPrescriber?${this.getRouteParams({ prescriberId })}`);

	getAllPrescriptionsForOrganisationEmployee = (prescriberId) => this.makeRequest(`GET`, `/prescriptions/GetAllWithPatientByPrescriberId?${this.getRouteParams({ prescriberId })}`);

	linkPrescriberToOrganisation = (payload) => this.makeRequest(`PATCH`, `/prescribers/LinkPrescriberToOrganisation`, payload);

	getAllTerpenes = () => this.makeRequest(`GET`, '/terpenes/GetAll');

	getPatientSar = (patientId, adminId) => this.makeRequest(`GET`, `/patients/getPatientSar?${this.getRouteParams({ patientId, adminId })}`);

	// UTIL

	makeRequest = (method, route, data = null, isFormData = false, cancelToken = axios.CancelToken.source()) => {
		return axios({
			method,
			url: apiBaseUrl + route,
			data,
			headers: this.getHeaders(isFormData),
			cancelToken: cancelToken.token,
		});
	}

	getHeaders = (isFormData) => {
		const headers = {
			Authorization: `Bearer ${localStorage.getItem(tokenKey)}`,
		};

		if (isFormData) headers["Content-Type"] = "multipart/form-data";

		return headers;
	};

	/**
	 * Create route params from an object
	 *
	 * This is used because axios doesn't send
	 * a body in a GET request
	 *
	 * @param {*} data - object with filters
	 */
	getRouteParams = (data) => {
		let result = "";

		const keyValuePairs = Object.entries(data);

		for (let i = 0; i < keyValuePairs.length; i++) {
			result += `${keyValuePairs[i][0]}=${keyValuePairs[i][1]}`;

			if (i !== keyValuePairs.length - 1) result += "&";
		}

		return result;
	};
}

export default new ApiClient();
