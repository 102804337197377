import lambdas from "./util/validation/lambdas";

export const AdminType = {
	Super: 0,
	Regular: 1,
	Eastone: 2,
};

// Values for Admin roles from backend 
export const AdminRole = {
	Super: 7,
	Regular: 6,
	External: 5,
};

export const AdminTypesGroups = {
	All: [AdminType.Super, AdminType.Regular, AdminType.Eastone],
	SuperRegular: [AdminType.Super, AdminType.Regular],
	Eastone: [AdminType.Eastone],
	Super: [AdminType.Super],
};

export const AdminTypeDefaultLinks = {
	[AdminType.Super]: "/prescriptions/all",
	[AdminType.Eastone]: "/prescriptions/eastone",
	[AdminType.Regular]: "/prescriptions/all",
};

export const AccountType = {
	0: "Patient",
	1: "Carer",
	2: "Doctor",
	3: "Clinic",
};

export const PatientAccountTypes = {
	isSignedUp: 0,
	isPartiallySignedUp: 1,
	isNotSignedUp: 2,
}

Object.freeze(PatientAccountTypes);

export const PatientAccountTypesColors = {
	isSignedUp: '#719C80',
	isPartiallySignedUp: '#DEA84C',
	isNotSignedUp: '#CCCCCC',
}

Object.freeze(PatientAccountTypesColors);

export const AdminPermissions = {
	UserData: 0,
	AdminData: 1,
};

Object.freeze(AdminPermissions);

export const ProductTypes = {
	Oil: 0,
	Flower: 1,
	Cartridge: 2,
};

Object.freeze(ProductTypes);

export const ProductSubtypes = {
	None: 0,
	Indica: 1,
	Sativa: 2,
	Hybrid: 3,
};

Object.freeze(ProductSubtypes);

export const ProductPhEur = {
	"None": 0,
	"5.1.4": 1,
	"5.1.8b": 2
};

Object.freeze(ProductPhEur);

export const PatientRelationship = {
	Parent: 0,
	Guardian: 1,
};

Object.freeze(PatientRelationship);

export const ShippingStatus = {
	NotShipped: 0,
	SentFromWarehouse: 1,
	ReceivedByPharmacy: 2,
	SentFromPharmacy: 3,
	ReceivedByCustomer: 4,
};

Object.freeze(ShippingStatus);

export const bulkUploadTables = {
	AccountsCreated: 0,
	AccountsWithErrors: 1,
}

Object.freeze(bulkUploadTables);

export const PrescriptionFilters = {
	all: {},

	new: {
		isProcessed: false,
	},

	outofstock: {
		isProcessed: null,
		isStockReserved: false,
	},

	readyforpayment: {
		isProcessed: null,
		isStockReserved: true,
		isPaymentExpired: false,
		isPaymentReceived: false,
	},

	paymentmissed: {
		isPaymentExpired: true,
	},

	requirepapercopy: {
		isProcessed: null,
		isStockReserved: true,
		isPaperPrescriptionReceived: false,
	},

	readyforpharmacy: {
		isProcessed: null,
		isStockReserved: true,
		isPaperPrescriptionReceived: true,
		isPaymentReceived: true,
		shippingStatus: ShippingStatus.NotShipped,
	},

	readyforpatient: {
		isProcessed: null,
		isStockReserved: true,
		isPaperPrescriptionReceived: true,
		isPaymentReceived: true,
		shippingStatus: ShippingStatus.ReceivedByPharmacy,
	},

	shipped: {
		isProcessed: null,
		isStockReserved: true,
		isPaperPrescriptionReceived: true,
		isPaymentReceived: true,
		shippingStatus: ShippingStatus.SentFromPharmacy,
	},

	cancelled: {
		isCancelled: true
	},

	completed: {
		isCompleted: true,
	},

	void: {
		isVoid: true,
	},

	archived: {
		isArchived: true,
	},

	past: {},
};

Object.freeze(PrescriptionFilters);

export const ActiveRoutes = {
	ActivePrescriptions: {
		exact: ["/prescriptions/all"],
		prefix: [
			"/prescriptions/active",
			"/prescriptions/new",
			"/prescriptions/outOfStock",
			"/prescriptions/readyForPayment",
			"/prescriptions/paymentmissed",
			"/prescriptions/requirepapercopy",
			"/prescriptions/readyForPharmacy",
			"/prescriptions/readyForPatient",
			"/prescriptions/shipped",
		],
	},

	AllPrescriptions: { exact: ["/prescriptions/all"] },

	NewPrescriptions: {
		prefix: ["/prescriptions/new"],
	},
	ToBeAssigned: {
		prefix: ["/prescriptions/toBeAssigned"],
	},
	OutOfStockPrescriptions: {
		prefix: ["/prescriptions/outOfStock"],
	},
	PaymentMissed: {
		prefix: ["/prescriptions/paymentmissed"],
	},
	ReadyForPaymentPrescriptions: {
		prefix: ["/prescriptions/readyForPayment"],
	},
	RequirePaperCopyPrescriptions: {
		prefix: ["/prescriptions/requirepapercopy"],
	},
	ReadyForPharmacyPrescriptions: {
		prefix: ["/prescriptions/readyForPharmacy"],
	},
	ReadyForPatientPrescriptions: {
		prefix: ["/prescriptions/readyForPatient"],
	},
	InTransitPrescriptions: {
		prefix: ["/prescriptions/shipped"],
	},
	PastPrescriptions: {
		prefix: ["/prescriptions/past", "/prescriptions/completed", "/prescriptions/void", "/prescriptions/archived"],
	},
	CompletedPrescriptions: {
		prefix: ["/prescriptions/completed"],
	},
	VoidPrescriptions: {
		prefix: ["/prescriptions/void"],
	},
	ArchivedPrescriptions: {
		prefix: ["/prescriptions/archived"],
	},

	Patients: {
		prefix: ["/patients"],
	},
	Carers: {
		prefix: ["/carers"],
	},
	Prescribers: {
		prefix: ["/prescribers"],
	},
	Products: {
		prefix: ["/products"],
	},
	Organisations: {
		prefix: ["/organisations"],
	},
	Administrators: {
		prefix: ["/administrators"],
	},
	Metrics: {
		prefix: ["/metrics"],
	},
};

Object.freeze(ActiveRoutes);

export const ProductUnit = {
	Mg: 1,
	G: 2,
	Ml: 3,

	isDefined(value) {
		return [1, 2, 3].includes(value);
	},
};

Object.freeze(ProductUnit);

export const ProductUnitMapper = {
	[ProductUnit.Mg]: "mg",
	[ProductUnit.G]: "g",
	[ProductUnit.Ml]: "ml",
};

Object.freeze(ProductUnitMapper);

export const OrderState = {
	Active: 0,
	Unavailable: 1,
	Suspended: 2,
	Cancelled: 3,
	Void: 4,
	Archived: 5
}

Object.freeze(OrderState);

export const ProductAvailability = {
	Available: 0,
	Unavailable: 1,
	Discontinued: 2,
	AvailableToOrder: 3
}

Object.freeze(ProductAvailability);

export const StockCheckStatus = {
	AvailableInStock: 0,
	AvailableNotInStock: 1,
	UnavailableInStock: 2,
	UnavailableNotInStock: 3,
	Discontinued: 4
}

Object.freeze(StockCheckStatus);

export const OrderStatuses = {
	//Active orders
	OutOfStock: 0,
	NotPaidAndNoPaper: 1,
	NotPaidAndPaper: 2,
	PaidAndNoPaper: 3,
	PaidAndPaper: 4,
	SentFromWarehouse: 5,
	ReceivedByPharmacy: 6,
	SentFromPharmacy: 7,
	ReceivedByPatient: 8,
	PaymentDeadlineMissed: 9,
	NotProcessed: 10,
	//Past orders
	Completed: 11,
	Void: 12,
	Archived: 13,

	Cancelled: 14,
};

Object.freeze(OrderStatuses);

export const StatusesWhenProductCantBeCanceled = {
	[OrderStatuses.OutOfStock]: true,
	[OrderStatuses.NotPaidAndPaper]: true,
	[OrderStatuses.NotPaidAndNoPaper]: true,
	[OrderStatuses.PaidAndNoPaper]: false,
	[OrderStatuses.PaidAndPaper]: false,
	[OrderStatuses.SentFromWarehouse]: false,
	[OrderStatuses.ReceivedByPharmacy]: false,
	[OrderStatuses.SentFromPharmacy]: false,
	[OrderStatuses.ReceivedByPatient]: false,
	[OrderStatuses.Completed]: false,
	[OrderStatuses.Void]: false,
	[OrderStatuses.Archived]: false,
	[OrderStatuses.Cancelled]: false,
};

export const OrderStatusesToOrderHeaderMapper = {
	[OrderStatuses.OutOfStock]: { text: "Awaiting stock", color: "red" },
	[OrderStatuses.NotProcessed]: { text: "Not processed", color: "red" },
	[OrderStatuses.NotPaidAndPaper]: { text: "Ready for payment", color: "blue" },
	[OrderStatuses.NotPaidAndNoPaper]: { text: "Awaiting paper copy", color: "light-gray" },
	[OrderStatuses.PaidAndNoPaper]: { text: "Awaiting paper copy", color: "light-gray" },
	[OrderStatuses.PaidAndPaper]: { text: "Ready for pharmacy", color: "green" },
	[OrderStatuses.SentFromWarehouse]: { text: "Ready for pharmacy", color: "green" },
	[OrderStatuses.ReceivedByPharmacy]: { text: "Ready for patient", color: "green" },
	[OrderStatuses.SentFromPharmacy]: { text: "Shipped", color: "green" },
	[OrderStatuses.PaymentDeadlineMissed]: { text: "Payment missed", color: "red" },
	[OrderStatuses.ReceivedByPatient]: { text: "Received by patient", color: "blue" },
	[OrderStatuses.Completed]: { text: "Completed", color: "blue" },
	[OrderStatuses.Void]: { text: "Expired", color: "red" },
	[OrderStatuses.Cancelled]: { text: "Cancelled", color: "canceled-status-grey" },
	[OrderStatuses.Archived]: { text: "Archived", color: "blue" },
};

Object.freeze(OrderStatusesToOrderHeaderMapper);

export const OrderStatusToProductLabelMapper = {
	[OrderStatuses.NotPaidAndNoPaper]: { text: "Stock Reserved", color: "green" },
	[OrderStatuses.NotPaidAndPaper]: { text: "Stock Reserved", color: "green" },
	[OrderStatuses.PaidAndNoPaper]: { text: "Stock Reserved", color: "green" },
	[OrderStatuses.PaidAndPaper]: { text: "Paid", color: "green" },
	[OrderStatuses.SentFromWarehouse]: { text: "Paid", color: "green" },
	[OrderStatuses.ReceivedByPharmacy]: { text: "Paid", color: "green" },
	[OrderStatuses.SentFromPharmacy]: { text: "Shipped", color: "green" },
	[OrderStatuses.ReceivedByPatient]: { text: "Completed", color: "green" },
	[OrderStatuses.PaymentDeadlineMissed]: { text: "Suspended", color: "red" },
	[OrderStatuses.NotProcessed]: { text: "Not processed", color: "red" },

	[OrderStatuses.Completed]: { text: "Completed", color: "green" },
	[OrderStatuses.Void]: { text: "Expired", color: "red" },
	[OrderStatuses.Cancelled]: { text: "Cancelled", color: "canceled-status-grey" },
	[OrderStatuses.Archived]: { text: "Cancelled", color: "canceled-status-grey" },

	undefined: { text: "Not processed", color: "red" },
	null: { text: "Not processed", color: "red" },
};

Object.freeze(OrderStatusToProductLabelMapper);

export const StockStatusToProductLabelMapper = {
	[StockCheckStatus.AvailableInStock]: { text: "Available - in stock", color: "green" },
	[StockCheckStatus.AvailableNotInStock]: { text: "Available - need to order", color: "orange" },
	[StockCheckStatus.UnavailableInStock]: { text: "Available - low stock", color: "light-green-product-status" },
	[StockCheckStatus.UnavailableNotInStock]: { text: "Unavailable", color: "red" },
	[StockCheckStatus.Discontinued]: { text: "Discontinued", color: "red" },
};

Object.freeze(StockStatusToProductLabelMapper);

/**
 * Status string format
 *
 * `{isStockReserved}{isPaymentReceived}{isPaperPrescriptionReceived}{isCompleted}`
 * 	` `
 *  `{notShipped}{SentFromWarehouse}{ReceivedByPharmacy}{SentFromPharmacy}{SentToPatient}`
 */
export const StatusStringsToOrderStatusesMapper = {
	"0000 10000": OrderStatuses.OutOfStock,
	"0010 10000": OrderStatuses.OutOfStock,
	"1000 10000": OrderStatuses.NotPaidAndNoPaper,
	"1010 10000": OrderStatuses.NotPaidAndPaper,
	"1100 10000": OrderStatuses.PaidAndNoPaper,
	"1110 10000": OrderStatuses.PaidAndPaper,

	"1110 01000": OrderStatuses.SentFromWarehouse,
	"1110 00100": OrderStatuses.ReceivedByPharmacy,
	"1110 00010": OrderStatuses.SentFromPharmacy,
	// sometimes an order can have status completed but shipping status isn't received by patient
	"1111 00010": OrderStatuses.ReceivedByPatient,
	"1111 00001": OrderStatuses.ReceivedByPatient,
	"1100 00100": OrderStatuses.PaidAndNoPaper,
};

Object.freeze(StatusStringsToOrderStatusesMapper);

/**
 * Maps the status of the order to a step
 */
export const OrderStatusesToStepMapper = {
	[OrderStatuses.OutOfStock]: 1,
	[OrderStatuses.NotPaidAndPaper]: 2,
	[OrderStatuses.NotPaidAndNoPaper]: 2,
	[OrderStatuses.PaidAndNoPaper]: 2,
	[OrderStatuses.PaidAndPaper]: 3,
	[OrderStatuses.SentFromWarehouse]: 3,
	[OrderStatuses.ReceivedByPharmacy]: 3,
	[OrderStatuses.SentFromPharmacy]: 4,
	[OrderStatuses.ReceivedByPatient]: 4,

	[OrderStatuses.Completed]: 0,
	[OrderStatuses.Void]: 0,
	[OrderStatuses.Archived]: 0,
	[OrderStatuses.NotProcessed]: 0,
	[OrderStatuses.PaymentDeadlineMissed]: 0,

	[OrderStatuses.Cancelled]: 0,
};

Object.freeze(OrderStatusesToStepMapper);

export const productAvailabilityStatusMapper = {
	[StockCheckStatus.AvailableInStock]: "Available - in stock",
	[StockCheckStatus.AvailableNotInStock]: "Available - need to order",
	[StockCheckStatus.UnavailableInStock]: "Available - low stock",
	[StockCheckStatus.UnavailableNotInStock]: "Unavailable",
	[StockCheckStatus.Discontinued]: "Discontinued",
};

Object.freeze(productAvailabilityStatusMapper);

export const PrescriptionPhases = {
	new: 0,
	outofstock: 1,
	readyforpayment: 2,
	requirepapercopy: 3,
	readyforpharmacy: 4,
	readyforpatient: 5,
	shipped: 6,

	completed: 7,
	void: 8,
	archived: 9,

	past: 10,

	cancelled: 11,
	paymentmissed: 12
};

Object.freeze(PrescriptionPhases);

export const OrderStatusesToPhaseMapper = {
	[OrderStatuses.NotProcessed]: [PrescriptionPhases.new],
	[OrderStatuses.OutOfStock]: [PrescriptionPhases.outofstock],
	[OrderStatuses.NotPaidAndNoPaper]: [PrescriptionPhases.readyforpayment, PrescriptionPhases.requirepapercopy],
	[OrderStatuses.NotPaidAndPaper]: [PrescriptionPhases.readyforpayment],
	[OrderStatuses.PaidAndNoPaper]: [PrescriptionPhases.requirepapercopy],
	[OrderStatuses.PaidAndPaper]: [PrescriptionPhases.readyforpharmacy],
	[OrderStatuses.SentFromWarehouse]: [PrescriptionPhases.readyforpharmacy],
	[OrderStatuses.ReceivedByPharmacy]: [PrescriptionPhases.readyforpatient],
	[OrderStatuses.SentFromPharmacy]: [PrescriptionPhases.shipped],
	[OrderStatuses.ReceivedByPatient]: [PrescriptionPhases.shipped, PrescriptionPhases.completed],
	[OrderStatuses.PaymentDeadlineMissed]: [PrescriptionPhases.paymentmissed],

	[OrderStatuses.Completed]: [PrescriptionPhases.completed],
	[OrderStatuses.Void]: [PrescriptionPhases.void],
	[OrderStatuses.Archived]: [PrescriptionPhases.archived],

	[OrderStatuses.Cancelled]: [PrescriptionPhases.cancelled],
};

Object.freeze(OrderStatusesToPhaseMapper);


export const PatientsTables = {
	AllPatients: 0,
	AwaitingSignUp: 1,
};

Object.freeze(PatientsTables);

export const OrganisationsTables = {
	AllOrganisations: 0,
	AllOrganisationEmployees: 1,
};

Object.freeze(OrganisationsTables);

export const OrganisationType = {
	Clinic: 0,
	IndependentPrescriber: 1,
};

Object.freeze(OrganisationType);

export const OrganisationTypeMapper = {
	[OrganisationType.Clinic]: "Clinic",
	[OrganisationType.IndependentPrescriber]: "Independent prescriber",
};

Object.freeze(OrganisationTypeMapper);

export const BannerTypes = {
	Info: 0,
	Warning: 1,
	Error: 2,
	Error2: 3,
	Note: 4,
	InfoDelay: 5
};

Object.freeze(BannerTypes);

export const HighLevelRejectReasons = {
	DoctorError: "Doctor error",
	PrescriberError: "Prescriber error",
	PrescriberProductError: "Prescriber product error",
	AdminError: "Admin error",
	ProductNotInStock: "Product not in stock",
	PrescriptionExpired: "Prescription expired / close to expiry",
	DiscontinuedProduct: "Discontinued product",
	Other: "Other",
};

Object.freeze(HighLevelRejectReasons);

export const DoctorErrorRejectReasons = {
	DoctorSentADuplicate: "Doctor has sent a duplicate",
	JerseyGuernsey: "Jersey Guernsey",
};

Object.freeze(DoctorErrorRejectReasons);

export const PrescriberErrorRejectReasons = {
	ContentsUnreadable: "The contents are unreadable",
	DobMissing: "Patient’s date of birth missing",
	FullNameMissing: "Patient’s full name missing",
	AddressMissing: "Patient’s address missing",
	SignitureOrDateMissing: "Signature and/or date prescribed missing",
};
export const PrescriberProductErrorRejectReasons = {
	ProductNameUnreadable: "Product name missing, incomplete or unreadable",
	DosageMissing: "Product dosage missing, incomplete  or unreadable",
	FrequencyMissing: "Product frequency missing, incomplete or unreadable",
	QuantityMissing: "Product quantity missing, incomplete or unreadable",
	StrengthMissing: "Product strength missing, incomplete  or unreadable",
};

Object.freeze(PrescriberErrorRejectReasons);

export const AdminErrorRejectReasons = {
	ThisIsADupicate: "This is a duplicate",
	ImageUploadedByMistake: "Image uploaded by mistake",
};

Object.freeze(AdminErrorRejectReasons);

export const AllRejectReasons = {
	...DoctorErrorRejectReasons,
	...PrescriberErrorRejectReasons,
	...AdminErrorRejectReasons
};

Object.freeze(AllRejectReasons);

export const RefundTypes = {
	FullRefund: "fullRefund",
	CustomRefund: "customRefund",
}

Object.freeze(RefundTypes);

export const AddressTypesToUseForLabelGeneration = {
	PatientProfileAddress: "patientProfileAddress",
	CustomAddress: "customAddress",
}

Object.freeze(AddressTypesToUseForLabelGeneration);

export const GenerateLabelNoteTypes = {
	MustNotLeaveWithNeighbor: "MustNotLeaveWithNeighbor",
	CustomNote: "customNote",
}

Object.freeze(GenerateLabelNoteTypes);

export const PrescriberType = {
	0: "Doctor",
	1: "Pharmacist",
	2: "Nurse"
}

Object.freeze(PrescriberType);

export const BannerValues = {
	400: { text: "The number entered matches an existing Independent Prescriber organisation", type: BannerTypes.Error },
	404: { text: "The number added does not match any existing Prescribers. Please fill all fields below.", type: BannerTypes.Info },
}

Object.freeze(BannerValues);

export const OrganisationEmployeeType = {
	0: "Organisation Prescriber",
	1: "Organisation Admin",
	2: "Organisation Admin Prescriber"
}

Object.freeze(OrganisationEmployeeType);

export const OrganisationEmployeeTypeForEditModal = {
	0: "Organisation Prescriber",
	2: "Organisation Admin Prescriber"
}

Object.freeze(OrganisationEmployeeTypeForEditModal);

export const OrganisationEmployeeTypeForNewIPModal = {
	0: "Organisation Prescriber",
	1: "Organisation Admin",
}

Object.freeze(OrganisationEmployeeTypeForNewIPModal);

export const PasswordRulePoints = [
	{
		text: "Minimum 8 characters",
		validator: lambdas.minLength(8)
	},
	{
		text: "One number",
		validator: lambdas.doesPasswordContainNumber
	},
	{
		text: "One special character",
		validator: lambdas.doesPasswordContainSymbol
	},
	{
		text: "One uppercase character",
		validator: lambdas.doesPasswordContainCapital
	},
	{
		text: "One lowercase character",
		validator: lambdas.doesPasswordContainLowercase
	},
]

export const TerpeneOptions = {
	PERCENTAGE: 0,
	MAJORMINOR: 1

}

Object.freeze(TerpeneOptions);

export const SubscriptionTypes = {
	None: 0,
	AllPatient: 1,
	AccessSchemePatient: 2
}

Object.freeze(SubscriptionTypes);

export const genericTagSignupTextMapper = {
	[PatientAccountTypes.isSignedUp]: "Signed up",
	[PatientAccountTypes.isNotSignedUp]: "Not signed up",
	[PatientAccountTypes.isPartiallySignedUp]: "partial sign up",
	undefined: "",
}

Object.freeze(genericTagSignupTextMapper);

export const genericTagSubscriptionTextMapper = {
	[SubscriptionTypes.None]: `Pay as you go`,
	[SubscriptionTypes.AllPatient]: `All patients`,
	[SubscriptionTypes.AccessSchemePatient]: `Access Scheme Patient`,
	undefined: "",
};

Object.freeze(genericTagSubscriptionTextMapper);

export const genericTagSubscriptionClassnameMapper = {
	[SubscriptionTypes.None]: "b-light-gray",
	[SubscriptionTypes.AllPatient]: "b-light-green",
	[SubscriptionTypes.AccessSchemePatient]: "b-blue",
	undefined: "",
}

Object.freeze(genericTagSubscriptionClassnameMapper);

export const genericTagSignupClassnameMapper = {
	[PatientAccountTypes.isSignedUp]: "b-light-green",
	[PatientAccountTypes.isNotSignedUp]: "b-light-gray",
	[PatientAccountTypes.isPartiallySignedUp]: "b-light-orange",
	undefined: "",
}

Object.freeze(genericTagSignupClassnameMapper);

export const ProducMediaAccessLevel =
{
	Undefined: -1,
	PatientOnly: 0,
	DoctorOnly: 1,
	PatientAndDoctor: 2,
	AdminOnly: 3
}

Object.freeze(ProducMediaAccessLevel);